import { FormControlLabel, Grid, Radio, RadioGroup, Typography, withStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useForms } from '../../../../hooks/forms';
import ListWidget from './ListWidget';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import getTitle from './helpers/getTitle';
import { hasErrors } from '../../../../helpers';
import formStyles from '../../../../../collums-constants/styles/stylesheets/formStyles';

const RadioListWidget = ({ classes, widgetProps, onChange }) => {
    const { isJourney, isReadOnly, errors } = useForms();
    const isMobile = useMediaQuery('(max-width:540px)');

    const label = useMemo(() => {
        return getTitle(widgetProps.title, widgetProps.isRequired);
    }, [widgetProps]);

    const generalStyle = useMemo(() => {
        if (!isReadOnly && hasErrors(widgetProps.id, errors) && !widgetProps.isVisible) {
            return classes.error;
        }

        if (isReadOnly) {
            return classes.textDisabled;
        }
        return '';
    }, [widgetProps, errors, isReadOnly, classes.error, classes.textDisabled]);

    return (
        <>
            <RadioGroup
                aria-label={widgetProps.title}
                name={widgetProps.title}
                value={widgetProps.isVisible || ''}
                onChange={e => {
                    onChange(widgetProps.id, e.target.value, 'isVisible');
                }}
                label={label}
            >
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    className="radio-form-item"
                >
                    <Grid
                        item
                        xs={4}
                        style={{
                            minWidth: isMobile ? '100%' : 'unset'
                        }}
                    >
                        <Typography className={generalStyle}>{label}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            value={'f'}
                            className={generalStyle}
                            control={<Radio disabled={!isJourney || isReadOnly} className={generalStyle} />}
                            label={'No'}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            value={'t'}
                            className={generalStyle}
                            control={<Radio disabled={!isJourney || isReadOnly} className={generalStyle} />}
                            label={'Yes'}
                        />
                    </Grid>
                </Grid>
            </RadioGroup>
            {widgetProps.isVisible === 't' && (
                <ListWidget widgetProps={widgetProps} onChange={(id, value) => onChange(id, value)} isSubItem />
            )}
        </>
    );
};

RadioListWidget.propTypes = {
    onChange: PropTypes.func.isRequired,
    widgetProps: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(formStyles)(RadioListWidget);
