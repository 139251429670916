import React, { useState, useMemo } from 'react';
import { isEmpty, isArray } from 'lodash';
import { useEffect } from 'react';
import styled from 'styled-components';
import steps from '../../../constants/steps';
import useAppointment from '../../../hooks/useAppointment';
import useJourney from '../../../hooks/useJourney';
import useNavigation from '../../../hooks/useNavigation';
import DifferenceTable from '../../molecules/DifferenceTable/DifferenceTable';
import MainTemplate from '../../templates/MainTemplate/MainTemplate';
import { Grid, Typography } from '@material-ui/core';
import LoadingScreen from './../../../collums-components/components/common/loadingScreen';
import EditFormButton from '../../molecules/EditFormButton';
import customerProperties from '../../../constants/customerProperties';
import { getAnswersFromForm } from '../../../collums-constants/utils';

function SummaryPage() {
    const [isLoading, setIsLoading] = useState(true);
    const { stepInfo, push, previousPath } = useNavigation();
    const { journey, getJourney, updateJourney } = useJourney();
    const { customerForms: forms } = useAppointment();

    const getValue = v => {
        if (!v) return '';
        if (v.isVisible && v.isVisible !== 't') return 'No';
        if (v.isVisible === 't' && !isArray(v.value)) {
            const fieldValue = getValue({ ...v, isVisible: undefined });
            return ['Yes', fieldValue].filter(el => el).join(', ');
        }
        if (typeof v === 'string') {
            return v;
        }
        if (typeof v.value === 'boolean') {
            return v.value ? 'Yes' : 'No';
        }
        if (isArray(v.value)) {
            if (!v.value.length) return '';
            return v.value.map(getValue).join('|');
        }
        return [v.value, v.info].filter(el => el).join(', ');
    };

    const getDefaultValue = field => {
        const { answers } = getAnswersFromForm([field]);
        return getValue(answers[0]);
    };

    useEffect(() => {
        (async () => {
            await getJourney();
            setIsLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filterForm = form => {
        const newForm = { oldData: {}, newData: {} };
        const journeyFormInfo = journey.forms.find(f => f.formId === form.id);
        if (journeyFormInfo && journeyFormInfo.data) {
            Object.keys(journeyFormInfo.data).forEach(answer => {
                const field = form.fields.find(f => f.id === answer);
                if (!field) return;

                const newValue = getValue(journeyFormInfo.data[answer]);
                const oldValue = getValue((journeyFormInfo.oldData || {})[answer]);
                const defaultValue = getDefaultValue(field);

                if (defaultValue && newValue === defaultValue && !oldValue) {
                    return;
                }

                const assignValue = (targetObject, data) => {
                    Object.assign(targetObject, {
                        [field.title]: data
                    });
                };

                if (oldValue !== newValue) {
                    if (
                        !journeyFormInfo.isNewForm &&
                        (isEmpty(journeyFormInfo.oldData || {}) || !(journeyFormInfo.oldData || {})[answer])
                    ) {
                        return;
                    }

                    assignValue(newForm.oldData, oldValue);
                    assignValue(newForm.newData, newValue);
                }
            });
        }
        return newForm;
    };

    const renderFormTables = () => {
        if (
            !forms ||
            !journey ||
            !forms.length ||
            (!journey?.updateMedicalHistory && forms.every(el => el.isMasterForm))
        ) {
            return <div></div>;
        }

        return forms
            .filter(form => {
                if (form.isMasterForm) {
                    return journey?.updateMedicalHistory;
                }
                return true;
            })
            .map((form, idx) => {
                const data = filterForm(form);
                if (isEmpty(data.newData) && isEmpty(data.oldData)) {
                    return <div></div>;
                }
                return (
                    <TableContainer key={idx}>
                        <Title>{form.name}</Title>
                        <Grid container alignItems="center" justify="space-between">
                            <Grid item xs={9}>
                                <DifferenceTable data={data}></DifferenceTable>
                            </Grid>

                            <Grid item xs={2}>
                                <EditFormButton targetUrl={'/forms'} queryParam={`formId=${form.id}`} />
                            </Grid>
                        </Grid>
                    </TableContainer>
                );
            });
    };

    const continueAction = () => {
        const nextPage = steps.find(step => step.step === stepInfo.step + 1);
        updateJourney({
            lastStep: {
                label: 'practitioner-forms'
            },
            isClientJourneyCompleted: true,
            updateAppts: true
        });
        push(`/${nextPage.id}`);
    };

    const backAction = () => {
        push('/form-complete');
    };

    useEffect(() => {
        if (!journey || !forms || (isLoading && previousPath !== '/photos')) return;
        const isShowingClientTable = !isEmpty(journey.clientDetails.oldData) && !isEmpty(journey.clientDetails.data);

        const checkFormAppearing = form => {
            const data = filterForm(form);
            return !isEmpty(data.newData) || !isEmpty(data.oldData);
        };

        const isSomeFormAppearing = !journey.updateMedicalHistory
            ? forms.filter(el => !el.isMasterForm).some(checkFormAppearing)
            : forms.some(checkFormAppearing);

        if (!isShowingClientTable && !isSomeFormAppearing) {
            if (['/photos', '/practitioner-forms'].includes(previousPath)) push('/form-complete');
            else push('/practitioner-forms');
        }
        //eslint-disable-next-line
    }, [journey, forms, isLoading, previousPath]);

    const formatData = data => {
        const formattedData = { ...data };
        if (formattedData.marketingTypes) {
            formattedData['marketingTypes'] = formattedData.marketingTypes.join(', ');
        }
        Object.keys(formattedData).forEach(key => {
            const property = customerProperties.find(property => property.key === key);
            if (property) {
                formattedData[property.label] = formattedData[key];
                if (formattedData[key] !== undefined) delete formattedData[key];
            }
        });
        return formattedData;
    };

    const formattedCustomerData = useMemo(() => {
        if (!journey || isEmpty(journey.clientDetails.data)) return;

        const oldData = {};
        Object.keys(journey.clientDetails.data).forEach(key => {
            oldData[key] = (journey.clientDetails.oldData || {})[key] || '';
        });

        const oldDataFormatted = formatData(oldData);
        const newDataFormatted = formatData(journey.clientDetails.data);

        return {
            oldData: oldDataFormatted,
            newData: newDataFormatted
        };
    }, [journey]);

    return (
        <MainTemplate backAction={backAction} continueAction={continueAction} showSkipButton={false}>
            <Container>
                {isLoading ? (
                    <LoadingScreen />
                ) : (
                    <>
                        <TitleContainer>
                            <MainTitle>Form Details Summary</MainTitle>
                            <SubTitle>These are the changes & positive answers your patient has entered.</SubTitle>
                        </TitleContainer>
                        {formattedCustomerData && (
                            <TableContainer>
                                <Title>Client Details</Title>
                                <Grid container alignItems="center" justify="space-between">
                                    <Grid item xs={9}>
                                        <DifferenceTable data={formattedCustomerData} />
                                    </Grid>

                                    <Grid item xs={2}>
                                        <EditFormButton targetUrl="/client-details" />
                                    </Grid>
                                </Grid>
                            </TableContainer>
                        )}
                        {renderFormTables()}
                    </>
                )}
            </Container>
        </MainTemplate>
    );
}

const MainTitle = styled(Typography)`
    margin: 24px 0 0 0 !important;
    font-size: 22px !important;
    font-weight: 500 !important;
`;

const SubTitle = styled(Typography)`
    margin: 0 0 0 24px;
    font-size: 20px !important;
    font-weight: 400px;
`;

const TitleContainer = styled.div`
    text-align: center;
`;

const Title = styled(Typography)`
    margin: 24px 0 12px !important;
    font-size: 20px !important;
    font-weight: 500;
`;

const TableContainer = styled.div`
    width: 100%;
    min-width: 500px;
    @media (max-width: 500px) {
        min-width: unset;
        width: 100%;
    }
    & h1,
    h2 {
        font-weight: 500;
    }
    margin-bottom: 16px;
`;

const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    .MuiButton-root {
        margin-top: 1rem;
    }
`;

export default SummaryPage;
