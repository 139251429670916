import { TextareaAutosize } from '@material-ui/core';
import React from 'react';
import { useForms } from '../../../../hooks/forms';
import PropTypes from 'prop-types';
import { hasErrors } from '../../../../helpers';

const TextAreaAutosizeWidget = ({ widgetProps, onChange, isSubItem = false, textKey }) => {
    const { isJourney, isReadOnly, errors } = useForms();
    const displayingValue = textKey ? widgetProps[textKey] : widgetProps.value;

    const withErrors = hasErrors(widgetProps.id, errors);

    return (
        <TextareaAutosize
            id={widgetProps.id}
            variant="outlined"
            className={`usePlaceholderTextField usePlaceholderTextArea ${
                withErrors && !isReadOnly ? 'errorPlaceholder' : 'transparentPlaceholder'
            }`}
            required={isSubItem ? false : widgetProps.isRequired}
            placeholder={isSubItem ? widgetProps.textTitle : widgetProps.title}
            value={displayingValue || ''}
            disabled={!isJourney || isReadOnly}
            onChange={({ target }) => {
                const newValue = target.value || '';
                onChange(widgetProps.id, newValue);
            }}
            fullWidth
            error={withErrors}
        />
    );
};

TextAreaAutosizeWidget.propTypes = {
    onChange: PropTypes.func.isRequired,
    isSubItem: PropTypes.bool,
    textKey: PropTypes.string,
    widgetProps: PropTypes.object.isRequired
};

export default TextAreaAutosizeWidget;
