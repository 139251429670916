import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import steps from '../../../constants/steps';
import useJourney from '../../../hooks/useJourney';
import useNavigation from '../../../hooks/useNavigation';
import AuthApi from '../../../collums-components/api/AuthApi';
import PINModal from '../../../collums-components/components/common/PINModal/index';
import MainTemplate from '../../templates/MainTemplate/MainTemplate';
import config from '../../../config';
import useAppointment from '../../../hooks/useAppointment';
import useQs from '../../../hooks/useQs';
import { useCookies } from 'react-cookie';
import { BroadcastChannel } from 'broadcast-channel';

function FormComplete() {
    const { stepInfo, push } = useNavigation();
    const { updateJourney, getJourney } = useJourney();
    const { appointments, updateApptData, getAppointment } = useAppointment();
    const [, setCookie] = useCookies();
    const qs = useQs();
    const apptId = qs.get('appointmentId');
    const [showPinModal, setShowPinModal] = useState(false);

    const authBroadcastChannel = new BroadcastChannel('collums-lockscreen');

    const continueAction = async () => {
        const data = {
            lastStep: {
                label: 'summary'
            },
            isClientJourneyCompleted: true,
            updateAppts: true
        };

        await updateJourney(data);

        const res = await AuthApi.getMe();
        const serviceFromCurrentPractitioner = appointments.filter(appt => appt.practitioner.id === res.id);
        if (!serviceFromCurrentPractitioner.length) {
            await AuthApi.unlockUser();
            window.location.href = config.calendarUrl;
            return;
        }
        if (serviceFromCurrentPractitioner.length < appointments.length) {
            const url = window.location.toString();
            const newApptId = serviceFromCurrentPractitioner.map(a => a._id || a.id).join(',');
            window.location = url.replace(apptId, newApptId);
            updateApptData({ appointments: newApptId });
            return;
        }
        const nextPage = steps.find(step => step.step === stepInfo.step + 1);
        getJourney();
        getAppointment();
        push(`/${nextPage.id}`);
        return;
    };

    const backAction = () => {
        push('/forms');
    };

    return (
        <>
            {showPinModal && <PINModal onCorrectPin={continueAction} />}
            <MainTemplate
                forceContentInCenter
                backAction={backAction}
                continueAction={() => {
                    const environment = {};
                    if (window.location.hostname !== 'localhost' && window.location.hostname !== 'devcollumsapi.net') {
                        environment.path = '/';
                        environment.domain = '.collums.co.uk';
                    }

                    setCookie('isLocked', true, environment);
                    authBroadcastChannel.postMessage(JSON.stringify({ token: '' }));
                    setShowPinModal(true);
                }}
                showSkipButton={false}
            >
                <Container>
                    <Typography>
                        <strong>Thank you, you have finished completing this section.</strong>
                    </Typography>
                    <Typography>Please hand this device back to a member of staff.</Typography>
                </Container>
            </MainTemplate>
        </>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    & > p {
        font-size: 20px;
    }
    .MuiButton-root {
        margin-top: 1rem;
    }
    & strong {
        font-weight: 500;
    }
`;

export default FormComplete;
