import styled from 'styled-components';

export const ButtonLabel = styled.span`
    font-size: 20px;
`;

export const Title = styled.div`
    font-weight: 500;
    font-size: 22px;
    text-align: center;
    font-family: 'Roboto', 'Arial';
    margin-bottom: 20px;
`;

export const Text = styled.p`
    font-family: 'Roboto', 'Arial';
    font-size: 20px;
    margin: 0px;
    text-align: center;
`;
