/* eslint-disable comma-dangle */
import React, { useEffect, useState, useRef, useMemo, useLayoutEffect, useCallback } from 'react';
import useNavigation from '../../../hooks/useNavigation';
import MainTemplate from '../../templates/MainTemplate';
import useAppointment from '../../../hooks/useAppointment';
import useJourney from '../../../hooks/useJourney';
import LoadingScreen from '../../../collums-components/components/common/loadingScreen';
import FormBuilder from '../../organisms/FormBuilder';
import ConsentBuilder from '../../organisms/ConsentBuilder';
import { ORGANISATION_FORM_TYPES } from '../../../collums-constants';
import { omit } from 'lodash';
import { getAnswersFromForm } from '../../../collums-constants/utils';
import Api from '../../../../services/Api';
import { clearCanvas, getSignature } from '../../../collums-components/components/common/ConsentCanvas/utils';
import useQs from '../../../hooks/useQs';

function CustomerFormsPage() {
    const router = useNavigation();
    const { journey, updateJourney, getJourney } = useJourney();
    const { customer, customerForms } = useAppointment();
    const [isSigned, setIsSigned] = useState(false);
    const [isLoadingForm, setIsLoadingForm] = useState(true);
    const qs = useQs();
    const [formProp, setFormProp] = useState({
        type: '',
        item: null,
        index: 0
    });
    const [isInvalidForm, setIsInvalidForm] = useState(false);
    const formRef = useRef();
    const canvasRef = useRef();

    const selectForm = useCallback(
        index => {
            const type = customerForms[index]?.type === ORGANISATION_FORM_TYPES.CONSENT ? 'consent' : 'form';
            const item = (() => {
                if (type === 'consent') {
                    return customerForms[index];
                }
                const currentForm = customerForms[index];

                const getCurFormJourney = journey.forms.find(fJourney => fJourney.formId === customerForms[index]?.id);
                formRef.current = null;
                if (getCurFormJourney || currentForm?.formData) {
                    const data = getCurFormJourney ? getCurFormJourney.data : currentForm?.formData;
                    const newFields = getCurFormJourney?.formFields?.length
                        ? getCurFormJourney?.formFields
                        : currentForm.fields;
                    const formWithdata = {
                        ...currentForm,
                        fields: newFields.map(field => {
                            if (data && data[field.id]) {
                                Object.assign(field, {
                                    ...data[field.id],
                                    additionalInfo: omit(data[field.id], 'value')
                                });
                            }
                            return field;
                        })
                    };

                    if (!getCurFormJourney && (currentForm?.isNewForm || currentForm?.oldData)) {
                        Object.assign(formWithdata, {
                            isNewForm: currentForm.isNewForm,
                            oldData: currentForm.oldData
                        });
                    }

                    return formWithdata;
                }
                return currentForm;
            })();
            setIsSigned(false);
            setFormProp({
                type,
                item,
                index
            });
        },
        [customerForms, journey]
    );

    useEffect(() => {
        if (journey && customerForms && !formProp.item) {
            try {
                const availableForms = customerForms
                    .filter(form => {
                        const findConsent = (journey.consents || []).find(consent => consent.consentId === form.id);
                        return (
                            !findConsent?.isSigned &&
                            ((!journey.updateMedicalHistory && !form.isMasterForm) || journey.updateMedicalHistory)
                        );
                    })
                    .map(form => form.id);
                if (!availableForms.length) {
                    const isFirstRedirect = qs.get('firstRedirect');
                    if (router.previousPath === '/client-details' || isFirstRedirect) {
                        router.push('/form-complete');
                    } else {
                        router.push('/client-details');
                    }
                    return;
                }
                if (journey?.label === 'forms' && journey?.lastStep?.referenceId) {
                    if (availableForms.includes(journey.lastStep.referenceId)) {
                        const formIndex = customerForms.findIndex(
                            form => form.id === formId && availableForms.includes(form.id)
                        );
                        if (formIndex > -1) {
                            selectForm(formIndex);
                            return;
                        }
                    }
                }
                const formId = router.qs.get('formId');
                if (formId) {
                    const formIndex = customerForms.findIndex(form => form.id === formId);
                    if (formIndex !== -1) {
                        selectForm(formIndex);
                        return;
                    }
                }
                if (['/form-complete', '/summary'].includes(router.previousPath)) {
                    const lastFormIndex = customerForms.findIndex(
                        form => form.id === availableForms[availableForms.length - 1]
                    );
                    selectForm(lastFormIndex);
                    return;
                } else {
                    const firstFormIndex = customerForms.findIndex(form => form.id === availableForms[0]);
                    selectForm(firstFormIndex);
                }
            } finally {
                setIsLoadingForm(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [journey, customerForms, formProp, router, selectForm]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [formProp]);

    const [canShowForm, canShowConsent] = useMemo(() => {
        const canShowForm = formProp.type === 'form' && formProp.item;
        const canShowConsent = formProp.type === 'consent' && formProp.item;
        return [canShowForm, canShowConsent];
    }, [formProp]);

    const backAction = () => {
        if (router.previousPath === '/summary') {
            router.push('/form-complete');
            return;
        }

        if (formProp.index !== 0) {
            const unfilledFormIndex = (customerForms || []).findIndex((form, index) => {
                const consent = (journey.consents || []).find(consent => {
                    return consent.consentId === form.id;
                });
                return (
                    index < formProp.index &&
                    !consent?.isSigned &&
                    ((!journey.updateMedicalHistory && !form.isMasterForm) || journey.updateMedicalHistory)
                );
            });
            if (unfilledFormIndex > -1) {
                selectForm(unfilledFormIndex);
                return;
            }
        }
        router.push('/client-details');
    };

    const filterAvailableCustomerForm = (form, index) => {
        const consent = (journey.consents || []).find(consent => {
            return consent.consentId === form.id;
        });
        return (
            index > formProp.index &&
            !consent?.isSigned &&
            ((!journey.updateMedicalHistory && !form.isMasterForm) || journey.updateMedicalHistory)
        );
    };

    const getNextAvailableIndex = () => {
        if (customerForms && formProp.index < customerForms.length - 1) {
            const unfilledFormIndex = customerForms.findIndex(filterAvailableCustomerForm);
            return unfilledFormIndex;
        }
        return -1;
    };

    const getNextAvailable = () => {
        if (router.previousPath === '/summary') {
            router.push('/form-complete');
            return;
        }

        if (customerForms && formProp.index < customerForms.length - 1) {
            const unfilledFormIndex = customerForms.findIndex(filterAvailableCustomerForm);
            if (unfilledFormIndex > -1) {
                selectForm(unfilledFormIndex);
                return;
            }
        }
        router.push('/form-complete');
    };

    const getNewStep = () => {
        const newFormIndex = getNextAvailableIndex();

        const newForm = customerForms[newFormIndex];
        if (newFormIndex === -1 || !newForm) {
            return {
                label: 'form-complete'
            };
        }
        return {
            label: 'forms',
            referenceId: newForm.id
        };
    };

    const saveForm = async () => {
        if (!formRef.current && !formProp.item) {
            return;
        }
        if (!formRef.current) {
            formRef.current = formProp.item;
        }
        const { id, fields } = formRef.current;

        const { answers } = getAnswersFromForm(fields);

        const dataSet = {};
        answers.forEach(el => {
            dataSet[el.id] = omit(el, ['id']);
        });

        const checkIfFormExists = journey?.forms?.filter(form => form.formId === id).length;
        let formsUpdated;
        if (checkIfFormExists) {
            formsUpdated = journey.forms?.map(form => {
                if (form.formId === id) {
                    return {
                        ...form,
                        data: dataSet
                    };
                }

                return form;
            });
        } else {
            formsUpdated = [
                ...journey.forms,
                {
                    formId: id,
                    data: dataSet,
                    oldData: formProp.item.oldData,
                    isNewForm: !formProp.item.oldData
                }
            ];
        }

        const data = {
            lastStep: {
                label: 'forms',
                referenceId: id
            },
            forms: formsUpdated
        };

        await updateJourney(data);
        await updateJourney({ lastStep: getNewStep() });
        await getJourney();

        getNextAvailable();
    };

    const saveConsent = async () => {
        if (!canvasRef?.current || !formProp.item) {
            return;
        }

        const base64Signature = getSignature(canvasRef);
        clearCanvas(canvasRef);

        await Api.post(`/journey/consent/sign/${formProp.item.id}`, {
            signature: base64Signature,
            customerId: customer.id,
            journeyId: journey._id,
            fromBothJourneys: (customerForms || []).some(consent => {
                return consent.id === formProp.item.id;
            })
        });

        await updateJourney({ lastStep: getNewStep() });
        await getJourney();

        getNextAvailable();
    };

    return (
        <MainTemplate
            forceContentInCenter={false}
            backAction={backAction}
            continueAction={canShowForm ? saveForm : saveConsent}
            disableContinueAction={canShowForm ? isInvalidForm : !isSigned}
        >
            <LoadingScreen isVisible={isLoadingForm} />
            {canShowForm && <FormBuilder item={formProp.item} formRef={formRef} setIsInvalidForm={setIsInvalidForm} />}
            {canShowConsent && (
                <ConsentBuilder
                    item={formProp.item}
                    canvasRef={canvasRef}
                    isSigned={isSigned}
                    setIsSigned={setIsSigned}
                    isCustomerSide
                />
            )}
        </MainTemplate>
    );
}

export default CustomerFormsPage;
