import Api from './config';

class JobApi {
    static query(query = '') {
        return Api.request({
            method: 'GET',
            url: `/jobs?${query}`
        });
    }

    static listAll() {
        return Api.request({
            method: 'GET',
            url: '/jobs/all'
        });
    }

    static list(skip, limit, query) {
        return Api.request({
            method: 'GET',
            url: `/jobs/list?skip=${skip}&limit=${limit}${query ? `&${query}` : ''}`
        });
    }

    static archive(id) {
        return Api.request({
            method: 'DELETE',
            url: `/jobs/${id}`
        });
    }

    static changeActive(id) {
        return Api.request({
            method: 'PUT',
            url: `/jobs/${id}/change-active`
        });
    }

    static changeAppPermission(jobId, data) {
        return Api.request({
            method: 'PUT',
            url: `/jobs/${jobId}/change-app-permission`,
            data
        });
    }

    static create(data) {
        return Api.request({
            method: 'POST',
            url: '/jobs',
            data
        });
    }

    static getPermissions() {
        return Api.request({
            method: 'GET',
            url: '/jobs/permissions'
        });
    }

    static update(id, data) {
        return Api.request({
            method: 'PUT',
            url: `/jobs/${id}`,
            data
        });
    }
}

export default JobApi;
