const getForSignatureField = ({ field, errors }) => {
    const mhData = [];

    if (field.isRequired && !field.value) {
        errors.push({ id: field.id, errorType: `field ${field.title} needs to be signed` });
    }
    return {
        id: field.id,
        value: field.value,
        isVisible: field.isVisible,
        mhData
    };
};

module.exports = getForSignatureField;
