import React from 'react';
import PropTypes from 'prop-types';

const BlockWidget = ({ widgetProps }) => {
    const height = (() => {
        if (widgetProps.size) {
            return `${widgetProps.size}px`;
        }
        return 50;
    })();

    return (
        <div
            style={{
                height
            }}
        />
    );
};

BlockWidget.propTypes = {
    widgetProps: PropTypes.object.isRequired
};

export default BlockWidget;
