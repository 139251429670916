import React, { useMemo, useRef, useState } from 'react';
import { useForms } from '../../../../hooks/forms';
import { Box, Button, IconButton, makeStyles, Typography, withStyles } from '@material-ui/core';
import formStyles from '../../../../../collums-constants/styles/stylesheets/formStyles';
import PropTypes from 'prop-types';
import Modal from '../../../common/Modal';
import ConsentCanvas from '../../../common/ConsentCanvas';
import { getSignature } from '../../../common/ConsentCanvas/utils';
import { modalsButtonStyles } from '../../../../../collums-constants/styles/stylesheets/buttonsStyles';
import getTitle from './helpers/getTitle';
import { hasErrors } from '../../../../helpers';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

const SignatureWidget = ({ classes, widgetProps, onChange }) => {
    const buttonClasses = makeStyles(modalsButtonStyles)();
    const { isJourney, isReadOnly, errors } = useForms();
    const [isError, setIsError] = useState(false);
    const [openSignature, setOpenSignature] = useState(false);
    const [signature, setSignature] = useState(widgetProps.value);
    const canvasRef = useRef();
    const [isDisabled, setIsDisabled] = useState(true);

    const generalStyle = useMemo(() => {
        if (!isReadOnly && hasErrors(widgetProps.id, errors) && !widgetProps.isVisible) {
            setIsError(true);
            return classes.error;
        }

        if (isReadOnly) {
            return classes.textDisabled;
        }

        setIsError(false);
        return '';
    }, [widgetProps, errors, isReadOnly, classes.error, classes.textDisabled]);

    // Check is canvas blank by trying to find not colored pixels.
    const isCanvasBlank = () => {
        const current = canvasRef.current;
        return !current
            .getContext('2d')
            .getImageData(0, 0, current.width, current.height)
            .data.some(channel => channel !== 0);
    };

    const confirm = () => {
        if (isCanvasBlank()) {
            setOpenSignature(false);
            return;
        }

        try {
            setSignature(getSignature(canvasRef));
        } finally {
            onChange(widgetProps.id, getSignature(canvasRef));
            setOpenSignature(false);
        }
    };

    const removeSignature = () => {
        onChange(widgetProps.id, null);
        setSignature(null);
        setIsDisabled(true);
    };

    return (
        <div>
            <Typography disabled={!isJourney || isReadOnly} className={generalStyle}>
                {getTitle(widgetProps.title, widgetProps.isRequired)}
            </Typography>
            <Box
                className={`${classes.boxSignature} ${isReadOnly ? classes.boxSignatureDisabled : ''} ${
                    isError ? `${classes.boxSignatureError}` : `${classes.boxSignatureValid}`
                }`}
                disabled={!isJourney || isReadOnly}
            >
                {signature && <img src={signature} alt="signature" style={{ height: 200, width: 300, margin: 20 }} />}
            </Box>
            <div className={`${buttonClasses.buttonsContainer} buttonsContainer`} id="buttonsContainer">
                <Button
                    onClick={() => {
                        setOpenSignature(true);
                    }}
                    color="primary"
                    variant="contained"
                    autoFocus
                    disabled={!isJourney || isReadOnly}
                    className={`${buttonClasses.confirmButton}`}
                >
                    {signature ? 'Edit signature' : 'Click to sign'}
                </Button>
                {signature && (
                    <IconButton
                        aria-label="upload picture"
                        component="span"
                        onClick={removeSignature}
                        disabled={!isJourney || isReadOnly}
                    >
                        <DeleteIcon color="error" />
                    </IconButton>
                )}
            </div>

            {openSignature && (
                <Modal
                    id="insert-signature"
                    isOpen={true}
                    title="Insert signature"
                    size={widgetProps.modalSize || 'sm'}
                    draggable
                    onConfirm={confirm}
                    confirmLabel={widgetProps.confirmLabel || 'Save'}
                    onCancel={() => {
                        setOpenSignature(false);
                    }}
                    onClose={() => {
                        setOpenSignature(false);
                    }}
                >
                    <ConsentCanvas
                        canvasRef={canvasRef}
                        isSigned={!isDisabled}
                        setIsSigned={isSigned => setIsDisabled(!isSigned)}
                    />
                </Modal>
            )}
        </div>
    );
};

SignatureWidget.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    widgetProps: PropTypes.object.isRequired
};

export default withStyles(formStyles)(SignatureWidget);
