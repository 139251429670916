import React, { useEffect, useRef, useState } from 'react';
import { Grid, TextField, withStyles, IconButton, Typography } from '@material-ui/core';
import styled from 'styled-components';
import AddCircle from '@material-ui/icons/AddCircle';
import UploadPhotosModal from '../../../collums-components/components/common/UploadPhotosModal';
import useAppointment from '../../../hooks/useAppointment';
import useJourney from '../../../hooks/useJourney';
import useNavigation from '../../../hooks/useNavigation';
import MainTemplate from '../../templates/MainTemplate/MainTemplate';
import DeleteIcon from '@material-ui/icons/Delete';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';
import useLoading from '../../../hooks/useLoading';
import formStyles from '../../../collums-constants/styles/stylesheets/formStyles';
import { getFileType } from '../../../collums-constants/utils';
import useDebounce from '../../../hooks/useDebounce';
import apps from '../../../config';
import uploadPhotoAndThumbnail from '../../../collums-components/helpers/uploadPhotoAndThumbnail';
import TreatmentNoteApi from '../../../collums-components/api/TreatmentNoteApi';
import PhotosApi from '../../../collums-components/api/PhotosApi';
import AuthApi from './../../../collums-components/api/AuthApi';
import Modal from '../../../collums-components/components/common/Modal';

function PhotosPage({ classes }) {
    const [inputValue, setInputValue] = useState();
    const [currentIndex, setCurrentIndex] = useState(0);
    const isFirstRender = useRef(true);
    const changeNameDebounced = useDebounce(inputValue, 600);
    const [allFieldsValid, setAllFieldsValid] = useState();
    const [numPhotos, setNumPhotos] = useState(1);
    const [photos, setPhotos] = useState([]);
    const journey = useJourney();
    const { customer } = useAppointment();
    const [newPhotos, setNewPhotos] = useState([]);
    const { push } = useNavigation();
    const { isLoading, setIsLoading } = useLoading();
    const defaultImage = 'https://www.stregasystem.com/img/users/user.png';
    const [showUploadPhotosModal, setShowUploadPhotosModal] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [oldPhotoNames, setOldPhotoNames] = useState([]);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (journey.journey) {
            const getNotePhotos = async journeyId => {
                const note = await TreatmentNoteApi.getNote(journeyId, true);
                if (!note) return;
                const photos = await PhotosApi.getPhotosByNoteId(note._id);
                if (!_.isEmpty(photos)) {
                    setOldPhotoNames(
                        photos.reduce((array, photo) => {
                            array.push(photo.name);
                            return array;
                        }, [])
                    );
                }
                setNumPhotos(photos.length);
            };
            getNotePhotos(journey.journey._id);
        }
    }, [journey.journey]);

    const onRemoveImage = async idx => {
        const filterPhoto = (_, index) => index !== idx;
        const nNewPhotos = newPhotos.filter(filterPhoto);
        const nPhotos = photos.filter(filterPhoto);
        setNumPhotos(nPhotos.length + oldPhotoNames.length);
        setPhotos(nPhotos);
        setNewPhotos(nNewPhotos);
    };

    const generateDefaultName = (customer, lastNum) => {
        const firstName = customer?.firstName.charAt(0);
        const surname = customer?.surname.replace(/\s/g, '-');
        const DOB = customer?.dateOfBirth?.format('YYYY_MM_DD');
        if (DOB) {
            return `${firstName}_${surname}_${DOB}_${lastNum}`;
        } else {
            if (firstName && surname) {
                return `${firstName}_${surname}_${lastNum}`;
            } else {
                return new Date().getTime();
            }
        }
    };
    const onDropAvatar = (file, picture, photoType, idx) => {
        const nPhotos = [...photos];
        const photo = nPhotos[idx];
        if (!photo) {
            setNumPhotos(numPhotos - 1);
        }
        let lastNumber = 1;
        let name = generateDefaultName(customer, lastNumber);

        const isValidName = () => {
            return (
                !nPhotos.some(photo => {
                    return photo !== nPhotos[idx] && photo.name === name;
                }) && !oldPhotoNames.includes(name)
            );
        };

        while (!isValidName()) {
            name = generateDefaultName(customer, ++lastNumber);
        }

        photo.name = name;
        photo.picture = picture;
        photo.file = file;
        photo.uploaded = false;
        photo.type = photoType;
        photo.disabled = false;
        photo.validName = true;
        const photoId =
            journey.journey && journey.journey.photos && journey.journey.photos.length > idx
                ? idx
                : nPhotos.filter(p => p.picture !== defaultImage).length - 1;
        const type = getFileType(file, 'png');

        photo.uploadUrl = `/journey/${journey.journey._id}/upload-photo/${photoId}?customerId=${customer?.id}&type=${type}&name=${photo.name}`;
        const nNewPhotos = [...nPhotos];
        setNewPhotos(nNewPhotos);
        setPhotos(nPhotos);
    };

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        } else {
            changePhotoName(inputValue, currentIndex);
        }
    }, [changeNameDebounced]); //eslint-disable-line

    const onChangeName = (e, idx) => {
        setInputValue(e.target.value);
        setCurrentIndex(idx);
        photos[idx].name = e.target.value;
        const newPhoto = newPhotos.find(photo => photo === photos[idx]);
        if (newPhoto) {
            newPhoto.name = e.target.value;
        }
    };

    const changePhotoName = (qry, idx) => {
        if (!inputValue) {
            return;
        }
        const nPhotos = [...photos];
        const value = qry;
        const alreadyExists =
            nPhotos.some(photo => {
                return photo !== nPhotos[idx] && photo.name === value;
            }) || oldPhotoNames.includes(value);

        nPhotos[idx].validName = !alreadyExists;

        setPhotos(nPhotos);
        setInputValue();
    };

    const checkNameErrors = () => {
        const withError = photos.filter(item => {
            return item.validName === false;
        });
        if (withError.length === 0) {
            setAllFieldsValid(true);
        } else {
            setAllFieldsValid(false);
        }
    };

    useEffect(() => {
        checkNameErrors();
    }, [photos]); //eslint-disable-line

    const renderPhotosGrid = () => {
        const renderPhoto = idx => {
            return (
                <Photo>
                    <Grid alignItems="center" spacing={1}>
                        <Grid container item justify="center" style={{ marginBottom: '15px' }}>
                            <img
                                src={photos[idx].picture}
                                style={{ margin: '20px 0px', width: 'auto', maxHeight: '200px' }}
                            ></img>
                        </Grid>
                        {photos[idx]?.withError && (
                            <Grid item style={{ margin: '-20px 0 20px' }}>
                                <Typography variant="caption" style={{ color: '#de4545' }}>
                                    Error during uploading
                                </Typography>
                            </Grid>
                        )}
                        <Grid container item justify="center" style={{ marginBottom: '15px' }}>
                            {photos[idx].validName ? (
                                <TextField
                                    id={`photo-name-${idx}`}
                                    className={classes.autocompleteTextField}
                                    label="File name"
                                    variant="outlined"
                                    value={photos[idx].name}
                                    onChange={e => {
                                        onChangeName(e, idx);
                                    }}
                                />
                            ) : (
                                <TextField
                                    error
                                    id={`photo-name-${idx}`}
                                    className={classes.autocompleteTextField}
                                    label="Name already in use"
                                    variant="outlined"
                                    value={photos[idx].name}
                                    onChange={e => {
                                        onChangeName(e, idx);
                                    }}
                                />
                            )}
                        </Grid>

                        <Grid container item justify="space-evenly" alignItems="center">
                            <IconButton
                                onClick={() => onRemoveImage(idx)}
                                className={`innerButton ${
                                    !photos[idx].uploaded && photos[idx].picture !== defaultImage ? 'disabled' : ''
                                }`}
                            >
                                <DeleteIcon style={{ fontSize: '25pt', color: '#de4545' }}></DeleteIcon>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Photo>
            );
        };
        const renderRowPhotos = () => {
            return Array.from(Array(photos.length).keys()).map(idx => {
                const isPhotoAppearing = Boolean(photos[idx]?.picture !== defaultImage);
                if (!isPhotoAppearing) {
                    return <></>;
                }
                return (
                    <Grid key={idx} justify="center" spacing={1} style={{ margin: '30px' }}>
                        {renderPhoto(idx)}
                    </Grid>
                );
            });
        };

        if (!customer) return <></>;

        return (
            <Grid container column xs justify="center">
                <Grid>
                    <Title>
                        Upload pre-treatment photos - {customer.firstName} {customer.surname}
                    </Title>
                </Grid>
                <PhotosContent container justify="center" alignContent="space-around">
                    {renderRowPhotos()}
                    <ButtonGrid justify="center" spacing={1}>
                        <Button>
                            <div
                                className="innerButton"
                                onClick={() => {
                                    setShowUploadPhotosModal(true);
                                }}
                            >
                                <AddCircle style={{ fontSize: '55pt', fill: '#51B4F4' }}></AddCircle>
                            </div>
                        </Button>
                    </ButtonGrid>
                    {showUploadPhotosModal && (
                        <UploadPhotosModal
                            visible={showUploadPhotosModal}
                            onClose={() => {
                                setShowUploadPhotosModal(false);
                            }}
                            loading={isUploading}
                            setUploadedPhoto={newPhotos => {
                                if (newPhotos.length > 0) {
                                    newPhotos.forEach(newPhoto => {
                                        photos.push({
                                            picture: defaultImage,
                                            file: undefined,
                                            name: 'test',
                                            disabled: true,
                                            type: 'png'
                                        });
                                        onDropAvatar(
                                            newPhoto.file,
                                            newPhoto.picture,
                                            newPhoto.type || 'png',
                                            photos.length - 1
                                        );
                                    });
                                }
                            }}
                        />
                    )}
                </PhotosContent>
            </Grid>
        );
    };

    const continueAction = async () => {
        try {
            setIsLoading(true);
            setIsUploading(true);
            let hadError = false;
            const uploadedPhotos = [];
            if (!_.isEmpty(newPhotos)) {
                const note = await TreatmentNoteApi.getNote(journey.journey._id, true);
                const formattedPhotos = newPhotos.map(photo => {
                    return {
                        ...photo,
                        uploadUrl: photo.uploadUrl.replace(photo.uploadUrl.match(/name=(.{0,})/)[1], photo.name)
                    };
                });
                const completedImages = [];

                const onUploadPhoto = photo => {
                    const itemIndexOnPhotos = photos.indexOf(photo);
                    photos.splice(itemIndexOnPhotos, 1);
                    setPhotos(photos);
                    uploadedPhotos.push(photo);
                    uploadComplete();
                };

                const onUploadError = (err, photo) => {
                    toastr.error(err?.data?.message || 'Something went wrong uploading the photo');
                    hadError = true;
                    photo.withError = true;
                    setIsLoading(false);
                    uploadComplete(photo);
                };

                const uploadComplete = (photoWithError = null) => {
                    completedImages.push(photoWithError);
                    if (completedImages.length >= formattedPhotos.length) {
                        let hasError = false;
                        const erroredUpload = photos.map(item => {
                            const photo = completedImages.find(image => image.uploadUrl === item.uploadUrl);
                            if (photo) {
                                hasError = true;
                                return { ...photo, withError: true };
                            }
                        });
                        setPhotos(erroredUpload);
                        if (hasError) {
                            setShowModal(true);
                        }
                    }
                };

                await uploadPhotoAndThumbnail(
                    formattedPhotos,
                    note._id || note.id,
                    journey.journey.customer._id || journey.journey.customer.id,
                    'Note',
                    onUploadPhoto,
                    onUploadError
                );

                uploadedPhotos.forEach(photo => {
                    const itemIndexOnNewPhotos = newPhotos.indexOf(photo);
                    newPhotos.splice(itemIndexOnNewPhotos, 1);
                });

                setIsUploading(false);
            }
            if (!hadError && newPhotos.length === 0) {
                const isLocked = await AuthApi.isUserLocked();
                if (isLocked) {
                    await AuthApi.lockUser();
                }

                const redirectToCalendar = customerId => {
                    const treatmentNoteLink = `/customer/${customerId}/medical/practitioner_notes?journey=${journey.journey._id}`;
                    window.location.href = `${apps.calendarUrl}${treatmentNoteLink}`;
                };
                journey.updateJourney({
                    isClientJourneyCompleted: true,
                    updateAppts: true
                });
                if (!journey.journey.customer._id && typeof journey.journey.customer === 'string') {
                    redirectToCalendar(journey.journey.customer);
                } else if (journey.journey.customer._id || journey.journey.customer.id) {
                    redirectToCalendar(journey.journey.customer._id || journey.journey.customer.id);
                } else {
                    window.location.href = apps.calendarUrl;
                }
            }
        } catch (err) {
            toastr.error(err?.data?.message || 'Something went wrong uploading photo');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <MainTemplate
            centralizedGrid
            backAction={() => push('practitioner-forms')}
            continueAction={continueAction}
            disableContinueAction={!allFieldsValid || isLoading}
            showSkipButton={false}
        >
            {renderPhotosGrid()}
            <Modal
                isOpen={showModal}
                title="Warning"
                size="sm"
                confirmLabel="OK"
                onClose={() => setShowModal(false)}
                onConfirm={() => setShowModal(false)}
                titleStyle={{ fontSize: '1.2em' }}
            >
                <Grid container justifyContent={'center'}>
                    <StyledTypography variant="body1" style={{ fontSize: '1.2em' }}>
                        There was a problem with the upload of some photos.
                    </StyledTypography>
                    <StyledTypography variant="body1" style={{ fontSize: '1em', textAlign: 'center' }}>
                        Please try to upload them again or remove them and go to the next step.
                    </StyledTypography>
                </Grid>
            </Modal>
        </MainTemplate>
    );
}

const Title = styled(Typography)`
    margin: 24px 0 0 0 !important;
    font-size: 22px !important;
    font-weight: 500 !important;
`;
const PhotosContent = styled(Grid)`
    min-height: calc(var(--vh, 1vh) * 75);
`;

const ButtonGrid = styled(Grid)`
    display: flex;
`;

const Button = styled.div`
    display: flex;
    align-items: center;
    min-height: calc(var(--vh, 1vh) * 20);
    justify-content: center;

    .innerButton {
        border-radius: 8px;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .innerButton:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }

    .innerButton.disabled > svg {
        color: rgba(0, 0, 0, 0.3) !important;
    }
`;

const Photo = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    .MuiButton-root {
        margin-top: 1rem;
    }
`;

const StyledTypography = styled(Typography)`
    font-size: 1rem;
`;

export default withStyles(formStyles)(PhotosPage);
