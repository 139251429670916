const modalsButtonStyles = () => {
    return {
        baseButton: {
            width: 'fit-content',
            minWidth: 80,
            height: 38,
            borderRadius: 0,
            fontWeight: 500,
            padding: '6px 14px',
            margin: '0 30px',
            boxShadow: 'none'
        },
        buttonsContainer: {
            marginTop: 24,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        cancelButton: {
            width: 'fit-content',
            minWidth: 80,
            height: 38,
            borderRadius: 0,
            fontWeight: 500,
            padding: '6px 14px',
            margin: '0 30px',
            boxShadow: 'none',
            border: '1px solid rgba(0, 0, 0, 0.87)',
            backgroundColor: 'transparent',
            color: 'rgba(0, 0, 0, 0.87)',
            '&:hover': {
                backgroundColor: 'transparent'
            },
            textTransform: 'none',
            fontSize: 14
        },
        confirmButton: {
            width: 'fit-content',
            minWidth: 80,
            height: 38,
            borderRadius: 0,
            fontWeight: 500,
            padding: '6px 14px',
            margin: '0 30px',
            boxShadow: 'none',
            backgroundColor: '#b41778',
            '&:hover': {
                backgroundColor: '#b41778'
            },
            color: 'white',
            border: 'none',
            textTransform: 'none',
            fontSize: 14
        }
    };
};

const buttonsStyles = theme => ({
    grayButton: {
        backgroundColor: theme.palette.gray.bold,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.gray.bold
        }
    },
    addCircleStyle: {
        fontSize: 30,
        color: '#2B78E4'
    }
});

module.exports = {
    modalsButtonStyles,
    buttonsStyles
};
