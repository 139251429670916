import React from 'react';
import useNavigation from '../../../hooks/useNavigation';
import { StyledButton } from './styles';

const EditFormButton = ({ targetUrl, queryParam }) => {
    const router = useNavigation();

    return (
        <StyledButton
            variant="contained"
            disableElevation
            onClick={() => {
                router.push(targetUrl, queryParam);
            }}
        >
            Edit form
        </StyledButton>
    );
};

export default EditFormButton;
