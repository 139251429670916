/* eslint-disable comma-dangle */
import React, { useEffect, useState, useRef, useMemo, useLayoutEffect, useCallback } from 'react';
import useNavigation from '../../../hooks/useNavigation';
import MainTemplate from '../../templates/MainTemplate';
import useAppointment from '../../../hooks/useAppointment';
import useJourney from '../../../hooks/useJourney';
import LoadingScreen from '../../../collums-components/components/common/loadingScreen';
import FormBuilder from '../../organisms/FormBuilder';
import ConsentBuilder from '../../organisms/ConsentBuilder';
import { ORGANISATION_FORM_TYPES } from '../../../collums-constants';
import { omit } from 'lodash';
import { getAnswersFromForm } from '../../../collums-constants/utils';
import Api from '../../../../services/Api';
import useAuth from '../../../hooks/useAuth';
import { clearCanvas, getSignature } from '../../../collums-components/components/common/ConsentCanvas/utils';

function PractitionerFormsPage() {
    const router = useNavigation();
    const { user } = useAuth();
    const { journey, updateJourney, getJourney } = useJourney();
    const { customer, practitionerForms } = useAppointment();
    const [isSigned, setIsSigned] = useState(false);
    const [isLoadingForm, setIsLoadingForm] = useState(true);
    const [formProp, setFormProp] = useState({
        type: '',
        item: null,
        index: 0
    });
    const [isInvalidForm, setIsInvalidForm] = useState(false);
    const formRef = useRef();
    const canvasRef = useRef();

    const selectForm = useCallback(
        index => {
            const type = practitionerForms[index]?.type === ORGANISATION_FORM_TYPES.CONSENT ? 'consent' : 'form';
            const item = (() => {
                if (type === 'consent') {
                    const foundConsent = journey.consents.find(
                        fJourney =>
                            fJourney.consentId === practitionerForms[index]?.id && matchPractitionerConsent(fJourney)
                    );
                    if (foundConsent && foundConsent.signatureImage) {
                        return {
                            ...(practitionerForms[index] || {}),
                            signatureImage: foundConsent.signatureImage,
                            logs: foundConsent.logs
                        };
                    }
                    return practitionerForms[index];
                }
                const currentForm = practitionerForms[index];
                const getCurFormJourney = journey.forms.find(
                    fJourney => fJourney.formId === practitionerForms[index]?.id
                );
                formRef.current = null;
                if (getCurFormJourney || currentForm?.formData) {
                    const data = getCurFormJourney ? getCurFormJourney.data : currentForm?.formData;

                    const formWithdata = {
                        ...currentForm,
                        fields: currentForm.fields.map(field => {
                            if (data && data[field.id]) {
                                Object.assign(field, {
                                    ...data[field.id],
                                    additionalInfo: omit(data[field.id], 'value')
                                });
                            }
                            return field;
                        })
                    };

                    if (!getCurFormJourney && (currentForm?.isNewForm || currentForm?.oldData)) {
                        Object.assign(formWithdata, {
                            isNewForm: currentForm.isNewForm,
                            oldData: currentForm.oldData
                        });
                    }

                    return formWithdata;
                }
                return currentForm;
            })();
            setIsSigned(false);
            setFormProp({
                type,
                item,
                index
            });
        },
        // eslint-disable-next-line
        [practitionerForms, journey]
    );

    useEffect(() => {
        if (journey && practitionerForms && !formProp.item) {
            try {
                const availableForms = practitionerForms
                    .filter(form => {
                        const findConsent = (journey.consents || []).find(
                            consent => consent.consentId === form.id && matchPractitionerConsent(consent)
                        );
                        return (
                            (!findConsent?.isSigned || findConsent?.signatureImage) &&
                            ((!journey.updateMedicalHistory && !form.isMasterForm) || journey.updateMedicalHistory)
                        );
                    })
                    .map(form => form.id);
                if (!availableForms.length) {
                    if (['/photos'].includes(router.previousPath)) {
                        router.push('/summary');
                    } else {
                        router.push('/photos');
                    }
                    return;
                }
                if (journey?.label === 'practitioner-forms' && journey?.lastStep?.referenceId) {
                    if (availableForms.includes(journey.lastStep.referenceId)) {
                        const formIndex = practitionerForms.findIndex(
                            form => form.id === formId && availableForms.includes(form.id)
                        );
                        if (formIndex > -1) {
                            selectForm(formIndex);
                            return;
                        }
                    }
                }
                const formId = router.qs.get('formId');
                if (formId) {
                    const formIndex = practitionerForms.findIndex(form => form.id === formId);
                    if (formIndex !== -1) {
                        selectForm(formIndex);
                        return;
                    }
                }
                if (['/photos'].includes(router.previousPath)) {
                    const lastFormIndex = practitionerForms.findIndex(
                        form => form.id === availableForms[availableForms.length - 1]
                    );
                    selectForm(lastFormIndex);
                    return;
                } else {
                    // const firstFormIndex = practitionerForms.findIndex(form => form.id === availableForms[0]);
                    // selectForm(firstFormIndex);
                    selectForm(0);
                }
            } finally {
                setIsLoadingForm(false);
            }
        }
        // eslint-disable-next-line
    }, [journey, practitionerForms, formProp, router, selectForm]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [formProp]);

    const [canShowForm, canShowConsent] = useMemo(() => {
        const canShowForm = formProp.type === 'form' && formProp.item;
        const canShowConsent = formProp.type === 'consent' && formProp.item;
        return [canShowForm, canShowConsent];
    }, [formProp]);

    const matchPractitionerConsent = consent => {
        return !consent?.targetPractitioner || consent?.targetPractitioner === user.id;
    };

    const backAction = () => {
        if (formProp.index !== 0) {
            // const unfilledFormIndex = practitionerForms.findIndex((form, index) => {
            //     const consent = (journey.consents || []).find(consent => {
            //         return consent.consentId === form.id && matchPractitionerConsent(consent);
            //     });
            //     return (
            //         index < formProp.index &&
            //         (!consent?.isSigned || consent?.signatureImage) &&
            //         ((!journey.updateMedicalHistory && !form.isMasterForm) || journey.updateMedicalHistory) &&
            //         matchPractitionerConsent()
            //     );
            // });
            // if (unfilledFormIndex > -1) {
            //     selectForm(unfilledFormIndex);
            //     return;
            // }
            selectForm((parseInt(formProp.index) - 1));
            return;
        }
        router.push('/summary');
    };

    const filterAvailablePractitionerForm = (form, index) => {
        const consent = (journey.consents || []).find(consent => {
            return consent.consentId === form.id && matchPractitionerConsent(consent);
        });
        return (
            index > formProp.index &&
            (!consent?.isSigned || consent?.signatureImage) &&
            ((!journey.updateMedicalHistory && !form.isMasterForm) || journey.updateMedicalHistory) &&
            matchPractitionerConsent()
        );
    };

    const getNextAvailable = () => {
        if (formProp.index < practitionerForms.length - 1) {
            // const unfilledFormIndex = practitionerForms.findIndex(filterAvailablePractitionerForm);
            // if (unfilledFormIndex > -1) {
            //     selectForm(unfilledFormIndex);
            //     return;
            // }
            selectForm((parseInt(formProp.index) + 1));
            return;
        }
        router.push('/photos');
    };

    const getNextAvailableIndex = () => {
        if (practitionerForms && formProp.index < practitionerForms.length - 1) {
            const unfilledFormIndex = practitionerForms.findIndex(filterAvailablePractitionerForm);
            return unfilledFormIndex;
        }
        return -1;
    };

    const getNewStep = () => {
        const newFormIndex = getNextAvailableIndex();

        const newForm = practitionerForms[newFormIndex];
        if (newFormIndex === -1 || !newForm) {
            return {
                label: 'photos'
            };
        }
        return {
            label: 'practitioner-forms',
            referenceId: newForm.id
        };
    };

    const saveForm = async () => {
        if (!formRef.current && !formProp.item) {
            return;
        }
        if (!formRef.current) {
            formRef.current = formProp.item;
        }
        const { id, fields } = formRef.current;

        const { answers } = getAnswersFromForm(fields);

        const dataSet = {};
        answers.forEach(el => {
            dataSet[el.id] = omit(el, ['id']);
        });

        const checkIfFormExists = journey?.forms?.filter(form => form.formId === id).length;
        let formsUpdated;
        if (checkIfFormExists) {
            formsUpdated = journey.forms?.map(form => {
                if (form.formId === id) {
                    return {
                        ...form,
                        data: dataSet
                    };
                }

                return form;
            });
        } else {
            formsUpdated = [
                ...journey.forms,
                {
                    formId: id,
                    data: dataSet,
                    oldData: formProp.item.oldData,
                    isNewForm: !formProp.item.oldData
                }
            ];
        }

        const data = {
            lastStep: {
                label: 'practitioner-forms',
                referenceId: id
            },
            forms: formsUpdated
        };

        await updateJourney(data);
        await updateJourney({ lastStep: getNewStep() });
        await getJourney();

        getNextAvailable();
    };

    const saveConsent = async () => {
        if (!canvasRef?.current || !formProp.item) return;

        const base64Signature = getSignature(canvasRef);
        clearCanvas(canvasRef);

        const customerSignature = formProp.item.signatureImage || '';
        const date = formProp.item.signatureImage ? formProp.item.logs[0]?.date?.toDate() : '';

        await Api.post(`/journey/consent/sign/${formProp.item.id}?fromPractitionerSide=true`, {
            signature: base64Signature,
            customerId: customer.id,
            journeyId: journey._id,
            customerSignature,
            date
        });

        await updateJourney({ lastStep: getNewStep() });
        await getJourney();

        getNextAvailable();
    };

    return (
        <MainTemplate
            forceContentInCenter={false}
            backAction={backAction}
            continueAction={canShowForm ? saveForm : saveConsent}
            disableContinueAction={canShowForm ? isInvalidForm : !isSigned}
        >
            <LoadingScreen isVisible={isLoadingForm} />
            {canShowForm && <FormBuilder item={formProp.item} formRef={formRef} setIsInvalidForm={setIsInvalidForm} />}
            {canShowConsent && (
                <ConsentBuilder
                    item={formProp.item}
                    canvasRef={canvasRef}
                    isSigned={isSigned}
                    setIsSigned={setIsSigned}
                />
            )}
        </MainTemplate>
    );
}

export default PractitionerFormsPage;
