import React, { useCallback, useState, useEffect } from 'react';
import Api from '../../../services/Api';
import useQs from '../../hooks/useQs';
const JourneyContext = React.createContext({});

export const JourneyContextProvider = ({ children }) => {
    const [journey, setJourney] = useState();
    const qs = useQs();
    const apptId = qs.get('appointmentId');

    const getJourney = useCallback(async () => {
        if (apptId) {
            const { data } = await Api.get(`journey/${apptId.split(',')[0]}`);
            setJourney(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apptId]);

    useEffect(() => {
        getJourney();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateJourney = async updatedObject => {
        try {
            const { data } = await Api.put(`/journey/${journey._id}`, updatedObject);
            setJourney({
                ...journey,
                ...data,
                ...updatedObject,
                customer: journey.customer
            });
        } catch (error) {
            console.error(error);
        }
    };

    return <JourneyContext.Provider value={{ journey, updateJourney, getJourney }}>{children}</JourneyContext.Provider>;
};

export default JourneyContext;
