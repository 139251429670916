import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Canvas, CanvasContainer } from './style';
import PropTypes from 'prop-types';
import { getSignature, addSignatureProgress, clearCanvas, createPointerCircle, getDefaultScrollProps } from './utils';

const ConsentCanvas = ({ isSigned, setIsSigned, canvasRef, getScrollProps = getDefaultScrollProps }) => {
    const [isDrawing, setIsDrawing] = useState(false);
    const containerRef = useRef();
    const contextRef = useRef();

    useLayoutEffect(() => {
        const updateCanvasSize = () => {
            const canvas = canvasRef.current;
            if (!containerRef.current) return;
            window.devicePixelRatio = 2;
            const width = containerRef.current.getBoundingClientRect().width - 10;
            const newCanvasWidth = width * window.devicePixelRatio;
            if (canvas.width !== newCanvasWidth) {
                const imgData = isSigned ? getSignature(canvasRef) : undefined;
                canvas.width = newCanvasWidth;
                canvas.height = 300 * window.devicePixelRatio;
                canvas.style.width = `${width}px`;
                canvas.style.height = '300px';
                const context = canvas.getContext('2d');
                clearCanvas(canvasRef);
                addSignatureProgress(contextRef, imgData, width, 300);
                context.scale(window.devicePixelRatio, window.devicePixelRatio);
            }
        };

        window.addEventListener('resize', updateCanvasSize);
        window.addEventListener('orientationchange', updateCanvasSize);
        updateCanvasSize();

        return () => {
            try {
                window.removeEventListener('resize', updateCanvasSize);
                window.removeEventListener('orientationchange', updateCanvasSize);
            } catch (err) {
                console.error(err);
            }
        };
    }, [canvasRef, setIsSigned, isSigned]);

    useEffect(() => {
        const context = canvasRef.current.getContext('2d');
        context.lineCap = 'round';
        context.strokeStyle = 'black';
        context.lineWidth = 3;
        contextRef.current = context;
    }, [canvasRef]);

    const getDrawCoords = coords => {
        const [x, y] = (() => {
            if (coords.touches && coords.touches.length) {
                return [coords.touches[0].pageX, coords.touches[0].pageY];
            }
            return [coords.pageX, coords.pageY];
        })();
        const clientRect = coords.target.getBoundingClientRect();
        const leftPadding = clientRect?.left || 0;
        const topPadding = clientRect?.top || 0;
        const scrollProps = getScrollProps();
        return {
            x: x - leftPadding - scrollProps.x,
            y: y - topPadding - scrollProps.y
        };
    };

    const startDrawing = e => {
        e.stopPropagation();
        e.preventDefault();
        const position = getDrawCoords(e.nativeEvent);
        createPointerCircle(contextRef, position);
        setIsDrawing(true);
    };

    const finishDrawing = e => {
        if (isDrawing) {
            e.stopPropagation();
            e.preventDefault();
            contextRef.current.closePath();
            setIsDrawing(false);
            setIsSigned(true);
        }
    };

    const draw = e => {
        e.stopPropagation();
        e.preventDefault();
        if (!isDrawing) {
            return;
        }
        const position = getDrawCoords(e.nativeEvent);
        contextRef.current.lineTo(position.x, position.y);
        contextRef.current.stroke();
    };

    return (
        <CanvasContainer ref={containerRef}>
            <Canvas
                id="sign-canvas"
                ref={canvasRef}
                onMouseDown={startDrawing}
                onMouseUp={finishDrawing}
                onMouseMove={draw}
                onMouseOut={finishDrawing}
                onTouchStart={startDrawing}
                onTouchMove={draw}
                onTouchEnd={finishDrawing}
            />
        </CanvasContainer>
    );
};

ConsentCanvas.propTypes = {
    isSigned: PropTypes.bool.isRequired,
    setIsSigned: PropTypes.func.isRequired,
    canvasRef: PropTypes.object.isRequired,
    getScrollProps: PropTypes.func
};

export default ConsentCanvas;
