import React, { useCallback } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Grid, TextField, Typography, withStyles } from '@material-ui/core';
import { useForms } from '../../../../hooks/forms';
import commonDrugsList from '../../../../../collums-constants/commonDrugsList';
import formStyles from '../../../../../collums-constants/styles/stylesheets/formStyles';
//import SelectWidget from './SelectWidget';
import PropTypes from 'prop-types';
import { hasErrors } from '../../../../helpers';
import { startsWith, uniq, isArray } from 'lodash';

const AutoCompleteWidget = ({ classes, widgetProps, onChange }) => {
    const { isJourney, isReadOnly, errors } = useForms();
    const value = useCallback(() => {
        if (!widgetProps.value && widgetProps.multiple) return [];
        if (!widgetProps.value) return null;
        return widgetProps.value;
    }, [widgetProps.value, widgetProps.multiple])();

    const options = useCallback(() => {
        const appearingOptions = (() => {
            if (widgetProps.useDrugList) {
                return [...commonDrugsList, ...(widgetProps.options || [])].sort();
            }
            return widgetProps.options || [];
        })();
        return appearingOptions;
    }, [widgetProps.useDrugList, widgetProps.options])();

    const changeHandler = (_, value) => {
        onChange(widgetProps.id, value);
    };

    return (
        <Grid container spacing={1} direction="row" justify="flex-start" alignItems="center">
            <Grid item xs={widgetProps.labelAfter ? 9 : 12} style={{ paddingLeft: 0 }}>
                <Autocomplete
                    id={widgetProps.id}
                    className={classes.autocomplete}
                    disabled={!isJourney || isReadOnly}
                    options={options || []}
                    getOptionLabel={option => option}
                    value={value}
                    onChange={changeHandler}
                    {...(!widgetProps.multiple ? { onInputChange: changeHandler } : {})}
                    freeSolo={widgetProps.useDrugList}
                    filterOptions={(options, state) => {
                        const searchedValue = (() => {
                            if (
                                value &&
                                !isArray(value) &&
                                typeof value === 'string' &&
                                widgetProps.useDrugList &&
                                !state.inputValue
                            ) {
                                return (value || '').toLowerCase();
                            }
                            return state.inputValue.toLowerCase();
                        })();
                        if ((!widgetProps.autocompleteSearch && !widgetProps.useDrugList) || !searchedValue)
                            return options;
                        const optionsStartingWithSearchValue = options.filter(option =>
                            startsWith((option || '').toLowerCase(), searchedValue)
                        );
                        const includingSearchValue = options.filter(option =>
                            (option || '').toLowerCase().includes(searchedValue)
                        );
                        return uniq([...optionsStartingWithSearchValue, ...includingSearchValue]);
                    }}
                    getLimitTagsText={more => `+${more}`}
                    limitTags={widgetProps.limitTags ? widgetProps.limitTags : widgetProps.labelAfter ? 2 : 3}
                    multiple={widgetProps.multiple}
                    renderInput={params => (
                        <TextField
                            {...params}
                            className={classes.autocompleteTextField}
                            label={widgetProps.title}
                            variant="outlined"
                            fullWidth
                            error={hasErrors(widgetProps.id, errors) && !isReadOnly}
                        />
                    )}
                />
            </Grid>
            {widgetProps.labelAfter && (
                <Grid item xs={3}>
                    <Typography>{widgetProps.labelAfter}</Typography>
                </Grid>
            )}
        </Grid>
    );
};

AutoCompleteWidget.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    widgetProps: PropTypes.object.isRequired,
    isSubItem: PropTypes.bool
};

export default withStyles(formStyles)(AutoCompleteWidget);
