import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const ConsentWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    .html-content {
        font-family: Roboto, Arial, Sans-serif;
        text-align: justify;
        font-size: 22px;
        word-break: break-word;
    }
    margin-bottom: 2rem;
    padding-right: 24px;
    position: relative;
    min-height: 100%;
`;

export const Signature = styled.div`
    width: 100%;
    border: 1px solid black;
    box-sizing: border-box;
    overflow: hidden;
`;

export const TakenByContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const FullWidthTypography = styled(Typography)`
    width: 100%;
    padding-bottom: 10px;
`;
