import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/configureStore';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { ThemeProvider, createTheme } from '@material-ui/core';
import theme from './styles/theme';
import App from './lib/app';
import './styles/index.css';
import { AccessTierProvider } from './lib/collums-components/hooks/accessTier';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={createTheme(theme)}>
                <BrowserRouter>
                    <AccessTierProvider>
                        <App>
                            <ReduxToastr
                                style={{ zIndex: '9000' }}
                                timeOut={3000}
                                newestOnTop={false}
                                preventDuplicates
                                position="bottom-left"
                                getState={state => state.toastr}
                                transitionIn="fadeIn"
                                transitionOut="fadeOut"
                                progressBar
                                closeOnToastrClick
                            />
                        </App>
                    </AccessTierProvider>
                </BrowserRouter>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
