const { WIDGET_GROUPS, MEDICAL_HISTORY_SELECTIONS } = require('../../../index');

const getForTextArea = ({ field, errors, requiredMessage }) => {
    const mhData = [];
    if (field.isRequired && !field.value) {
        errors.push({ id: field.id, errorType: requiredMessage(field.title) });
    }
    if (field.value && field.group && field.group !== WIDGET_GROUPS.NONE) {
        mhData.push({
            type: MEDICAL_HISTORY_SELECTIONS.DESCRIPTION,
            label: field.value,
            section: field.group
        });
    }
    return { id: field.id, value: field.value, mhData };
};

module.exports = getForTextArea;
