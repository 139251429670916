import { Grid, IconButton, useTheme, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import AutoCompleteWidget from './AutoCompleteWidget';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useForms } from '../../../../hooks/forms';
import formStyles from '../../../../../collums-constants/styles/stylesheets/formStyles';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextAreaAutosizeWidget from './TextAreaAutosizeWidget';

const defaultNewItem = {
    value: null,
    info: ''
};

const ListWidget = ({ widgetProps, onChange, isSubItem = false }) => {
    const isMobile = useMediaQuery('(max-width:540px)');
    const { isJourney, isReadOnly } = useForms();
    const displayingValue = widgetProps.value || [];
    const [newItem, setNewItem] = useState(defaultNewItem);
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));

    if (!displayingValue.length) {
        displayingValue.push({});
    }

    const changeListItem = (fromAutoComplete, value, index) => {
        const newItems = displayingValue.map((el, ind) => {
            if (ind === index) {
                const newValue = { ...el };
                newValue[fromAutoComplete ? 'value' : 'info'] = value;
                return newValue;
            }
            return el;
        });
        onChange(widgetProps.id, newItems);
    };

    const addItem = () => {
        const itemList = [...displayingValue, {}];
        onChange(widgetProps.id, itemList);
        setNewItem(defaultNewItem);
    };

    const excludeItem = index => {
        if (index === 0) {
            setNewItem(defaultNewItem);
        }
        const itemList = displayingValue.filter((_, ind) => ind !== index);
        onChange(widgetProps.id, itemList);
    };

    const ListItem = ({ itemProps, index, isNew = false }) => {
        const itemProp = { ...widgetProps, ...itemProps };
        if (isSubItem) {
            itemProp.title = '';
        }
        return (
            <Grid
                key={index}
                spacing={1}
                container
                direction="row"
                alignItems="center"
                style={{
                    flexWrap: isSM ? 'wrap' : 'nowrap',
                    marginBottom: 8
                }}
            >
                <Grid item xs={12} sm={4} spacing={1} style={{ padding: 4 }}>
                    <AutoCompleteWidget
                        style={{ boxSizing: 'border-box' }}
                        widgetProps={itemProp}
                        onChange={(id, value) => {
                            const defaultValue = widgetProps.multiple ? [] : null;
                            const newValue = value || defaultValue;
                            if (isNew) {
                                setNewItem({
                                    ...newItem,
                                    value: newValue
                                });
                            }
                            changeListItem(true, newValue, index);
                        }}
                        isSubItem
                        fromList
                    />
                </Grid>
                <Grid item xs={12} sm={4} spacing={1} style={{ padding: 4 }}>
                    <TextAreaAutosizeWidget
                        widgetProps={itemProp}
                        onChange={(id, newValue) => {
                            if (isNew) {
                                setNewItem({
                                    ...newItem,
                                    info: newValue
                                });
                            }
                            changeListItem(false, newValue, index);
                        }}
                        isSubItem={true}
                        textKey={'info'}
                        fromList
                    />
                </Grid>
                <Grid
                    item
                    xs={1}
                    sm={2}
                    spacing={1}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        maxWidth: '28%'
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <IconButton
                            style={{
                                visibility: isNew ? 'visible' : 'hidden',
                                padding: isMobile ? 0 : 12
                            }}
                            disabled={!isJourney || isReadOnly}
                            onClick={() => addItem()}
                        >
                            <AddCircleIcon style={{ color: '#3083EC' }} />
                        </IconButton>
                        <IconButton
                            style={{
                                padding: isMobile ? 0 : 12
                            }}
                            disabled={!isJourney || isReadOnly}
                            onClick={() => excludeItem(index)}
                        >
                            <DeleteForeverIcon />
                        </IconButton>
                    </div>
                </Grid>
            </Grid>
        );
    };

    ListItem.propTypes = {
        itemProps: PropTypes.object.isRequired,
        index: PropTypes.number.isRequired,
        isNew: PropTypes.bool
    };

    return (
        <>
            {displayingValue.map((itemProps, index) =>
                ListItem({ itemProps, index, isNew: displayingValue.length === index + 1 })
            )}
        </>
    );
};

ListWidget.propTypes = {
    onChange: PropTypes.func.isRequired,
    widgetProps: PropTypes.object.isRequired,
    isSubItem: PropTypes.bool
};

export default withStyles(formStyles)(ListWidget);
