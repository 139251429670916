import React from 'react';
import JourneyContext from '../contexts/JourneyContext';

function useJourney() {
    const journey = React.useContext(JourneyContext);

    return {
        ...journey
    };
}

export default useJourney;
