export const RenderViewStyles = theme => {
    const formSetup = {
        minWidth: 680
    };
    if (theme.app === 'journey') {
        formSetup.width = '90vw';
    } else if (theme.app !== 'admin') {
        formSetup.width = '100%';
    }

    return {
        formContent: {
            padding: theme.formPadding || '24px 16px 16px',
            margin: theme.formMargin || '0 auto',
            [theme.breakpoints.down('tabletPortrait')]: {
                width: '100%',
                minWidth: 'unset'
            },
            width: theme.formWidth || (theme.app === 'admin' ? 'calc(100% - 56px)' : '90vw'),
            [theme.breakpoints.up('tabletLandscape')]: formSetup,
            '& .MuiGrid-item': {
                width: '100%',
                // minWidth: 'fit-content',
                '& span, p, input, li, textarea': {
                    fontSize: theme.formLabel || '14px',
                    '&::placeholder': {
                        fontSize: (theme.formLabel || '14px') + ' !important'
                    }
                }
            }
        },
        clickHandler: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 999,
            top: 0,
            left: 0
        },
        widgetGrid: {
            position: 'relative',
            '&:hover': {
                '& div': {
                    visibility: 'visible'
                }
            }
        },
        widgetArea: {
            position: 'relative'
        },
        arrowIcons: {
            color: '#333',
            fontSize: 18,
            zIndex: 1000
        },
        deleteItemContainer: {
            visibility: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: '100%',
            zIndex: 1000
        },
        deleteIcon: {
            color: '#FF2800'
        },
        previewButton: {
            backgroundColor: '#2b78e4',
            textTransform: 'capitalize',
            color: 'white',
            borderRadius: '30px !important',
            '&:hover': {
                backgroundColor: '#286cd3'
            },
            marginTop: '40px !important',
            marginLeft: '0px !important'
        },
        widgetsBuilderGridContainer: {
            '& > div:last-child': {
                paddingBottom: 0
            },
            '& *:disabled::placeholder': {
                color: 'rgba(0,0,0,1) !important'
            },
            '& *:disabled': {
                color: 'rgba(0,0,0,1) !important',
                borderColor: 'rgba(0,0,0,1) !important'
            },
            '& .Mui-disabled fieldset': {
                borderColor: 'rgba(0,0,0,1) !important'
            },
            '& .Mui-disabled input::placeholder': {
                color: 'rgba(0,0,0,1) !important'
            },
            '& .Mui-disabled': {
                color: 'rgba(0,0,0,1) !important',
                '& .MuiFormControlLabel-label': {
                    color: 'rgba(0,0,0,1) !important'
                }
            }
        },
        widgetsGridContainer: {
            '& > div:last-child': {
                paddingBottom: 0
            },
            '& *:disabled::placeholder': {
                color: 'rgba(0,0,0,0.5) !important'
            },
            '& *:disabled': {
                color: 'rgba(0,0,0,0.5) !important',
                borderColor: 'rgba(0,0,0,0.5) !important'
            },
            '& .Mui-disabled fieldset': {
                borderColor: 'rgba(0,0,0,0.5) !important'
            },
            '& .Mui-disabled input::placeholder': {
                color: 'rgba(0,0,0,0.5) !important'
            },
            '& .Mui-disabled': {
                color: 'rgba(0,0,0,0.5) !important',
                '& .MuiFormControlLabel-label': {
                    color: 'rgba(0,0,0,0.6) !important'
                }
            }
        }
    };
};
