import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import MuiDivider from '@material-ui/core/Divider';
import StepperNavigation from '../../organisms/StepperNavigation/StepperNavigation';
import JourneyButtons from '../../molecules/JourneyButtons';
import useNavigation from '../../../hooks/useNavigation';
import useLoading from '../../../hooks/useLoading';

function MainTemplate({
    children,
    forceContentInCenter = false,
    backAction,
    continueAction,
    disableContinueAction,
    showPageDetails = false,
    centralizedGrid = false,
    showSkipButton = true
}) {
    const [customHeight, setCustomHeight] = useState('auto');

    const router = useNavigation();
    const { setIsLoading } = useLoading();

    useLayoutEffect(() => {
        if (showPageDetails) {
            const updateHeight = () => {
                const element = document.getElementById('content-container');
                const { y } = element.getBoundingClientRect();
                const newCustomHeight = window.innerHeight - y;
                setCustomHeight(`${newCustomHeight}px`);
            };

            window.addEventListener('resize', updateHeight);
            window.addEventListener('orientationchange', updateHeight);
            updateHeight();

            return () => {
                window.removeEventListener('resize', updateHeight);
                window.removeEventListener('orientationchange', updateHeight);
            };
        }
    }, [showPageDetails]);

    const generalParams = {
        useTopPadding: showPageDetails
    };

    const pageContainerParams = {};

    return (
        <Container id="main-container" {...generalParams}>
            {showPageDetails && (
                <>
                    <HeaderPadding>
                        <Typography variant="h2" component="h1">
                            Patient Journey
                        </Typography>
                    </HeaderPadding>
                    <Divider />
                    <HeaderPadding>
                        <StepperNavigation />
                    </HeaderPadding>
                </>
            )}
            <Content {...generalParams} id="content-container">
                <ContentWrapper
                    {...pageContainerParams}
                    {...generalParams}
                    customHeight={customHeight || 'auto'}
                    isCentered={forceContentInCenter}
                    centralizedGrid={centralizedGrid}
                    id="content-wrapper"
                >
                    {children}
                </ContentWrapper>
                {router.stepInfo && router.stepInfo.id !== 'home' && (
                    <ActionsWrapper>
                        <JourneyButtons
                            backAction={backAction}
                            continueAction={async () => {
                                setIsLoading(true);
                                await continueAction();
                                setIsLoading(false);
                            }}
                            disableContinueAction={disableContinueAction}
                            continueText="Save & Continue"
                            showSkipButton={showSkipButton}
                        />
                    </ActionsWrapper>
                )}
            </Content>
        </Container>
    );
}

const ContentWrapper = styled.div`
    width: 100%;
    min-height: calc(var(--vh, 1vh) * 100 - 100px);
    height: auto;
    box-sizing: border-box;
    ${props => {
        if (props.useTopPadding) {
            return 'display: flex; align-items: center; justify-content: center;';
        }
        return '';
    }}
    ${props => {
        if (props.centralizedGrid) {
            return `
                display: flex;
                flex-direction: column;
                text-align: center;
                align-items: center;
                width: 100%;
            `;
        }
        if (props.isCentered && !props.useTopPadding) {
            return `
                display: flex; 
                justify-content: center; 
                align-items: center;
            `;
        }
        return 'padding-left: 24px;';
    }}
`;

const ActionsWrapper = styled.div`
    background-color: #fff;
    width: 100%;
    height: 100px;
    padding: 1rem;
    border-top: 1px solid rgba(210, 210, 210, 0.6);
    box-sizing: border-box;
`;

const Container = styled.div.attrs(props => ({
    useTopPadding: props.useTopPadding,
    customHeight: props.customHeight
}))`
    padding-top: ${props => {
        return props.useTopPadding ? '24px' : '0';
    }};
    min-height: ${props => (props.useTopPadding ? props.customHeight : 'calc(var(--vh, 1vh) * 100)')};
    overflow: auto;
    & * {
        box-sizing: border-box;
    }
`;

const Content = styled.div.attrs(props => ({
    isCentered: props.forceContentInCenter
}))`
    display: flex;
    min-height: ${props => (props.useTopPadding ? props.customHeight : 'calc(var(--vh, 1vh) * 100)')};
    flex-direction: column;
    justify-content: space-between;
    ${props => {
        if (props.isCentered) {
            return 'justify-content: center; align-items: center;';
        }
        return '';
    }}
`;

const Divider = styled(MuiDivider)`
    margin: 2rem 0 !important;
`;

const HeaderPadding = styled.div`
    padding: 0px 24px;
`;

export default MainTemplate;
