import React from 'react';
import Api from '../../../services/Api';
import useQs from '../../hooks/useQs';
import { ORGANISATION_FORMS_TARGET, ORGANISATION_FORM_TYPES } from '../../collums-constants';
import { uniqBy } from 'lodash';

const AppointmentContext = React.createContext({});

export const AppointmentContextProvider = ({ children }) => {
    const [appts, setAppts] = React.useState({});
    const [isValidAppt, setIsValidAppt] = React.useState(true);
    const qs = useQs();
    const apptId = qs.get('appointmentId');

    const getAppointment = React.useCallback(async () => {
        if (!apptId) {
            return;
        }
        try {
            const { data } = await Api.get(`appointments/${apptId.replace(/[,]/g, '-')}?multiple=true`);
            if (data) {
                setAppts({
                    appointments: [...data]
                });

                const serviceIds = data
                    ?.map(appt => {
                        return appt.service.id;
                    })
                    .filter(el => el)
                    .join(',');

                const queryParam = serviceIds ? `?serviceIds=${serviceIds}` : '';

                const formsRequest = await Api.get(`forms/service/Medical-General-Aftercare${queryParam}`);
                const consentsRequest = await Api.get(`forms/service/Consent${queryParam}`);
                const journeyRequest = await Api.get(`journey/${data[0].id}`);
                const masterFormsRequest = await Api.get(`forms/get-master-forms?customer=${data[0].customer?.id}`);

                const getForms = (order, formList) => {
                    const orderedForms = order
                        .map(form => {
                            return formList.find(filteredItem => filteredItem.id === form);
                        })
                        .filter(el => el);

                    return uniqBy(orderedForms, 'id');
                };

                const [customerFormsList, practitionerFormsList] = (data || []).reduce(
                    (acc, appt) => {
                        acc[0].push(...(appt.service.customerForms || []));
                        acc[1].push(...(appt.service.practitionerForms || []));
                        return acc;
                    },
                    [[], []]
                );

                const forms = formsRequest.data;
                const consents = consentsRequest.data;

                const allForms = [...(forms || []), ...(consents || [])];

                const customerMasterForms = (masterFormsRequest.data || []).filter(form =>
                    (form.isFor || []).includes(ORGANISATION_FORMS_TARGET.CLIENT)
                );

                const customerForms = getForms(customerFormsList, allForms);

                const practitionerForms = getForms(practitionerFormsList, allForms);

                const masterForms = (masterFormsRequest.data || []).filter(
                    el => ORGANISATION_FORM_TYPES.CONSENT !== el.type
                );

                const allAppearingForms = uniqBy([...masterForms, ...customerForms, ...practitionerForms], 'id');

                setAppts({
                    appointments: [...data],
                    customer: data[0].customer,
                    customerForms: [...customerMasterForms, ...customerForms],
                    practitionerForms: [...practitionerForms],
                    journey: journeyRequest.data,
                    allForms: allAppearingForms,
                    masterForms: masterFormsRequest.data || []
                });
                setIsValidAppt(true);
            } else {
                setIsValidAppt(false);
            }
        } catch (error) {
            console.error(error);
            setIsValidAppt(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apptId]);

    React.useEffect(() => {
        getAppointment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateApptData = data => {
        setAppts({ ...appts, ...data });
    };
    return (
        <AppointmentContext.Provider value={{ ...appts, updateApptData, isValidAppt, getAppointment }}>
            {children}
        </AppointmentContext.Provider>
    );
};

export default AppointmentContext;
