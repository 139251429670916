import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';

const StyledHeaderRow = styled(TableRow)`
  background-color: #fafafa;
`;

const StyledTableBody = styled(TableBody)`
    tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.04);
    }
`;

function DifferenceTable({ data }) {
    const [oldData, setOldData] = useState(data.oldData || {});
    const [newData, setNewData] = useState(data.newData || {});

    useEffect(() => {
        setOldData(data.oldData || {});
        setNewData(data.newData || {});
    }, [data]);

    const getSplittedValue = (value, key) => {
        if (typeof value !== 'string') return null;
        if (value.length > 10 && value.includes('image/png')) return 'Updated';
        return (value || '').split('|').map(value => {
            return <Typography key={`${key}-${value}`}>{value}</Typography>;
        });
    };

    const renderBody = () => {
        return Object.keys(oldData).map((key, idx) => {
            return (
                <TableRow key={key + idx}>
                    <TableCell>{key}</TableCell>
                    <TableCell>{getSplittedValue(oldData[key] || '', `old-${key}`)}</TableCell>
                    <TableCell>{getSplittedValue(newData[key] || '', `new-${key}`)}</TableCell>
                </TableRow>
            );
        });
    };
    return (
        <Table>
            <TableHead>
                <StyledHeaderRow>
                    <TableCell>Field</TableCell>
                    <TableCell>Previous</TableCell>
                    <TableCell>New</TableCell>
                </StyledHeaderRow>
            </TableHead>
            <StyledTableBody>{renderBody()}</StyledTableBody>
        </Table>
    );
}

export default DifferenceTable;
