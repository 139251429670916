export const confirmExitModalStyles = () => ({
    cancelModal: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center'
    },
    cancelModalContainer: {
        backgroundColor: 'white',
        margin: 'auto',
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    mdSpacer: {
        marginBottom: '1rem'
    },
    smSpacer: {
        marginBottom: '0.3rem'
    }
});
