export default [
    {
        label: 'First name',
        key: 'firstName',
        disabled: true
    },
    {
        label: 'Surname',
        key: 'surname',
        disabled: true
    },
    {
        label: 'Dob',
        key: 'dateOfBirth',
        disabled: true
    },
    {
        label: 'Address 1',
        key: 'address1'
    },
    {
        label: 'Address 2',
        key: 'address2'
    },
    {
        label: 'City',
        key: 'city'
    },
    {
        label: 'Post code',
        key: 'postCode'
    },
    {
        label: 'Country',
        key: 'country'
    },
    {
        label: 'Email',
        key: 'email'
    },
    {
        label: 'Mobile',
        key: 'mobilePhone'
    },
    {
        label: 'Receive offers',
        key: 'marketingTypes'
    }
];
