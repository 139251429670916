const PRACTITIONER_ORDER = 'practitionerOrder';
const ZOOM_LEVEL = 'zoomLevel';
const TOKEN = 'token';
const HIDED_STAFF = 'hidedStaff';
const CURRENT_CLINIC = 'currentClinic';
const WSCONNECTION = 'wsConnection';
const NOT_VISIBLE_START = 'notVisibleStart';

module.exports = {
    PRACTITIONER_ORDER,
    ZOOM_LEVEL,
    TOKEN,
    HIDED_STAFF,
    CURRENT_CLINIC,
    WSCONNECTION,
    NOT_VISIBLE_START
};
