import * as imageConversion from 'image-conversion';
import PhotosApi from '../api/PhotosApi';
import Promise from 'bluebird';
import TreatmentNoteApi from '../api/TreatmentNoteApi';

const uploadPhotoAndThumbnail = async (
    photos,
    treatmentId,
    customerId,
    isFrom,
    onUploadPhoto,
    onUploadError,
    isDraft = false
) => {
    const newPhotos = [];
    const haveAnGoodInternetSpeed = [];
    const downloadSize = 658800;
    const downloadImgSrc = new Image();

    downloadImgSrc.onload = function() {
        const end_time = new Date().getTime();
        const timeDuration = (end_time - time_start) / 1000;
        const loadedBits = downloadSize * 8;

        const bps = (loadedBits / timeDuration).toFixed(2);
        const speedInKbps = (bps / 1024).toFixed(2);
        const speedInMbps = (speedInKbps / 1024).toFixed(2);
        haveAnGoodInternetSpeed.push(speedInMbps >= 3 ? true : false);
    };
    const time_start = new Date().getTime();
    downloadImgSrc.src = 'https://upload.wikimedia.org/wikipedia/commons/4/49/Fann_Mountains_vertical_2013.jpg';

    const processedPhotos = await Promise.map(photos, async (photo, index) => {
        try {
            const thumbnail = await imageConversion.compress(photo.file, {
                scale: 0.2,
                type: photo.file.type
            });
            const fileThumbnail = new File([thumbnail], `thumbnail-${photo.name}`, {
                type: thumbnail.type,
                lastModified: new Date().getTime()
            });
            fileThumbnail.id = `thumbnail-${photo.name}-${index}`;
            return {
                photo,
                name: photo.name,
                fileThumbnail
            };
        } catch (error) {
            return undefined;
        }
    }).filter(current => current);

    const photosWithPhotoID = [];

    await Promise.each(processedPhotos, async current => {
        try {
            const photoId = await PhotosApi.createWithoutPhoto({
                name: current.name,
                customer: customerId,
                origin: {
                    isFrom,
                    ref: treatmentId
                },
                isDraft: isDraft
            });

            photosWithPhotoID.push({
                ...current,
                photoId
            });
        } catch (error) {
            if (onUploadError) onUploadError(error, current.photo);
        }
    });

    const uploadPhotos = async current => {
        try {
            const uploadedPhoto = await PhotosApi.uploadPhotos(current.photo, treatmentId, customerId);
            const thumbnailURL = await PhotosApi.uploadPhotos(current.fileThumbnail, treatmentId, customerId, true);

            const FinishedPhotoUpload = await PhotosApi.finishUploadPhotoStage(current.photoId, {
                url: uploadedPhoto.url,
                thumbnail: thumbnailURL.url,
                oldUrl: current.photo.url
            });
            newPhotos.push({
                ...FinishedPhotoUpload,
                url: uploadedPhoto.url,
                oldUrl: current.photo.url
            });

            if (onUploadPhoto) {
                onUploadPhoto({ ...current.photo, id: current.photoId, newUrl: uploadedPhoto.url });
            }
        } catch (error) {
            if (onUploadError) onUploadError(error, current.photo);
            return error;
        }
    };

    if (!haveAnGoodInternetSpeed[0]) {
        for (let i = 0; i < photosWithPhotoID.length; i++) {
            await uploadPhotos(photosWithPhotoID[i]); // process images synchronously
        }
    } else {
        await Promise.map(
          photosWithPhotoID,
          async current => {
              await uploadPhotos(current);
          },
          { concurrency: 2 } // process images asynchronously batched by 2
        );
    }

    await TreatmentNoteApi.updatePhotoList(treatmentId);

    return newPhotos;
};

export default uploadPhotoAndThumbnail;
