import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import apps from '../../config';
import steps from '../../constants/steps';
import useAppointment from '../../hooks/useAppointment';
import useJourney from '../../hooks/useJourney';
import useLoading from '../../hooks/useLoading';
import useQs from '../../hooks/useQs';
import useAuth from '../../hooks/useAuth';
import AuthApi from '../../collums-components/api/AuthApi';

const NavigationContext = React.createContext({});

export const NavigationContextProvider = ({ children, activeStep }) => {
    const { journey } = useJourney();
    const history = useHistory();
    const { user } = useAuth();
    const { isValidAppt, appointments } = useAppointment();
    const { setIsLoading } = useLoading();
    const location = useLocation();
    const qs = useQs();
    const apptId = qs.get('appointmentId');
    const [previousPath, setPreviousPath] = useState('');
    const didFirstRedirect = useRef(false);

    useEffect(() => {
        if (journey?.stepsToIgnore && location.pathname) {
            const currentTab = (location.pathname || '').replace('/', '');
            const isFromSummary = previousPath === '/summary';
            const isJourneyCompleted = journey?.isClientJourneyCompleted && journey?.lastStep?.label === 'summary';

            const isIgnoredPath = (journey.stepsToIgnore || []).includes(currentTab);

            // Verify user is logged to app and has been logged to summary page and journey has been completed.
            const userEntry =
                (currentTab === 'forms' || currentTab === 'client-details') &&
                user &&
                (user?.id || user?._id) &&
                isFromSummary &&
                isJourneyCompleted;

            if (isIgnoredPath && !userEntry) {
                const currentIndex = steps.findIndex(step => step.id === currentTab);
                if (currentIndex > -1) {
                    const newPageId = steps[currentIndex + 1]?.id || '';
                    if (newPageId) history.push(`${newPageId}?appointmentId=${apptId}`);
                }
            }
        }
        //eslint-disable-next-line
    }, [journey, location.pathname, previousPath]);

    useEffect(() => {
        if (!previousPath) {
            setPreviousPath(location.pathname);
            return;
        }
        return () => {
            setPreviousPath(location.pathname);
        };
        //eslint-disable-next-line
    }, [location.pathname]);

    const redirectPractitioner = async () => {
        await AuthApi.unlockUser();
        window.location.href = apps.calendarUrl;
    };

    useEffect(() => {
        if (!Object.keys(user || {}).length) return;
        if (didFirstRedirect.current || !appointments) return;
        if (!apptId || !isValidAppt) {
            window.location.href = apps.calendarUrl;
            return;
        }
        const isServicePractitioner = appointments.some(appt => {
            return appt.practitioner.id === user.id;
        });
        if (!isServicePractitioner && journey?.isClientJourneyCompleted) {
            redirectPractitioner();
            return;
        }
        if (journey !== undefined && !_.keys(journey).length) {
            didFirstRedirect.current = true;
            history.push(`/?appointmentId=${apptId}`);
        } else if (journey !== undefined && _.keys(journey).length && journey?.customer?._id) {
            const id = journey.lastStep.label === 'init' ? '' : journey.lastStep.label;
            const idx = steps.findIndex(step => step.id === id);
            setIsLoading(false);
            didFirstRedirect.current = true;
            const referenceFormParam = journey.lastStep.referenceId ? `&formId=${journey.lastStep.referenceId}` : '';
            history.push(`${steps[idx]?.id || ''}?appointmentId=${apptId}${referenceFormParam}&firstRedirect=true`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [journey, apptId, history, isValidAppt, user]);
    return (
        <NavigationContext.Provider value={{ activeStep, previousPath, didFirstRedirect }}>
            {children}
        </NavigationContext.Provider>
    );
};

export default NavigationContext;
