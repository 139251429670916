const { WIDGET_GROUPS, MEDICAL_HISTORY_SELECTIONS } = require('../../../index');

const getForCheckbox = ({ field, errors }) => {
    const mhData = [];
    const isValidMHSection = field.group && field.group !== WIDGET_GROUPS.NONE;
    if (field.isRequired && !field.value) {
        errors.push({ id: field.id, errorType: `field ${field.title} should be checked` });
    }
    const section = field.group;
    if (field.value && field.group && field.group !== WIDGET_GROUPS.NONE && field.mhSelection) {
        const type = field.mhSelection.showAs || '';
        const label = field.mhSelection.customValue || field.title;
        if (type) {
            mhData.push({
                type,
                label,
                section
            });
        }
    }
    if (
        !field.value &&
        isValidMHSection &&
        field.mhSelection &&
        field.mhSelection.showAs === MEDICAL_HISTORY_SELECTIONS.LABEL
    ) {
        mhData.push({
            type: MEDICAL_HISTORY_SELECTIONS.LABEL,
            label: field.mhSelection.customValue || field.title,
            section,
            isRemoval: true
        });
    }
    return {
        id: field.id,
        value: field.value || false,
        mhData
    };
};

module.exports = getForCheckbox;
