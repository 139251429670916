import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Modal } from '@material-ui/core';

const LoadingScreen = ({ isVisible, afterRender }) => {
    React.useEffect(() => {
        if (!afterRender) return;

        // TODO: using this causes the loading screen to show immediately.
        // There should be a better way than this + it doesn't actually speed up the loading,
        // but it requires a large refactor first.
        setTimeout(afterRender, 100);
    }, [afterRender]);

    if (isVisible !== undefined) {
        return (
            <div
                style={{
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    top: '0%',
                    left: '0%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000000,
                    outline: 'none',
                    visibility: !isVisible ? 'hidden' : 'visible',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)'
                }}
            >
                <CircularProgress style={{ outline: 'none' }} disableShrink color="primary" />
            </div>
        );
    }
    return (
        <Modal open>
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    outline: 'none'
                }}
            >
                <CircularProgress style={{ outline: 'none' }} disableShrink color="primary" />
            </div>
        </Modal>
    );
};
LoadingScreen.propTypes = {
    isVisible: PropTypes.bool,
    afterRender: PropTypes.func
};
export default LoadingScreen;
