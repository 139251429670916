import Api from './config';

export default class AddressApi {
    static getCountries(clinicId) {
        return Api.request({
            method: 'get',
            url: `/countries?${!clinicId ? '' : `clinic=${clinicId}`}`
        }).catch(err => err);
    }

    static async addressLookup(zipcode) {
        try {
            if (!zipcode) {
                return {};
            }

            const response = await (
                await fetch(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode}&key=${'AIzaSyAxbQiEskQDZ9aqwfkK1wMay5rDLaYy9xY'}`
                )
            ).json();

            const fullAddress = await response.results[0].address_components.reduce((acc, current) => {
                const types = current.types;
                if (types.includes('route')) {
                    acc = { ...acc, address: current.long_name };
                } else if (types.includes('postal_town')) {
                    acc = { ...acc, city: current.long_name };
                } else if (types.includes('country')) {
                    acc = { ...acc, country: current.long_name };
                }
                return acc;
            });
            return fullAddress;
        } catch (error) {
            return {};
        }
    }
}
