const malePhotos = [
    {
        url: `${process.env.REACT_APP_BACKEND_URL || process.env.COLLUMS_API_URL}/images/full-face-male.jpg`,
        name: 'Full Face - male'
    },
    {
        url: `${process.env.REACT_APP_BACKEND_URL || process.env.COLLUMS_API_URL}/images/face-&-neck-male.jpg`,
        name: 'Face & Neck - male'
    },
    {
        url: `${process.env.REACT_APP_BACKEND_URL || process.env.COLLUMS_API_URL}/images/lips-&-nose-male.jpg`,
        name: 'Lips & Nose - male'
    },
    {
        url: `${process.env.REACT_APP_BACKEND_URL ||
            process.env.COLLUMS_API_URL}/images/front-&-back-full-body-male.jpg`,
        name: 'Front & Back Full Body - male'
    }
];

const femalePhotos = [
    {
        url: `${process.env.REACT_APP_BACKEND_URL || process.env.COLLUMS_API_URL}/images/full-face-female.jpg`,
        name: 'Full Face - female'
    },
    {
        url: `${process.env.REACT_APP_BACKEND_URL || process.env.COLLUMS_API_URL}/images/face-&-neck-female.jpg`,
        name: 'Face & Neck - female'
    },
    {
        url: `${process.env.REACT_APP_BACKEND_URL || process.env.COLLUMS_API_URL}/images/lips-&-nose-female.jpg`,
        name: 'Lips & Nose - female'
    },
    {
        url: `${process.env.REACT_APP_BACKEND_URL ||
            process.env.COLLUMS_API_URL}/images/front-&-back-full-body-female.jpg`,
        name: 'Front & Back Full Body - female'
    }
];

const DEFAULT_TREATMENT_PHOTOS_BY_GENDER = {
    MALE: malePhotos,
    FEMALE: femalePhotos,
    ALL: [...malePhotos, ...femalePhotos]
};

module.exports = DEFAULT_TREATMENT_PHOTOS_BY_GENDER;
