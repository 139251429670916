/* eslint-disable comma-dangle */
import React from 'react';
import styled from 'styled-components';
import MuiStepper from '@material-ui/core/Stepper';
import MuiStep from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import useNavigation from '../../../hooks/useNavigation';

const visibleSteps = [
    {
        label: 'Check Your Details'
    },
    {
        label: 'Forms',
        maxWidth: '80px'
    },
    {
        label: 'Consent',
        maxWidth: '80px'
    },
    {
        label: 'Photos',
        maxWidth: '85px'
    },
    {
        label: 'Treatment',
        maxWidth: '90px'
    }
];

function StepperNavigation() {
    const navigation = useNavigation();
    return (
        <Stepper activeStep={navigation.activeStep}>
            {visibleSteps.map(step => (
                <Step maxWidth={step.maxWidth} key={step.label}>
                    <StepLabel>{step.label}</StepLabel>
                </Step>
            ))}
        </Stepper>
    );
}

const Step = styled(MuiStep).attrs(props => ({ maxWidth: props.maxWidth || '158px' }))`
    padding: 0 !important;
    white-space: nowrap;
    max-width: ${props => props.maxWidth} !important;
    @media (max-width: 768px) {
        flex: 1 1 100%;
    }
`;

const Stepper = styled(MuiStepper)`
    display: flex;
    justify-content: space-between !important;
    padding: 12px !important;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;

    .MuiStepConnector-root {
        display: none;
    }
`;

export default StepperNavigation;
