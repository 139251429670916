import Api from './config';

export default class AuthApi {
    static removeToken() {
        return Api.request({
            method: 'POST',
            url: '/user/unauthorize'
        }).catch(err => err);
    }

    static async getMe() {
        return Api.request({
            method: 'GET',
            url: '/user/me'
        });
    }

    static async refreshToken() {
        return Api.request({
            method: 'GET',
            url: '/refresh-token'
        });
    }

    static lockUser() {
        return Api.request({
            method: 'POST',
            url: '/lock-user'
        });
    }

    static unlockUser(data = {}) {
        return Api.request({
            method: 'POST',
            url: '/unlock-user',
            data
        });
    }

    static isUserLocked() {
        return Api.request({
            method: 'GET',
            url: '/is-user-locked'
        });
    }

    static sendPinWebSocket(data) {
        return Api.request({
            method: 'POST',
            url: '/send-pin-websocket',
            data
        });
    }

    static isCorrectPIN(data) {
        return Api.request({
            method: 'POST',
            url: '/verify-pin-with-user',
            data
        });
    }

    static getOrg() {
        return Api.request({
            method: 'GET',
            url: '/organisations'
        });
    }
}
