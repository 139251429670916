const { WIDGET_GROUPS } = require('../../../index');

const getForRadio = ({ field, errors }) => {
    const mhData = [];
    if (field.isRequired && (!field.value || !(field.options || []).includes(field.value))) {
        errors.push({ id: field.id, errorType: `field ${field.title} should be checked` });
    }
    if (
        field.value &&
        field.group &&
        field.group !== WIDGET_GROUPS.NONE &&
        (field.mhSelection || field.mhPositiveSelection)
    ) {
        const section = field.group;
        if (field.mhSelection) {
            const type = field.mhSelection.showAs || '';
            const label = field.mhSelection.customValue || field.value;

            mhData.push({
                type,
                label,
                section
            });
        }

        const fieldValue = (field.value || 'no').toLowerCase();
        if (field.mhPositiveSelection && ['yes', 'no'].includes(fieldValue)) {
            const type = field.mhPositiveSelection.showAs;
            const label = field.mhPositiveSelection.customValue || field.title;

            if (type) {
                mhData.push({
                    type,
                    label,
                    section,
                    isRemoval: fieldValue === 'no'
                });
            }
        }
    }
    return { id: field.id, value: field.value, mhData };
};

module.exports = getForRadio;
