import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Typography, withStyles, makeStyles } from '@material-ui/core';

import { modalsButtonStyles } from '../../../../collums-constants/styles/stylesheets/buttonsStyles';
import { confirmExitModalStyles as styles } from './styles';

/**
 * Confirm Exit Modal React component.
 * @param {boolean} isOpen - Set if modal is open or closed.
 * @param {boolean} onConfirm - Function called when the confirm button is triggered.
 * @param {boolean} onCancel - Function called when the cancel button is triggered.
 * @param {boolean} preventClosing - Prevent closing the modal when clicking outside it's bounds.
 * @param {string} title - Title of the modal.
 * @param {string} text - Text of the modal.
 * @returns {React.Component} React Component.
 */

function ConfirmExitModal({
    classes,
    id,
    isOpen,
    onConfirm,
    onCancel,
    preventClosing = false,
    title,
    text,
    confirmLabel,
    cancelLabel,
    actions
}) {
    const globalStyles = makeStyles(modalsButtonStyles)();

    return (
        <Modal
            disableBackdropClick={preventClosing}
            id={id || 'confirm_exit_modal_id'}
            open={isOpen}
            className={classes.cancelModal}
        >
            <div className={classes.cancelModalContainer}>
                <Typography variant="h5" className={classes.smSpacer}>
                    {title}
                </Typography>
                <Typography className={classes.mdSpacer}>{text}</Typography>
                <div className={globalStyles.buttonsContainer}>
                    <Button
                        id="confirm-exit-modal-back-button"
                        variant="outlined"
                        onClick={e => {
                            e.stopPropagation();
                            onCancel();
                        }}
                        className={globalStyles.cancelButton}
                    >
                        {cancelLabel || 'Back'}
                    </Button>
                    {!actions && (
                        <Button
                            id="confirm-exit-modal-continue-button"
                            onClick={onConfirm}
                            className={globalStyles.confirmButton}
                        >
                            {confirmLabel || 'Continue'}
                        </Button>
                    )}
                    {actions &&
                        actions.map((action, index) => (
                            <Button
                                key={index}
                                id={action.id}
                                onClick={action.action}
                                className={globalStyles.confirmButton}
                            >
                                {action.label}
                            </Button>
                        ))}
                </div>
            </div>
        </Modal>
    );
}

ConfirmExitModal.propTypes = {
    classes: PropTypes.object,
    id: PropTypes.string,
    isOpen: PropTypes.bool,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    preventClosing: PropTypes.bool,
    title: PropTypes.string,
    text: PropTypes.string,
    confirmLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    actions: PropTypes.array
};

export default withStyles(styles)(ConfirmExitModal);
