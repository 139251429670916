import React from 'react';
import styled from 'styled-components';
import { Button as MUIButton, Typography } from '@material-ui/core';
import MainTemplate from '../../templates/MainTemplate/MainTemplate';
import useNavigation from '../../../hooks/useNavigation';
import useJourney from '../../../hooks/useJourney';
import useAppointment from '../../../hooks/useAppointment';
import Api from '../../../../services/Api';
import useLoading from '../../../hooks/useLoading';
import { useEffect } from 'react';

function MainPage() {
    const router = useNavigation();
    const { journey, getJourney } = useJourney();
    const appt = useAppointment();
    const { setIsLoading } = useLoading();

    useEffect(() => {
        if (!appt.customer) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appt.customer]);

    const handleStart = async () => {
        setIsLoading(true);
        if (!journey._id) {
            await Api.post('/journey', {
                appointments: appt.appointments.map(a => a._id || a.id).join(','),
                customer: appt.customer.id
            });
            getJourney();
        }
        router.push('/client-details');
        setIsLoading(false);
    };

    return (
        <MainTemplate showPageDetails showSkipButton={false}>
            <Container>
                <Typography>Click start and hand tablet to patient to start journey</Typography>
                <Button variant="contained" color="secondary" onClick={handleStart}>
                    Start
                </Button>
            </Container>
        </MainTemplate>
    );
}

const Button = styled(MUIButton)`
    width: fit-content;
    font-size: 20px !important;
    background-color: #c00076 !important;
    border-radius: 0 !important;
    color: white;
    text-transform: none;
    font-weight: 500 !important;
    padding: 6px 2.5rem !important;
    height: 38;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    & > p {
        font-size: 20px;
    }
    .MuiButton-root {
        margin-top: 1rem;
    }
`;

export default MainPage;
