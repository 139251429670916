import React, { useState, useEffect } from 'react';
import MainTemplate from '../../templates/MainTemplate/MainTemplate';
import styled from 'styled-components';
import CustomerFields from '../../molecules/CustomerFields/CustomerFields';
import useAppointment from '../../../hooks/useAppointment';
import useNavigation from '../../../hooks/useNavigation';
import Api from '../../../../services/Api';
import useJourney from '../../../hooks/useJourney';
import AddressApi from './../../../collums-components/api/AddressApi';
import MHModal from './../../organisms/MHModal';
import { isValidEmail } from '../../../collums-constants/utils';
import isValidPhone from '../../../helpers/isValidPhone';
import { toastr } from 'react-redux-toastr';
import useLoading from '../../../hooks/useLoading';

function ClientDetailsPage() {
    const { setIsLoading } = useLoading();
    const [isShowingMHModal, setIsShowingMHModal] = useState(false);
    const [newCustomerData, setNewCustomerData] = useState({});
    const { customer, updateApptData, masterForms, getAppointment, customerForms } = useAppointment();
    const [countryOptions, setCountryOptions] = useState([]);
    const [invalidData, setInvalidData] = useState({
        email: false,
        phone: false
    });
    const [countryCode, setCountryCode] = useState();
    const router = useNavigation();
    const journey = useJourney();

    const backAction = () => {
        if (router.previousPath === '/summary') {
            router.push('/form-complete');
            return;
        }

        router.push('/');
    };

    useEffect(() => {
        async function getCountriesList() {
            const countries = await AddressApi.getCountries();
            setCountryOptions(countries);
        }

        getCountriesList();
    }, []);

    useEffect(() => {
        setInvalidData({
            email: !newCustomerData.email || !isValidEmail(newCustomerData.email),
            phone: !isValidPhone(newCustomerData.mobilePhone, countryCode)
        });
    }, [newCustomerData, countryCode]);

    const continueAction = async () => {
        try {
            const journeyId = journey.journey._id;
            const newDataKeys = Object.keys(newCustomerData || {});
            const newDataHandler = { ...newCustomerData };
            if (newDataKeys.length) {
                newDataKeys.forEach(property => {
                    if (newCustomerData[property] === customer[property]) delete newDataHandler[property];
                });
                try {
                    await Api.put(`/customers/${customer.id}?journey=${journeyId}`, newDataHandler);
                } catch (err) {
                    toastr.error(err?.response?.data?.message || 'Something went wrong');
                    return;
                }
                updateApptData({
                    customer: { ...customer, ...newDataHandler }
                });
            }
            if (masterForms && masterForms.length) {
                const formsCount = await Api.get(`/forms/count-master-forms/${customer.id}?ignoreJourney=${journeyId}`);
                if (formsCount.data && router.previousPath !== '/summary') {
                    setIsShowingMHModal(true);
                } else {
                    updateMH(true);
                }
            } else {
                updateMH(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const updateMH = async (changedMH, createMH) => {
        try {
            setIsLoading(true);
            await journey.updateJourney({
                updateMedicalHistory: changedMH,
                createPMHForm: createMH
            });

            if (router.previousPath === '/summary') {
                router.push('/form-complete');
                setIsLoading(false);
                return;
            }

            if (changedMH) {
                getAppointment();
            } else {
                if (
                    customerForms &&
                    customerForms.length <= 1 &&
                    (!customerForms[0] || customerForms[0]?.isMasterForm)
                ) {
                    setIsLoading(false);
                    router.push('/form-complete');
                    return;
                }
            }
            setIsLoading(false);
            router.push('/forms');
        } catch (err) {
            setIsLoading(false);
        }
    };

    if (!customer) return null;
    return (
        <MainTemplate
            backAction={backAction}
            continueAction={continueAction}
            disableContinueAction={Object.values(invalidData).some(el => el)}
            showSkipButton={false}
        >
            <Container>
                <Title>Check Your Details</Title>
                <br />
                <Subtitle>
                    Please check your details are correct. If they are not, tap on the field to update.
                    <br />
                    Tell a member of staff if your name or dob is incorrect.
                    <br />
                    Do not include the first 0 of your mobile number.
                </Subtitle>
                <br />
                <Subtitle>Once complete, click Save & Continue</Subtitle>
                <form>
                    <CustomerFields
                        customer={customer}
                        setNewCustomerData={setNewCustomerData}
                        newCustomerData={newCustomerData}
                        countryOptions={countryOptions}
                        setCountryCode={setCountryCode}
                        invalidData={invalidData}
                    />
                </form>
            </Container>
            <MHModal
                isOpen={isShowingMHModal}
                onReject={() => updateMH(false, true)}
                onConfirm={() => updateMH(true, true)}
            />
        </MainTemplate>
    );
}

const Container = styled.div`
    width: 100%;
    height: 80%;
    margin: 0 0 2rem;
`;
const Title = styled.div`
    font-weight: 500;
    font-size: 22px;
    text-align: center;
    font-family: 'Roboto', 'Arial';
    padding: 24px 12px 12px;
`;
const Subtitle = styled.p`
    font-family: 'Roboto', 'Arial';
    width: 75%;
    font-size: 18px;
    @media (max-width: 960px) {
        width: 100%;
    }
    margin: 0px auto;
    text-align: center;
`;

export default ClientDetailsPage;
