import axios from 'axios';
import moment from 'moment';

function stringToMomentTransformer(o) {
    Object.keys(o).forEach(function (k) {
        if (o[k] !== null && typeof o[k] === 'object') {
            o[k] = stringToMomentTransformer(o[k]);
            return;
        }
        if (
            typeof o[k] === 'string' &&
            /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)(\.\d+([+-][0-2]\d:[0-5]\d|Z)|(\+\d{2}:\d{2}))/.test(o[k])
        ) {
            o[k] = moment(o[k]);
        }
    });
    return o;
}

const Api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL || 'https://staging.api.collums.co.uk',
    headers: {
        'Content-Type': 'application/json'
    },
    transformRequest: [
        (data, headers) => {
            headers['Authorization'] = localStorage.getItem('token');
            return JSON.stringify(data);
        }
    ],
    transformResponse: [
        data => {
            try {
                return stringToMomentTransformer(JSON.parse(data));
            } catch (err) {
                console.error(err);
                return data;
            }
        }
    ]
});

export default Api;
