export const closeBtnStyles = () => ({
    button: {
        padding: 8,
        '& .material-icons': {
            width: 18,
            height: 18
        },
        '& .img': {
            width: 18,
            height: 18
        }
    }
});
export const mergeTagsStyles = () => ({
    mergeTagsBox: {
        width: '100%',
        borderColor: 'rgba(0,0,0,0.2)',
        borderTop: 'none',
        borderRadius: '0 0 2px 2px'
    },
    mergeTagsHeader: {
        fontFamily: 'Roboto, Arial, "sans-serif"',
        display: 'flex',
        width: '100%',
        height: '24px',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '19px 0',
        background: '#f9f9f9',
        border: '1px solid #0000001f',
        fontWeight: '500',
        fontSize: '14px',
        borderRadius: '2px 2px 0 0'
    },
    mergeTagsBody: {
        width: '100%',
        borderTop: 'none',
        height: 'inherit',
        borderRadius: '0 0 2px 2px'
    },
    mergeTags: {
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: 4
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#c1c1c199',
            borderRadius: 2
        }
    },
    mergeBtn: {
        background: 'white',
        textTransform: 'none',
        fontSize: 12,
        width: '100%',
        boxShadow: 'none',
        padding: 8,
        borderWidth: 1,
        borderRadius: 0,
        '& label': {
            overflow: 'hidden'
        },
        '&:hover': {
            background: '#f7f7f7',
            boxShadow: 'none'
        }
    }
});
export const uploadButtonStyles = () => ({
    inputFile: {
        display: 'none'
    },
    uploadButton: {
        display: 'flex',
        justifyContent: 'center',
        '& > button': {
            padding: '15px',
            borderRadius: '30px',
            color: 'white',
            border: 0,
            cursor: 'pointer',
            fontWeight: '500',
            backgroundColor: '#3f4257',
            fontFamily: 'Roboto, Arial, Sans-serif',
            transition: 'background-color 0.5s'
        },
        '& > button:disabled': {
            cursor: 'default',
            backgroundColor: '#ccc'
        }
    }
});
export const viewModalStyles = () => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        margin: '3vh 15%'
    },
    largeModal: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        margin: '1rem 5%'
    },
    fileFormatLabel: {
        marginTop: 8,
        fontSize: 14,
        textAlign: 'center'
    },
    smallModal: {
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        margin: '3vh 25%'
    },
    root: {
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'min-content',
        paddingBottom: '3vh',
        outline: 'none'
    },
    closeBtn: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: '3%',
        '& p': {
            fontFamily: 'Roboto, Arial, Sans-serif',
            fontWeight: 500,
            fontSize: 16,
            margin: 0
        },
        '& p:nth-child(2)': {
            margin: '0 0 14px 0'
        }
    },
    iconButton: {
        padding: 0
    },
    content: {
        width: '95%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    fullWidthContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'space-around',
        padding: '1rem'
    },
    contentFullWidth: {
        width: '100%'
    },
    table: {
        width: '90%'
        //overflowX: 'auto'
    },
    tableHeader: {
        fontSize: 14,
        opacity: 1,
        fontWeight: 500,
        borderColor: 'black',
        borderWidth: 2
    },
    textarea: {
        width: '90%',
        '& textarea': {
            // maxHeight: '100px',
            overflow: 'scroll'
        }
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '40%',
        margin: '5vh 3vh 3vh 3vh',
        '& button': {
            textTransform: 'none',
            borderRadius: 0,
            borderColor: 'black',
            fontSize: 14,
            fontWeight: 500
        },
        '& button:nth-child(2)': {
            backgroundColor: '#b41778',
            color: 'white'
        }
    },
    photo: {
        height: 250
    },
    modalTitle: {
        fontSize: '18px !important',
        fontFamily: 'Roboto, Arial, Sans-serif !important',
        fontWeight: 500
    },
    marginBottom: {
        marginBottom: 20
    },
    uploadedPhoto: {
        maxWidth: 180,
        maxHeight: 200
    },
    addCircle: {
        fontSize: '55pt',
        color: 'black'
    },
    deleteIcon: {
        fontSize: '25pt',
        color: '#de4545'
    },
    grid: {
        alignItems: 'center !important',
        justifyContent: 'center !important'
    },
    flex: {
        display: 'flex'
    }
});
export const checkboxStyles = () => ({
    checkbox: {
        display: 'grid',
        gridTemplateColumns: 'min-content auto',
        gridGap: '0.5em',
        fontSize: '20pt',
        width: '20px',
        height: '20px',
        color: '#69767E'
    },
    checkboxInput: {
        display: 'grid',
        gridTemplateAreas: 'checkbox',
        '& > *': {
            gridArea: 'checkbox'
        },
        '& > input': {
            opacity: 0,
            width: '20px',
            height: '20px'
        },
        '& input:checked + span svg': {
            transform: 'scale(1)'
        }
    },
    checkboxControl: {
        display: 'inline-grid',
        width: '20px',
        height: '20px',
        borderRadius: '3px',
        border: '2.5px solid currentColor',
        '& > svg': {
            transition: 'transform 0.1s ease-in 25ms',
            transform: 'scale(0)',
            transformOrigin: 'bottom left'
        }
    }
});

export const sidebarStyles = () => ({
    containerWrapper: {
        position: 'relative',
        width: 60,
        height: '100%',
        display: 'flex',
        overflow: 'visible'
    },
    container: {
        position: 'fixed',
        width: 60,
        height: '100%',
        top: 0,
        right: 0,
        overflowX: 'hidden',
        overflowY: 'scroll',
        transition: 'width 100ms',
        zIndex: 1202,
        '&.visible': {
            width: 164
        },
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    labelsContainer: {
        position: 'absolute',
        width: 104,
        minHeight: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#565a65'
    },
    iconsContainer: {
        position: 'absolute',
        width: 60,
        minHeight: '100%',
        top: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#565a65'
    },
    icon: {
        display: 'flex',
        width: '100%',
        height: 48,
        cursor: 'pointer',
        justifyContent: 'center',
        alignItems: 'center',
        '&.active': {
            backgroundColor: '#343232'
        },
        '&.hover': {
            backgroundColor: '#343232'
        },
        '&.disabled': {
            cursor: 'default',
            opacity: 0.5
        },
        '& .material-icons': {
            width: 20,
            height: 20
        },
        '&.lastCustomers .material-icons': {
            width: 27,
            height: 27,
            paddingLeft: 4,
            paddingBottom: 6
        },
        '&.pos .material-icons': {
            width: 26,
            height: 26
        }
    },
    label: {
        color: '#ffffff',
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: 14,
        textAlign: 'right',
        paddingRight: 8,
        paddingTop: 14,
        display: 'block',
        textDecoration: 'none',
        height: 48,
        cursor: 'pointer',
        '&.active': {
            backgroundColor: '#343232'
        },
        '&.hover': {
            backgroundColor: '#343232'
        },
        '&.disabled': {
            cursor: 'default',
            opacity: 0.5
        }
    },
    mobileContainer: {
        width: '100%',
        height: 85,
        cursor: 'pointer',
        backgroundColor: '#565a65',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 12,
        '&.hover': {
            backgroundColor: '#343232'
        },
        '& .material-icons': {
            width: 36,
            height: 36
        }
    }
});
