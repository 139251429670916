import { Paper, withStyles } from '@material-ui/core';
import React from 'react';
import { useForms } from '../../hooks/forms';
import Menu from './menu';
import Render from './render';
import { FormBuilderStyles as styles } from './styles';
import PropTypes from 'prop-types';

const Form = ({ classes, openPreviewModal }) => {
    const { isJourney } = useForms();
    if (!isJourney) {
        return (
            <Paper className={classes.paper}>
                <div className={classes.form}>
                    <Render openPreviewModal={openPreviewModal} />
                </div>
                <div className={classes.menu}>
                    <Menu />
                </div>
            </Paper>
        );
    }
    return <Render />;
};

Form.propTypes = {
    classes: PropTypes.object.isRequired,
    openPreviewModal: PropTypes.func
};

export default withStyles(styles)(Form);
