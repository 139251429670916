import React from 'react';
import Modal from '../Modal';
import { CenteredText, CollumsImageArea, CollumsImage, Link } from './styles';
import { PropTypes } from 'prop-types';

function AccessDisabledModal({ onClose }) {
    return (
        <>
            <Modal isOpen={true} title="" size="xs" confirmLabel="Save" onClose={onClose}>
                <CollumsImageArea>
                    <CollumsImage src="/images/collums_logo.png" />
                </CollumsImageArea>
                <CenteredText>This feature is not available on your plan.</CenteredText>
                <CenteredText>
                    To upgrade, contact <Link href="mailto:hello@collums.co"> hello@collums.co</Link>
                </CenteredText>
            </Modal>
        </>
    );
}

AccessDisabledModal.propTypes = {
    onClose: PropTypes.func
};

export default AccessDisabledModal;
