import React from 'react';
import ConfirmExitModal from '../../../collums-components/components/common/ConfirmExitModal/ConfirmExitModal';
import { ButtonLabel, Text, Title } from './styles';

function MHModal({ isOpen, onReject, onConfirm }) {
    return (
        <ConfirmExitModal
            isOpen={isOpen}
            onConfirm={onConfirm}
            onCancel={onReject}
            preventClosing
            title={<Title>Medical History</Title>}
            text={
                <>
                    <Text>Has anything changed in your medical history since your last visit?</Text>
                    <Text>This includes changes in medication, pregnancy and breastfeeding.</Text>
                </>
            }
            confirmLabel={<ButtonLabel>Yes</ButtonLabel>}
            cancelLabel={<ButtonLabel>No</ButtonLabel>}
        />
    );
}

export default MHModal;
