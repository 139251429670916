const { WIDGET_GROUPS } = require('../../../index');

const getForTextField = ({ field, errors, requiredMessage }) => {
    const mhData = [];
    if (field.isRequired && !field.value) {
        errors.push({ id: field.id, errorType: requiredMessage(field.title) });
    }
    if (field.value && field.group && field.group !== WIDGET_GROUPS.NONE && field.mhText) {
        const type = field.mhText.showAs || '';
        const section = field.group;
        if (type) {
            mhData.push({
                type,
                label: field.value,
                section
            });
        }
    }
    return { id: field.id, value: field.value, mhData };
};

module.exports = getForTextField;
