import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import steps from '../../../constants/steps';
import useNavigation from '../../../hooks/useNavigation';
import MainTemplate from '../../templates/MainTemplate/MainTemplate';

function FinishPage() {
    const { stepInfo, push } = useNavigation();

    const continueAction = () => {
        const nextPage = steps.find(step => step.step === stepInfo.step + 1);
        push(`/${nextPage.id}`);
    };

    const backAction = () => {
        push('/photos');
    };

    return (
        <MainTemplate backAction={backAction} continueAction={continueAction} showSkipButton={false}>
            <Container>
                <Typography>
                    <strong>Please continue this treatment journey in the customer Client Card.</strong>
                </Typography>
            </Container>
        </MainTemplate>
    );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    & > p {
        font-size: 20px;
    }
    .MuiButton-root {
        margin-top: 1rem;
    }

    & strong {
        font-weight: 500;
    }
`;

export default FinishPage;
