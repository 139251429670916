import { Typography, withStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import formStyles from '../../../../../collums-constants/styles/stylesheets/formStyles';
import PropTypes from 'prop-types';
import { useForms } from '../../../../hooks/forms';

const ParagraphWidget = ({ classes, widgetProps }) => {
    const { isReadOnly } = useForms();
    const content = useMemo(() => {
        if (!widgetProps.content) {
            return <></>;
        }
        const splittedContent = widgetProps.content.split('\n');
        if (splittedContent.length === 1) {
            return (
                <Typography className={`${classes.paragraphContent} ${isReadOnly ? classes.textDisabled : ''}`}>
                    {widgetProps.content}
                </Typography>
            );
        }
        return splittedContent.map((content, index) => {
            return (
                <>
                    <Typography
                        className={`${classes.paragraphContent} ${isReadOnly ? classes.textDisabled : ''} ${
                            index !== splittedContent.length ? classes.breakParagraph : ''
                        }`}
                    >
                        {content}
                    </Typography>
                </>
            );
        });
    }, [widgetProps, classes.paragraphContent, classes.breakParagraph, isReadOnly, classes.textDisabled]);

    return (
        <div id={widgetProps.id}>
            {widgetProps.title && (
                <Typography
                    component="h2"
                    variant="h2"
                    className={`${classes.paragraphTitle} ${isReadOnly ? classes.textDisabled : ''}`}
                >
                    {widgetProps.title}
                </Typography>
            )}
            {content}
        </div>
    );
};

ParagraphWidget.propTypes = {
    classes: PropTypes.object.isRequired,
    widgetProps: PropTypes.object.isRequired
};

export default withStyles(formStyles)(ParagraphWidget);
