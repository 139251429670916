import api from './config';

class OrganisationApi {
    static getOrg() {
        return api.request({
            method: 'GET',
            url: '/organisations'
        });
    }
}

export default OrganisationApi;
