export default {
    app: 'journey',
    formLabel: '18px',
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
            mobileMin: 360,
            mobileMd: 400,
            mobileMax: 500,
            ipadPro: 1355,
            desktop: 1500,
            tabletPortrait: 768,
            tabletLandscape: 1112
        }
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
            disableTouchRipple: true
        }
    },
    palette: {
        primary: {
            main: '#3c3c3d'
        },
        secondary: {
            main: '#69767E'
        },
        white: {
            main: '#ffffff'
        },
        black: {
            main: '#343232',
            full: '#000000'
        },
        blue: {
            main: '#2b78e4',
            hover: '#1a67d3'
        },
        red: {
            main: '#FB4336'
        },
        gray: {
            main: '#69767E',
            bold: '#565A65',
            bolder: '#494d57',
            light: '#cccccc',
            lighter: '#eeeeee'
        },
        lightGrey: {
            main: '#575A64'
        },
        wine: {
            main: '#b41778',
            hover: '#912d6b'
        },
        event: {
            primary: '#3c3c3d',
            secondary: '#9d9d9f',
            checkedIn: '#3da768',
            checkedOut: '#2d3548',
            late: '#ef4842',
            missed: '#8a211f',
            block: '#ffffff',
            break: '#aadcf3'
        },
        appointment: '#32384b',
        break: '#aadcf3',
        block: '#6bcbd9'
    },
    typography: {
        fontFamily: ['Roboto', 'Arial', 'Sans-serif'],
        fontSize: 18,
        body1: {
            fontSize: 18
        },
        h6: {
            fontSize: '1.125rem',
            fontFamily: 'Roboto, Arial, Sans-serif'
        },
        h5: {
            fontSize: '1.125rem',
            fontFamily: 'Roboto, Arial, Sans-serif'
        },
        h4: {
            fontSize: '1.125rem',
            fontFamily: 'Roboto, Arial, Sans-serif'
        },
        h3: {
            fontSize: '1.125rem',
            fontFamily: 'Roboto, Arial, Sans-serif'
        },
        h2: {
            fontSize: '20px',
            fontFamily: 'Roboto, Arial, Sans-serif'
        },
        h1: {
            fontSize: '20px',
            fontFamily: 'Roboto, Arial, Sans-serif'
        }
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 4,
                textTransform: 'none',
                fontSize: 18,
                fontWeight: 400,
                padding: '3px 7px'
            },
            text: {
                padding: '3px 7px'
            }
        },
        MuiOutlinedInput: {
            root: {
                height: 38,
                lineHeight: '14px'
            }
        },
        MuiInputLabel: {
            root: {
                lineHeight: '1px',
                color: '#333'
            },
            outlined: {
                transform: 'translate(14px, 19px) scale(1)'
            },
            shrink: {
                transform: 'translate(14px, -4px) scale(0.75) !important'
            }
        },
        MuiSelect: {
            root: {
                fontSize: 18,
                fontFamily: 'Roboto, Arial, Sans-serif',
                display: 'flex',
                alignItems: 'center'
            }
        },
        MuiMenuItem: {
            root: {
                fontSize: 18
            }
        },
        MuiSvgIcon: {
            root: {
                fontSize: 24
            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 10
            }
        },
        MuiDialogActions: {
            root: {
                justifyContent: 'center'
            }
        },
        MuiAutocomplete: {
            inputRoot: {
                height: 38
            }
        },
        MuiTable: {
            root: {
                borderCollapse: 'collapse',
                background: 'white',
                '& tr td': {
                    height: 49,
                    paddingTop: 0,
                    paddingBottom: 0,
                    textAlign: 'left'
                },
                '& tr th': {
                    fontWeight: 500,
                    textAlign: 'left'
                },
                '& tr a': {
                    fontSize: 18,
                    fontWeight: 400,
                    color: '#3083EC',
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'none',
                        cursor: 'pointer'
                    }
                }
            }
        },
        MuiAccordionSummary: {
            content: {
                '& p': {
                    fontSize: 18
                }
            }
        },
        MuiChip: {
            label: {
                fontSize: '12px !important',
                lineHeight: 'initial'
            }
        }
    }
};
