import React from 'react';
import { getName } from '../../../collums-constants/utils';
import useAuth from '../../../hooks/useAuth';
import Moment from 'moment';
import useAppointment from '../../../hooks/useAppointment';
import { ConsentWrapper, FullWidthTypography, TakenByContainer } from './styles';
import ConsentCanvas from '../../../collums-components/components/common/ConsentCanvas';
import { Typography } from '@material-ui/core';

const ConsentBuilder = ({ item, canvasRef, isSigned, setIsSigned, isCustomerSide }) => {
    const { user } = useAuth();
    const { customer } = useAppointment();

    return (
        <div style={{ width: '100%', padding: 10, position: 'relative', height: '100%' }}>
            <ConsentWrapper>
                <div className="html-content" dangerouslySetInnerHTML={{ __html: item.consent }} />
                <br />
                <TakenByContainer>
                    <Typography>{Moment().format('DD/MM/YYYY HH:mm')}</Typography>
                    {!isCustomerSide && <Typography>Consent taken by: {getName(user || {})}</Typography>}
                </TakenByContainer>
                <FullWidthTypography>
                    {getName(customer || {})},{' '}
                    {customer?.dateOfBirth && Moment(customer.dateOfBirth).format('DD/MM/YYYY')}
                </FullWidthTypography>
                <FullWidthTypography variant="h4" style={{ fontWeight: 500 }}>
                    Signature:
                </FullWidthTypography>
                {item.signatureImage && <img src={item.signatureImage} style={{ padding: 1, width: '100%' }} />}
                <ConsentCanvas canvasRef={canvasRef} isSigned={isSigned} setIsSigned={setIsSigned} />
            </ConsentWrapper>
        </div>
    );
};

export default ConsentBuilder;
