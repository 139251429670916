import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
    background: #2b78e4 !important;
    color: white !important;
    width: 100px;
    border-radius: 0px !important;
    font-size: 1.125 !important;
`;
