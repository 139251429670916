import styled from 'styled-components';

export default styled.div`
    width: 100%;
    .MuiTypography-h1 {
        font-size: 1.375rem;
    }
    .MuiTypography-h2 {
        font-size: 20px;
    }
    .MuiTypography-body1 {
        font-size: 1.125rem;
    }
    .usePlaceholder {
        font-size: 1.125rem !important;
    }
    .MuiInputBase-input {
        font-size: 1.125rem;
    }
`;
