const { WIDGET_GROUPS, MEDICAL_HISTORY_SELECTIONS } = require('../../../index');

const getForRadioTextField = ({ field, errors, requiredMessage }) => {
    const mhData = [];
    const isValidMHSection = field.group && field.group !== WIDGET_GROUPS.NONE;

    if (field.isRequired && !field.isVisible) {
        errors.push({ id: field.id, errorType: requiredMessage(field.title) });
    } else if (field.isRequired && field.isVisible === 't' && field.isSubFieldRequired && !field.value) {
        errors.push({ id: field.id, errorType: requiredMessage(field.title) });
    }
    const section = field.group;
    if (field.isVisible === 't' && isValidMHSection && (field.mhText || field.mhPositiveSelection)) {
        if (field.mhText && field.value) {
            const type = field.mhText.showAs || '';
            if (type) {
                mhData.push({
                    type,
                    label: field.value,
                    section
                });
            }
        }

        if (field.mhPositiveSelection) {
            const type = field.mhPositiveSelection.showAs || '';
            if (type) {
                mhData.push({
                    type,
                    label: field.mhPositiveSelection.customValue || field.value || '',
                    section
                });
            }
        }
    }

    if (
        isValidMHSection &&
        field.isVisible !== 't' &&
        field.mhPositiveSelection &&
        field.mhPositiveSelection.showAs === MEDICAL_HISTORY_SELECTIONS.LABEL
    ) {
        mhData.push({
            type: MEDICAL_HISTORY_SELECTIONS.LABEL,
            section,
            label: field.mhPositiveSelection.customValue || field.title,
            isRemoval: true
        });
    }
    return {
        id: field.id,
        value: field.isVisible === 't' ? field.value : undefined,
        isVisible: field.isVisible,
        mhData
    };
};

module.exports = getForRadioTextField;
