import React, { useEffect } from 'react';
import getVH from '../../../helpers/getVH';
import { CenteredText, CollumsImageArea, CollumsImage, Link, Screen } from './styles';

function EnvironmentDisabled() {
    useEffect(() => {
        getVH();
    }, []);

    return (
        <Screen>
            <div>
                <CollumsImageArea>
                    <CollumsImage src="/images/collums_logo.png" />
                </CollumsImageArea>
                <CenteredText>This feature is not available on your plan.</CenteredText>
                <CenteredText>
                    To upgrade, contact <Link href="mailto:hello@collums.co"> hello@collums.co</Link>
                </CenteredText>
            </div>
        </Screen>
    );
}

export default EnvironmentDisabled;
