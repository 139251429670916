export const getSignature = canvasRef => {
    return canvasRef.current.toDataURL();
};

export const getDefaultScrollProps = () => {
    return {
        x: window.pageXOffset,
        y: window.pageYOffset
    };
};

export const clearCanvas = (canvasRef, canvasProgressRef) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    if (canvasProgressRef?.current) {
        canvasProgressRef.current.actions = [];
    }
};

export const createPointerCircle = (contextRef, position) => {
    contextRef.current.beginPath();
    contextRef.current.arc(position.x, position.y, 1, 0, 2 * Math.PI);
    contextRef.current.fillStyle = 'black';
    contextRef.current.fill();
    contextRef.current.lineWidth = 3;
    contextRef.current.stroke();
    contextRef.current.moveTo(position.x, position.y);
};

export const addSignatureProgress = (contextRef, imgData, width, height) => {
    if (!imgData) return;
    const signatureImage = new Image();
    signatureImage.onload = () => {
        contextRef.current.drawImage(signatureImage, 0, 0, width, height);
    };
    signatureImage.src = imgData;
};
