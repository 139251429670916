/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { isBrowser, isMobile, isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';
import {
    Typography,
    withStyles,
    Button,
    Grid,
    Divider,
    IconButton,
    Card,
    CardMedia,
    makeStyles
} from '@material-ui/core';
import { CloudUploadOutlined, CameraOutlined } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import Camera from 'react-html5-camera-photo';
import { viewModalStyles } from './styles';
import 'react-html5-camera-photo/build/css/index.css';
import Modal from './Modal';
import LoadingScreen from './loadingScreen';
import { getFileType } from '../../../collums-constants/utils';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';
import { modalsButtonStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';
import styled from 'styled-components';

const UploadPhotosModal = ({ classes, visible, onClose, loading, setUploadedPhoto }) => {
    const modalButtonClasses = makeStyles(modalsButtonStyles)();
    const [isUploading, setIsUploading] = useState(isMobile);
    const [localLoading, setLocalLoading] = useState(loading);
    const [newCameraPhotos, setNewCameraPhotos] = useState([]);

    useEffect(() => {
        setLocalLoading(loading);
    }, [loading]);

    const PhotoItem = ({ photo, onDelete }) => {
        return (
            <Card
                style={{
                    position: 'relative',
                    width: '100%'
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: 4,
                        right: 4
                    }}
                >
                    <IconButton
                        onClick={() => onDelete(photo.url)}
                        color="inherit"
                        style={{ backgroundColor: '#FFF' }}
                        size="small"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <CardMedia component="img" image={photo.url} className={classes.photo} />
            </Card>
        );
    };

    PhotoItem.propTypes = {
        photo: PropTypes.object.isRequired,
        onDelete: PropTypes.func.isRequired
    };
    const onImagesUploadChanges = e => {
        const files = Array.from(e.target.files);
        files.forEach(file => {
            const setBase64 = async () => {
                const _photo = {
                    url: URL.createObjectURL(file),
                    file: file,
                    type: getFileType(file, 'png')
                };
                if (file.type.includes('image')) {
                    const result = await new Promise(resolve => {
                        let fileReader = new FileReader();
                        fileReader.onload = () => resolve(fileReader.result);
                        fileReader.readAsDataURL(file);
                    });
                    _photo.picture = result;
                }
                try {
                    await transformPhoto(_photo);
                } catch (err) {
                    toastr.error(err?.data?.message || 'Something went wrong');
                }
            };
            setBase64();
        });
    };
    const takePicture = async dataUri => {
        const nNewCameraPhotos = [...newCameraPhotos];
        const newPhoto = {
            url: dataUri,
            picture: dataUri,
            type: 'png',
            name: 'Other'
        };
        nNewCameraPhotos.push(newPhoto);
        setNewCameraPhotos(nNewCameraPhotos);
    };

    const transformPhoto = async photo => {
        const dataURLtoFile = (dataurl, filename) => {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, { type: mime });
        };
        let photoFiles = [];
        if (typeof photo !== 'object') {
            photoFiles.push({
                file: dataURLtoFile(photo, `${photo.type}`),
                type: photo.type,
                picture: photo.picture
            });
        } else if (!photo.file) {
            photoFiles.push({
                file: dataURLtoFile(photo.url, `${photo.type}`),
                type: photo.type,
                picture: photo.picture
            });
        } else {
            photoFiles.push(photo);
        }
        setUploadedPhoto(photoFiles);
        onClose();
    };

    const TakePhotoArea = () => {
        if (isBrowser) {
            return (
                <Grid item>
                    <CameraContainer>
                        <Camera
                            onTakePhoto={dataUri => {
                                takePicture(dataUri);
                            }}
                        />
                    </CameraContainer>
                    {!_.isEmpty(newCameraPhotos) && (
                        <Grid
                            container
                            spacing={2}
                            item
                            alignItems="center"
                            justify="center"
                            style={{ minHeight: 160 }}
                        >
                            {newCameraPhotos.map((photo, idx) => {
                                return (
                                    <Grid
                                        item
                                        key={idx}
                                        style={{
                                            position: 'relative',
                                            height: 150
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 10,
                                                right: 10
                                            }}
                                        >
                                            <IconButton
                                                onClick={() => {
                                                    setNewCameraPhotos(
                                                        newCameraPhotos.filter(nPhoto => nPhoto.url !== photo.url)
                                                    );
                                                }}
                                                color="inherit"
                                                style={{ backgroundColor: '#FFF' }}
                                                size="small"
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                        <img
                                            style={{ height: '100%' }}
                                            alt={photo.name}
                                            component="img"
                                            src={photo.url}
                                            className={classes.photo}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    )}
                </Grid>
            );
        }
        return (
            <Grid container alignItems="center" justify="center" item spacing={3}>
                <Grid container item xs={isMobileOnly ? 12 : 6} className={classes.grid}>
                    <Grid item>
                        <Button
                            htmlFor="photo-image-upload"
                            variant="outlined"
                            component="label"
                            startIcon={<CloudUploadOutlined />}
                        >
                            <label htmlFor="photo-image-upload">Upload</label>
                            <input
                                id="photo-image-upload"
                                type="file"
                                accept={['.jpg', '.gif', '.png', '.jpeg', '.jfif']}
                                multiple
                                hidden
                                onChange={onImagesUploadChanges}
                            />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            {localLoading && <LoadingScreen />}
            <Modal
                size="md"
                id="upload-photo"
                isOpen={visible && !localLoading}
                title=""
                hideTitle
                onCancel={onClose}
                onClose={onClose}
                customButtons={
                    isUploading && (
                        <>
                            <Button
                                onClick={() => {
                                    if (isBrowser) {
                                        setNewCameraPhotos([]);
                                        setIsUploading(false);
                                    } else {
                                        onClose();
                                    }
                                }}
                                className={modalButtonClasses.cancelButton}
                            >
                                {isBrowser ? 'Back' : 'Close'}
                            </Button>

                            <Button
                                onClick={() => {
                                    newCameraPhotos.forEach(async photo => {
                                        try {
                                            await transformPhoto(photo);
                                        } catch (err) {
                                            return;
                                        }
                                    });
                                }}
                                className={modalButtonClasses.confirmButton}
                                disabled={_.isEmpty(newCameraPhotos)}
                            >
                                Upload photos
                            </Button>
                        </>
                    )
                }
            >
                <div className={classes.root} style={{ paddingBottom: '1vh' }}>
                    <Typography className={`${classes.modalTitle} ${classes.marginBottom}`}>Upload Photo</Typography>
                    <div className={classes.contentFullWidth}>
                        {!isUploading && (
                            <>
                                <Grid container alignItems="center" justify="center">
                                    <Grid item>
                                        <Button
                                            htmlFor="photo-image-upload"
                                            variant="outlined"
                                            component="label"
                                            startIcon={<CloudUploadOutlined />}
                                        >
                                            <label htmlFor="photo-image-upload">Upload</label>
                                            <input
                                                id="photo-image-upload"
                                                type="file"
                                                accept={['.jpg', '.gif', '.png', '.jpeg', '.jfif']}
                                                multiple
                                                hidden
                                                onChange={onImagesUploadChanges}
                                            />
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Divider orientation="vertical" />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            onClick={() => {
                                                setIsUploading(true);
                                            }}
                                            startIcon={<CameraOutlined />}
                                        >
                                            Use Camera
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Typography className={classes.fileFormatLabel}>
                                    Allowed file formats: GIF, JFIF, JPEG and JPG
                                </Typography>
                            </>
                        )}

                        {isUploading && (
                            <>
                                <Grid container alignItems="center" justify="space-around">
                                    <TakePhotoArea />
                                </Grid>
                            </>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

UploadPhotosModal.propTypes = {
    classes: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    loading: PropTypes.bool
};

const CameraContainer = styled.div`
    & > div > video {
        width: 640px !important;
    }
`;
export default withStyles(viewModalStyles)(UploadPhotosModal);
