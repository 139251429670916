import { Typography, withStyles } from '@material-ui/core';
import React from 'react';
import formStyles from '../../../../../collums-constants/styles/stylesheets/formStyles';
import PropTypes from 'prop-types';
import { useForms } from '../../../../hooks/forms';

const TitleWidget = ({ classes, widgetProps }) => {
    const { isReadOnly } = useForms();
    return (
        <Typography
            component="h1"
            className={`${classes.title} ${isReadOnly ? classes.textDisabled : ''}`}
            variant="h1"
            id={widgetProps.id}
        >
            {widgetProps.title || ''}
        </Typography>
    );
};

TitleWidget.propTypes = {
    classes: PropTypes.object.isRequired,
    widgetProps: PropTypes.object.isRequired
};

export default withStyles(formStyles)(TitleWidget);
