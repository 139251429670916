export const widgetConfigStyles = {
    popoverRoot: {
        zIndex: '1300 !important'
    },
    arrowContainer: {
        display: 'flex',
        padding: 2,
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignSelf: 'none'
    },
    updateOptionContainer: {
        display: 'flex',
        padding: 2,
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    arrowIcons: {
        color: '#333',
        fontSize: 16
    },
    option: {
        display: 'flex',
        width: 600,
        alignContent: 'center'
    },
    optionArea: {
        alignSelf: 'center',
        width: '100%'
    },
    optionLabel: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontSize: 14,
        wordBreak: 'break-all'
    },
    numberInput: {
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    menuButton: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: '500',
        fontSize: 14,
        padding: '6px 14px',
        color: '#000000DE',
        borderRadius: 0
    },
    saveBtn: {
        color: 'white',
        backgroundColor: '#b41778',
        '&:hover': {
            backgroundColor: '#b41778'
        }
    },
    closeBtn: {
        backgroundColor: 'white',
        border: '1px solid #000000DE',
        marginRight: 50
    },
    separatedRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    bold: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: '500!important'
    },
    closeButton: {
        display: 'flex',
        marginLeft: 'auto',
        zIndex: 1
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: '8px 16px',
        justifyContent: 'center',
        borderTop: '1px solid #00000020'
    },
    widgetsContainer: {
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        width: '680px',
        height: '480px',
        overflow: 'auto'
    },
    divider: {
        margin: '10px 0'
    },
    textInput: {
        marginBottom: 10
    },
    optionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& > span': {
            fontSize: 14,
            fontFamily: 'Roboto, Arial, Sans-serif',
            fontWeight: 400,
            lineHeight: 1.5,
            alignSelf: 'center',
            padding: '0 16px'
        }
    },
    spacedContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    availableOptions: {
        width: '100%',
        display: 'flex',
        backgroundColor: '#f2f2f2',
        marginBottom: 5,
        justifyContent: 'space-between',
        '& > span': {
            fontSize: 14,
            fontFamily: 'Roboto, Arial, Sans-serif',
            fontWeight: 400,
            lineHeight: 1.5,
            alignSelf: 'center',
            padding: '0 16px'
        }
    },
    circleIcon: {
        color: '#3083EC'
    },
    widgetTitle: {
        fontSize: 18,
        fontWeight: 500,
        marginBottom: 16
    },
    titleSpan: {
        fontSize: '18px',
        textAlign: 'center',
        position: 'absolute',
        top: '12px',
        justifyContent: 'center',
        display: 'flex',
        width: '100%',
        zIndex: '0',
        left: '0'
    },
    actionOption: {
        height: 36,
        width: 36,
        margin: 4
    },
    autocomplete: {
        marginLeft: 10,
        width: '200px !important'
    },
    groupSelector: {
        display: 'flex'
    },
    medicalHistoryText: {
        margin: '15px 0'
    },
    multilineInput: {
        width: 'calc(100% - 10px)',
        '& > div > textarea': {
            lineHeight: '1.1'
        },
        '& .MuiOutlinedInput-root': {
            height: 'auto'
        }
    }
};
