import React from 'react';
import AppointmentContext from '../contexts/AppointmentContext';

function useAppointment() {
    const appt = React.useContext(AppointmentContext);

    return {
        ...appt
    };
}

export default useAppointment;
