import React from 'react';
import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import widgetList from './widgetList';
import { v4 } from 'uuid';
import { useForms } from '../../../hooks/forms';
import { MenuStyles } from './styles';
import PropTypes from 'prop-types';
import { WIDGET_GROUPS } from '../../../../collums-constants';

const Menu = ({ classes }) => {
    const { data, setData } = useForms();

    const RenderListItem = item => {
        return (
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <item.icon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={item.title} secondary={item.info} />
                <IconButton
                    onClick={() => {
                        const newField = {
                            ...item.startValue,
                            type: item.type,
                            id: v4(),
                            isRequired: false,
                            group: WIDGET_GROUPS.NONE
                        };
                        setData({ ...data, fields: [...data.fields, newField] });
                    }}
                >
                    <AddIcon />
                </IconButton>
            </ListItem>
        );
    };

    return (
        <div className={classes.menuBar}>
            <List>{widgetList.map(RenderListItem)}</List>
        </div>
    );
};

Menu.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(MenuStyles)(Menu);
