import React from 'react';
import { FormProvider } from '../../../collums-components/hooks/forms';
import FormContainer from '../../molecules/FormContainer';
import Form from '../../../collums-components/components/formBuilder';

const FormBuilder = ({ item, formRef, setIsInvalidForm }) => {
    return (
        <FormContainer id="form-wrapper">
            <FormProvider
                value={{
                    isJourney: true,
                    defaultData: item,
                    onDataChange: data => {
                        formRef.current = data;
                    },
                    onErrorChange: isInvalidForm => {
                        setIsInvalidForm(isInvalidForm);
                    }
                }}
            >
                <Form />
            </FormProvider>
        </FormContainer>
    );
};

export default FormBuilder;
