const { WIDGET_GROUPS, MEDICAL_HISTORY_SELECTIONS } = require('../../../index');

const getForRadioList = ({ field, errors, requiredMessage }) => {
    const mhData = [];
    const isValidMHSection = field.group && field.group !== WIDGET_GROUPS.NONE;
    if (field.isRequired && !field.isVisible) {
        errors.push({ id: field.id, errorType: requiredMessage(field.title) });
    }
    if (field.isVisible === 't') {
        if (!(field.value || []).length) {
            errors.push({
                id: field.id,
                errorType: `Need to add at least 1 option to field ${field.title}`
            });
        }
        if ((field.value || []).some(answer => typeof answer.value !== 'string')) {
            errors.push({ id: field.id, errorType: `Invalid option on field ${field.title}` });
        }
    }
    const section = field.group;
    if (
        field.isVisible !== 't' &&
        isValidMHSection &&
        field.mhPositiveSelection &&
        field.mhPositiveSelection.showAs === MEDICAL_HISTORY_SELECTIONS.LABEL
    ) {
        mhData.push({
            type: MEDICAL_HISTORY_SELECTIONS.LABEL,
            section,
            label: field.mhPositiveSelection.customValue || field.title,
            isRemoval: true
        });
    }
    if (
        field.isVisible === 't' &&
        field.value &&
        isValidMHSection &&
        (field.mhText || field.mhSelection || field.mhPositiveSelection)
    ) {
        if (field.mhPositiveSelection) {
            const type = field.mhPositiveSelection.showAs || '';
            mhData.push({
                type,
                section,
                label: field.mhPositiveSelection.customValue || field.title
            });
        }
        (field.value || []).forEach(answer => {
            if (field.mhText) {
                const type = field.mhText.showAs || '';
                if (type && answer.info) {
                    mhData.push({
                        type,
                        label: answer.info,
                        section
                    });
                }
            }
            if (field.mhSelection && answer.value) {
                const type = field.mhSelection.showAs || '';
                if (type) {
                    mhData.push({
                        type,
                        label: field.mhSelection.customValue || answer.value,
                        section
                    });
                }
            }
        });
    }
    return {
        id: field.id,
        value: field.isVisible ? field.value : undefined,
        isVisible: field.isVisible,
        mhData
    };
};

module.exports = getForRadioList;
