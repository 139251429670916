import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
    Table,
    TableBody,
    TableCell as TableCellMUI,
    TableHead,
    TableRow,
    TextField,
    makeStyles,
    Checkbox,
    FormControlLabel,
    FormControl
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import formStyles from '../../../collums-constants/styles/stylesheets/formStyles';
import PhoneNumberInput from 'material-ui-phone-number';
import { DEFAULT_PHONE_COUNTRY, PREFERRED_PHONE_COUNTRIES } from '../../../collums-constants';
import customerProperties from '../../../constants/customerProperties';
import marketingOptions from '../../../constants/marketingOptions';
import OrganisationApi from '../../../collums-components/api/organizationApi';
import { getOrganisationCountryCode } from '../../../collums-components/helpers';

function CustomerFields({
    customer,
    setNewCustomerData,
    newCustomerData,
    countryOptions,
    setCountryCode,
    invalidData
}) {
    const formClasses = makeStyles(formStyles)();
    const [customerForm, setCustomerForm] = useState(newCustomerData);
    const phoneInputRef = useRef();
    const [inputCountryCode, setInputCountryCode] = useState(null);

    useEffect(() => {
        setNewCustomerData(customerForm);
        // eslint-disable-next-line
    }, [customerForm]);

    useEffect(() => {
        if (customer) {
            const updateStatement = {};
            if (customer.email) updateStatement.email = customer.email;
            if (customer.mobilePhone) {
                const inputValue = (() => {
                    const formattedPhone =
                        phoneInputRef.current.formatNumber(customer.mobilePhone) || customer.mobilePhone;
                    return formattedPhone.lastIndexOf('+') > 0 ? formattedPhone.replace('+', '') : formattedPhone;
                })();
                updateStatement.mobilePhone = inputValue;
                const countryCode = phoneInputRef.current.getCountryData()?.countryCode;
                if (countryCode) {
                    setCountryCode(countryCode);
                }
            }
            if (Object.keys(updateStatement).length) {
                setCustomerForm(updateStatement);
            }
        }
        //eslint-disable-next-line
    }, [customer]);

    const onChangeOffer = offerType => {
        const marketingTypes = [...(customerForm.marketingTypes || customer.marketingTypes)];
        if (marketingTypes.includes(offerType)) {
            const newMarketingTypes = marketingTypes.filter(el => el !== offerType);
            setCustomerForm({
                ...customerForm,
                marketingTypes: newMarketingTypes
            });
            return;
        } else {
            marketingTypes.push(offerType);
        }
        setCustomerForm({
            ...customerForm,
            marketingTypes
        });
    };

    useEffect(() => {
        const getOrganisation = async () => {
            return await OrganisationApi.getPublicOrg();
        };

        if (!inputCountryCode) {
            getOrganisation().then(org => {
                setInputCountryCode(
                    getOrganisationCountryCode({
                        ...org,
                        address: {
                            country: org?.country || undefined
                        }
                    })
                );
            });
        }
    }, [inputCountryCode]);

    return (
        <Container>
            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {customerProperties.map(prop => {
                            const Input = (() => {
                                if (prop.key === 'dateOfBirth') {
                                    const customerDob = (() => {
                                        if (customerForm.dateOfBirth) {
                                            return customerForm.dateOfBirth;
                                        }
                                        return customer.dateOfBirth || null;
                                    })();
                                    return (
                                        <DatePicker
                                            value={customerDob}
                                            onChange={dateOfBirth => {
                                                setCustomerForm({
                                                    ...customerForm,
                                                    dateOfBirth
                                                });
                                            }}
                                            TextFieldComponent={props => (
                                                <TextField
                                                    {...props}
                                                    className={formClasses.disabled}
                                                    variant="outlined"
                                                />
                                            )}
                                            label=""
                                            maxDate={Date(`${new Date().getFullYear()}-1-1`)}
                                            format="DD/MM/YYYY"
                                            disabled={prop.disabled}
                                        />
                                    );
                                } else if (prop.key === 'country') {
                                    return (
                                        <Autocomplete
                                            id="customer-country"
                                            options={countryOptions}
                                            defaultValue={customer.country}
                                            value={customerForm.country}
                                            getOptionLabel={option => option.label}
                                            className={formClasses.autocomplete}
                                            onChange={(_, country) => {
                                                setCustomerForm({
                                                    ...customerForm,
                                                    country
                                                });
                                            }}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    className={formClasses.autocompleteTextField}
                                                    label=""
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    );
                                } else if (prop.key === 'marketingTypes') {
                                    const marketingTypes = [
                                        ...(customerForm.marketingTypes || customer.marketingTypes)
                                    ];
                                    const includingEmail = marketingTypes.includes(marketingOptions.email);
                                    const includingSMS = marketingTypes.includes(marketingOptions.sms);

                                    return (
                                        <FormControl>
                                            <OfferCheckboxesContainer>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={includingEmail}
                                                            onChange={() => onChangeOffer(marketingOptions.email)}
                                                            name="Email"
                                                        />
                                                    }
                                                    label="Email"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={includingSMS}
                                                            onChange={() => onChangeOffer(marketingOptions.sms)}
                                                            name="SMS"
                                                        />
                                                    }
                                                    label="SMS"
                                                />
                                            </OfferCheckboxesContainer>
                                        </FormControl>
                                    );
                                } else if (prop.key === 'mobilePhone') {
                                    return (
                                        <PhoneNumberInput
                                            ref={phoneInputRef}
                                            variant="outlined"
                                            value={customerForm[prop.key]}
                                            onChange={(value, phoneInfo) => {
                                                setCustomerForm({
                                                    ...customerForm,
                                                    [prop.key]: value
                                                });
                                                setCountryCode(phoneInfo.countryCode);
                                            }}
                                            preferredCountries={PREFERRED_PHONE_COUNTRIES}
                                            defaultCountry={
                                                !customerForm[prop.key]
                                                    ? inputCountryCode || DEFAULT_PHONE_COUNTRY
                                                    : null
                                            }
                                            error={invalidData?.phone}
                                        />
                                    );
                                }
                                return (
                                    <TextField
                                        defaultValue={customer[prop.key]}
                                        value={customerForm[prop.key]}
                                        disabled={prop.disabled}
                                        onChange={({ target }) => {
                                            setCustomerForm({
                                                ...customerForm,
                                                [prop.key]: target.value
                                            });
                                        }}
                                        className={formClasses.disabled}
                                        variant="outlined"
                                        error={invalidData?.email && prop.key === 'email'}
                                    />
                                );
                            })();
                            return (
                                <TableRow key={prop.key}>
                                    <TableCell>{prop.label}</TableCell>
                                    <TableCell>{Input}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
        </Container>
    );
}
// Styled:
// Container width is 100% and centers children;
// The table width is 50% for width >= 768, when not, the width is unset and the flex is 1
// TextField width is 100%
const Container = styled.div`
    width: 100%;
    font-size: 20px !important;
    & > div {
        display: flex;
        align-items: flex-start !important;
        justify-content: center;

        & > table {
            width: 50%;
            @media (max-width: 768px) {
                width: unset;
                flex: 1;
            }
            & td > div {
                width: 100%;
            }
        }
    }
`;
const TableCell = styled(TableCellMUI)`
    font-size: 1.125rem !important;
    & > div > div {
        font-size: 1.125rem !important;
    }
    & > div > div > label > span {
        font-size: 1.125rem !important;
    }
`;
const OfferCheckboxesContainer = styled.div`
    display: flex;
`;

export default CustomerFields;
