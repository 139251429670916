export const JourneyButtonStyles = {
    div: {
        display: 'flex',
        direction: 'row',
        justifyContent: 'space-evenly',
        height: '100%',
        alignItems: 'center'
    },
    button: {
        fontSize: '20px',
        minWidth: 150,
        lineHeight: 'inherit',
        padding: '4px 12px!important',
        boxSizing: 'content-box'
    }
};
