import Api from './config';

class PractitionerApi {
    static query() {
        return Api.request({
            method: 'GET',
            url: '/practitioners?limit=50'
        });
    }

    static listActivePractitioners() {
        return Api.request({
            method: 'GET',
            url: '/practitioners/cc-list-actives'
        });
    }

    static listPractitionerClinics(practitioner) {
        return Api.request({
            method: 'GET',
            url: `/practitioners/${practitioner}/clinics/`
        });
    }

    static listAllPractitioners(showOnlyDisplayName = false) {
        return Api.request({
            method: 'GET',
            url: `/practitioners/all?showOnlyDisplayName=${showOnlyDisplayName}`
        });
    }

    static listPinLoginPractitioners() {
        return Api.request({
            method: 'GET',
            url: '/practitioners/list-pin-logins'
        });
    }
}

export default PractitionerApi;
