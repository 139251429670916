import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import steps from '../constants/steps';
import NavigationContext from '../contexts/NavigationContext';
import useQs from '../hooks/useQs';

const useNavigation = () => {
    const navigation = useContext(NavigationContext);
    const qs = useQs();
    const history = useHistory();
    const apptId = qs.get('appointmentId');
    const [stepInfo, setStepInfo] = useState();

    useEffect(() => {
        setStepInfo(steps.find(step => step.step === navigation.activeStep));
    }, [navigation.activeStep]);

    const push = (path, queryParams) => {
        history.push(`${path}?appointmentId=${apptId}${queryParams ? `&${queryParams}` : ''}`);
    };

    const goBack = () => {
        history.goBack();
    };

    const removeParams = () => {
        history.replace({
            search: `?appointmentId=${apptId}`
        });
    };

    return { ...navigation, stepInfo, push, goBack, removeParams, qs };
};

export default useNavigation;
