import styled from 'styled-components';

export const Canvas = styled.canvas`
    margin: 5px;
    width: 100%;
    outline: none;
    user-select: none;
    touch-action: none;
    -moz-user-select: none;
    border: 1px solid black;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
`;

export const CanvasContainer = styled.div`
    width: 100%;
`;
