import React, { useEffect, useState } from 'react';
import { JourneyButtonStyles as styles } from './styles';
import { Button, makeStyles, withStyles } from '@material-ui/core';
import { modalsButtonStyles } from '../../../collums-constants/styles/stylesheets/buttonsStyles';
import OrganisationApi from '../../../collums-components/api/organizationApi';

function JourneyButtons({
    classes,
    backAction,
    continueAction,
    continueText = 'Save & Continue',
    backText = 'Back',
    disableContinueAction,
    showSkipButton = true
}) {
    
    const [organisation, setOrganisation] = useState(null);

    useEffect(() => {
        const getOrganisation = async () => {
            return await OrganisationApi.getOrg();
        };

        getOrganisation().then(org => {
            setOrganisation(org);
        });
    }, []);
    
    const globalStyles = makeStyles(modalsButtonStyles)();

    return (
        <div className={classes.div}>
            <Button
                style={{ fontSize: '20px' }}
                className={`${globalStyles.cancelButton} ${classes.button}`}
                onClick={backAction}
            >
                {backText}
            </Button>
            {showSkipButton && organisation?.allowSkippingFormsInJourney &&
                <Button
                    id="skipActionButton"
                    style={{ fontSize: '20px' }}
                    className={`${globalStyles.confirmButton} ${classes.button}`}
                    onClick={continueAction}
                >
                    Skip
                </Button>
            }
            <Button
                id="continueActionButton"
                style={{ fontSize: '20px' }}
                className={`${globalStyles.confirmButton} ${classes.button} ${
                    disableContinueAction ? 'Mui-disabled' : ''
                }`}
                onClick={continueAction}
            >
                {continueText}
            </Button>
        </div>
    );
}

export default withStyles(styles)(JourneyButtons);
