import Axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'https://staging.api.collums.co.uk';

const defaultParams = {
    baseURL: backendUrl
};

class Api {
    constructor() {
        this.params = defaultParams;
        this.config({ headers: { Authorization: localStorage.getItem('token') } });
    }

    config(params) {
        this.params = { ...this.params, ...params };
    }
    resetToken(newToken) {
        this.config({ headers: { Authorization: newToken } });
    }

    updateToken() {
        try {
            const updatedToken = localStorage.getItem('token');
            if (
                updatedToken &&
                (!this.params.headers.Authorization || this.params.headers.Authorization !== updatedToken)
            ) {
                this.resetToken(updatedToken);
            }
        } catch (err) {
            console.error(err);
        }
    }

    async request(params) {
        try {
            this.updateToken();
            const response = await Axios({ ...this.params, ...params });
            return response.data;
        } catch (error) {
            if (Axios.isCancel(error)) {
                throw error;
            }
            throw error.response;
        }
    }
}

export default new Api();
