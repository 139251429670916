/* eslint-disable comma-dangle */
const steps = [
    {
        id: 'home',
        step: 0,
        label: 'Home'
    },
    {
        id: 'client-details',
        step: 1,
        label: 'Check Your Details'
    },
    {
        id: 'forms',
        label: 'Forms',
        step: 2
    },
    {
        id: 'form-complete',
        label: 'Form Complete',
        step: 3
    },
    {
        id: 'summary',
        label: 'Summary',
        step: 4
    },
    {
        id: 'practitioner-forms',
        label: 'Practitioner Forms',
        step: 5
    },
    {
        id: 'photos',
        label: 'Photos',
        step: 6
    }
];

export default steps;
