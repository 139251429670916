export const FormBuilderStyles = () => ({
    render: {
        backgroundColor: '#fafafa',
        padding: 10,
        width: '100%'
    },
    paper: {
        height: 1024,
        overflow: 'auto',
        backgroundColor: '#fafafa',
        display: 'flex',
        width: '100%'
    },
    form: {
        width: 'calc(100% - 300px)',
        minWidth: 500,
        marginLeft: '0 50px',
        overflowX: 'auto'
    },
    menu: {
        minWidth: 300
    }
});
