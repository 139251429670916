import styled from 'styled-components';
import TypographyMUI from '@material-ui/core/Typography';

export const CenteredText = styled(TypographyMUI)`
    font-family: Roboto, Arial, 'sans-serif';
    font-size: 18px;
    text-align: center;
    margin-top: 0px;
`;

export const Link = styled.a`
    font-size: 18px;
    color: black;
    text-decoration: none;
`;

export const CollumsImageArea = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
`;

export const CollumsImage = styled.img`
    height: 300px;
`;

export const Screen = styled.div`
    background-color: rgb(246, 245, 246);
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    justify-content: center;
    align-items: center;
`;
