const commonDrugsList = [
    'ABACAVIR',
    'ABACAVIR WITH DOLUTEGRAVIR AND LAMIVUDINE',
    'ABACAVIR WITH LAMIVUDINE',
    'ABACAVIR WITH LAMIVUDINE AND ZIDOVUDINE',
    'ABATACEPT',
    'ABEMACICLIB',
    'ABIRATERONE ACETATE',
    'ACALABRUTINIB',
    'ACAMPROSATE CALCIUM',
    'ACARBOSE',
    'ACEBUTOLOL',
    'ACECLOFENAC',
    'ACENOCOUMAROL',
    'ACETAZOLAMIDE',
    'ACETYLCHOLINE CHLORIDE',
    'ACETYLCYSTEINE',
    'ACICLOVIR',
    'ACIPIMOX',
    'ACITRETIN',
    'ACLIDINIUM BROMIDE',
    'ACLIDINIUM BROMIDE WITH FORMOTEROL',
    'ACRIVASTINE',
    'ADALIMUMAB',
    'ADAPALENE',
    'ADAPALENE WITH BENZOYL PEROXIDE',
    'ADEFOVIR DIPIVOXIL',
    'ADENOSINE',
    'ADRENALINE WITH ARTICAINE HYDROCHLORIDE',
    'ADRENALINE/EPINEPHRINE',
    'AFATINIB',
    'AFLIBERCEPT',
    'AGALSIDASE ALFA',
    'AGALSIDASE BETA',
    'AGOMELATINE',
    'ALBENDAZOLE',
    'ALBUMIN SOLUTION',
    'ALCLOMETASONE DIPROPIONATE',
    'ALCOHOL',
    'ALDESLEUKIN',
    'ALECTINIB',
    'ALEMTUZUMAB',
    'ALENDRONIC ACID',
    'ALENDRONIC ACID WITH COLECALCIFEROL',
    'ALFACALCIDOL',
    'ALFENTANIL',
    'ALFUZOSIN HYDROCHLORIDE',
    'ALGINIC ACID',
    'ALGLUCOSIDASE ALFA',
    'ALIMEMAZINE TARTRATE',
    'ALIROCUMAB',
    'ALISKIREN',
    'ALITRETINOIN',
    'ALLOPURINOL',
    'ALMOND OIL',
    'ALMOTRIPTAN',
    'ALOGLIPTIN',
    'ALOGLIPTIN WITH METFORMIN',
    'ALPELISIB',
    'ALPHA TOCOPHEROL',
    'ALPHA TOCOPHERYL ACETATE',
    'ALPRAZOLAM',
    'ALPROSTADIL',
    'ALTEPLASE',
    'ALUMINIUM ACETATE',
    'ALUMINIUM CHLORIDE HEXAHYDRATE',
    'ALVERINE CITRATE',
    'AMANTADINE HYDROCHLORIDE',
    'AMBRISENTAN',
    'AMIFAMPRIDINE',
    'AMIKACIN',
    'AMILORIDE HYDROCHLORIDE',
    'AMILORIDE WITH BUMETANIDE',
    'AMINOPHYLLINE',
    'AMINOSALICYLIC ACID',
    'AMIODARONE HYDROCHLORIDE',
    'AMISULPRIDE',
    'AMITRIPTYLINE HYDROCHLORIDE',
    'AMLODIPINE',
    'AMLODIPINE WITH VALSARTAN',
    'AMOROLFINE',
    'AMOXICILLIN',
    'AMPHOTERICIN B',
    'AMPICILLIN',
    'AMSACRINE',
    'ANAGRELIDE',
    'ANAKINRA',
    'ANASTROZOLE',
    'ANDEXANET ALFA',
    'ANETHOL WITH BORNEOL, CAMPHENE, CINEOLE, FENCHONE AND PINENE ',
    'ANIDULAFUNGIN',
    'ANTAZOLINE WITH XYLOMETAZOLINE',
    'ANTHRAX VACCINE',
    'ANTI-D (RH<sub>0</sub>) IMMUNOGLOBULIN',
    'ANTITHYMOCYTE IMMUNOGLOBULIN (RABBIT)',
    'APALUTAMIDE',
    'APIXABAN',
    'APOMORPHINE HYDROCHLORIDE',
    'APRACLONIDINE',
    'APREMILAST',
    'APREPITANT',
    'ARACHIS OIL',
    'ARGATROBAN MONOHYDRATE',
    'ARIPIPRAZOLE',
    'ARSENIC TRIOXIDE',
    'ARTEMETHER WITH LUMEFANTRINE',
    'ARTENIMOL WITH PIPERAQUINE PHOSPHATE',
    'ASCORBIC ACID',
    'ASENAPINE',
    'ASFOTASE ALFA',
    'ASPARAGINASE',
    'ASPIRIN',
    'ASPIRIN WITH CODEINE',
    'ASPIRIN WITH METOCLOPRAMIDE',
    'ATALUREN',
    'ATAZANAVIR',
    'ATAZANAVIR WITH COBICISTAT',
    'ATENOLOL',
    'ATENOLOL WITH NIFEDIPINE',
    'ATEZOLIZUMAB',
    'ATOMOXETINE',
    'ATORVASTATIN',
    'ATOSIBAN',
    'ATOVAQUONE',
    'ATOVAQUONE WITH PROGUANIL HYDROCHLORIDE',
    'ATRACURIUM BESILATE',
    'ATROPINE SULFATE',
    'AVANAFIL',
    'AVAPRITINIB',
    'AVATROMBOPAG',
    'AVELUMAB',
    'AVIPTADIL WITH PHENTOLAMINE MESILATE',
    'AXITINIB',
    'AZACITIDINE',
    'AZATHIOPRINE',
    'AZELAIC ACID',
    'AZELASTINE HYDROCHLORIDE',
    'AZILSARTAN MEDOXOMIL',
    'AZITHROMYCIN',
    'AZTREONAM',
    'BACILLUS CALMETTE-GUÉRIN',
    'BACILLUS CALMETTE-GUÉRIN VACCINE',
    'BACLOFEN',
    'BALSALAZIDE SODIUM',
    'BAMBUTEROL HYDROCHLORIDE',
    'BARICITINIB',
    'BARRIER CREAMS AND OINTMENTS',
    'BASILIXIMAB',
    'BECLOMETASONE DIPROPIONATE',
    'BECLOMETASONE WITH FORMOTEROL',
    'BECLOMETASONE WITH FORMOTEROL AND GLYCOPYRRONIUM',
    'BEDAQUILINE',
    'BEE VENOM EXTRACT',
    'BELATACEPT',
    'BELIMUMAB',
    'BEMPEDOIC ACID',
    'BEMPEDOIC ACID WITH EZETIMIBE',
    'BENDAMUSTINE HYDROCHLORIDE',
    'BENDROFLUMETHIAZIDE',
    'BENPERIDOL',
    'BENRALIZUMAB',
    'BENZALKONIUM CHLORIDE',
    'BENZATHINE BENZYLPENICILLIN',
    'BENZOIC ACID WITH SALICYLIC ACID',
    'BENZOIN TINCTURE, COMPOUND',
    'BENZOYL PEROXIDE',
    'BENZOYL PEROXIDE WITH CLINDAMYCIN',
    'BENZYDAMINE HYDROCHLORIDE',
    'BENZYL BENZOATE',
    'BENZYL BENZOATE WITH BISMUTH OXIDE, BISMUTH SUBGALLATE, HYDROCORTISONE ACETATE, PERU BALSAM AND ZINC OXIDE ',
    'BENZYLPENICILLIN SODIUM',
    'BETAHISTINE DIHYDROCHLORIDE',
    'BETAINE',
    'BETAMETHASONE',
    'BETAMETHASONE WITH CLIOQUINOL',
    'BETAMETHASONE WITH CLOTRIMAZOLE',
    'BETAMETHASONE WITH FUSIDIC ACID',
    'BETAMETHASONE WITH NEOMYCIN',
    'BETAMETHASONE WITH SALICYLIC ACID',
    'BETAXOLOL',
    'BETHANECHOL CHLORIDE',
    'BEVACIZUMAB',
    'BEXAROTENE',
    'BEZAFIBRATE',
    'BEZLOTOXUMAB',
    'BICALUTAMIDE',
    'BICTEGRAVIR WITH EMTRICITABINE AND TENOFOVIR ALAFENAMIDE',
    'BILASTINE',
    'BIMATOPROST',
    'BIMATOPROST WITH TIMOLOL',
    'BINIMETINIB',
    'BIPHASIC INSULIN ASPART',
    'BIPHASIC INSULIN LISPRO',
    'BIPHASIC ISOPHANE INSULIN',
    'BISACODYL',
    'BISMUTH SUBSALICYLATE',
    'BISOPROLOL FUMARATE',
    'BIVALIRUDIN',
    'BLEOMYCIN',
    'BLINATUMOMAB',
    'BORNEOL WITH CAMPHENE, CINEOLE, MENTHOL, MENTHONE AND PINENE',
    'BORTEZOMIB',
    'BOSENTAN',
    'BOSUTINIB',
    'BOTULINUM TOXIN TYPE A',
    'BOTULINUM TOXIN TYPE B',
    'BOTULISM ANTITOXIN',
    'BRENTUXIMAB VEDOTIN',
    'BRIGATINIB',
    'BRIMONIDINE TARTRATE',
    'BRIMONIDINE WITH TIMOLOL',
    'BRINZOLAMIDE',
    'BRINZOLAMIDE WITH BRIMONIDINE',
    'BRINZOLAMIDE WITH TIMOLOL',
    'BRIVARACETAM',
    'BRODALUMAB',
    'BROLUCIZUMAB',
    'BROMFENAC',
    'BROMOCRIPTINE',
    'BUDESONIDE',
    'BUDESONIDE WITH FORMOTEROL',
    'BUMETANIDE',
    'BUPIVACAINE HYDROCHLORIDE',
    'BUPIVACAINE WITH ADRENALINE ',
    'BUPIVACAINE WITH FENTANYL',
    'BUPRENORPHINE',
    'BUPRENORPHINE WITH NALOXONE',
    'BUPROPION HYDROCHLORIDE',
    'BUSERELIN',
    'BUSPIRONE HYDROCHLORIDE',
    'BUSULFAN',
    'C1-ESTERASE INHIBITOR',
    'CABAZITAXEL',
    'CABERGOLINE',
    'CABOZANTINIB',
    'CALAMINE WITH ZINC OXIDE',
    'CALCIPOTRIOL',
    'CALCIPOTRIOL WITH BETAMETHASONE',
    'CALCITONIN (SALMON)',
    'CALCITRIOL',
    'CALCIUM ACETATE',
    'CALCIUM ACETATE WITH MAGNESIUM CARBONATE',
    'CALCIUM CARBONATE',
    'CALCIUM CARBONATE WITH CALCIUM LACTATE GLUCONATE ',
    'CALCIUM CHLORIDE',
    'CALCIUM GLUCONATE',
    'CALCIUM LACTATE',
    'CALCIUM PHOSPHATE',
    'CALCIUM POLYSTYRENE SULFONATE',
    'CAMELLIA SINENSIS',
    'CANAGLIFLOZIN',
    'CANAGLIFLOZIN WITH METFORMIN',
    'CANAKINUMAB',
    'CANDESARTAN CILEXETIL',
    'CANGRELOR',
    'CANNABIDIOL',
    'CANNABIS EXTRACT',
    'CAPECITABINE',
    'CAPLACIZUMAB',
    'CAPREOMYCIN',
    'CAPSAICIN',
    'CAPTOPRIL',
    'CARBAMAZEPINE',
    'CARBETOCIN',
    'CARBIMAZOLE',
    'CARBOCISTEINE',
    'CARBOMERS',
    'CARBOPLATIN',
    'CARBOPROST',
    'CARFILZOMIB',
    'CARGLUMIC ACID',
    'CARIPRAZINE',
    'CARMELLOSE SODIUM',
    'CARMUSTINE',
    'CARVEDILOL',
    'CASPOFUNGIN',
    'CASTOR OIL WITH COLLODION AND COLOPHONY',
    'CEFACLOR',
    'CEFADROXIL',
    'CEFALEXIN',
    'CEFAZOLIN',
    'CEFEPIME',
    'CEFIDEROCOL',
    'CEFIXIME',
    'CEFOTAXIME',
    'CEFOXITIN',
    'CEFRADINE',
    'CEFTAROLINE FOSAMIL',
    'CEFTAZIDIME',
    'CEFTAZIDIME WITH AVIBACTAM',
    'CEFTOBIPROLE',
    'CEFTOLOZANE WITH TAZOBACTAM',
    'CEFTRIAXONE',
    'CEFUROXIME',
    'CELECOXIB',
    'CELIPROLOL HYDROCHLORIDE',
    'CEMIPLIMAB',
    'CERITINIB',
    'CERTOLIZUMAB PEGOL',
    'CETIRIZINE HYDROCHLORIDE',
    'CETRIMIDE WITH UNDECENOIC ACID',
    'CETRORELIX',
    'CETUXIMAB',
    'CHARCOAL, ACTIVATED',
    'CHENODEOXYCHOLIC ACID',
    'CHLORAL HYDRATE',
    'CHLORAMBUCIL',
    'CHLORAMPHENICOL',
    'CHLORDIAZEPOXIDE HYDROCHLORIDE',
    'CHLORHEXIDINE',
    'CHLORHEXIDINE GLUCONATE WITH ISOPROPYL ALCOHOL',
    'CHLORHEXIDINE WITH CETRIMIDE',
    'CHLORHEXIDINE WITH LIDOCAINE',
    'CHLORHEXIDINE WITH NEOMYCIN ',
    'CHLORHEXIDINE WITH NYSTATIN ',
    'CHLORMETHINE',
    'CHLOROPROCAINE HYDROCHLORIDE',
    'CHLOROQUINE',
    'CHLOROQUINE WITH PROGUANIL',
    'CHLORPHENAMINE MALEATE',
    'CHLORPROMAZINE HYDROCHLORIDE',
    'CHLORTALIDONE',
    'CHOLERA VACCINE',
    'CHOLIC ACID',
    'CHOLINE SALICYLATE',
    'CHORIOGONADOTROPIN ALFA',
    'CICLESONIDE',
    'CICLOSPORIN',
    'CIDOFOVIR',
    'CILOSTAZOL',
    'CIMETIDINE',
    'CINACALCET',
    'CINCHOCAINE HYDROCHLORIDE WITH FLUOCORTOLONE CAPROATE AND FLUOCORTOLONE PIVALATE ',
    'CINCHOCAINE WITH HYDROCORTISONE',
    'CINCHOCAINE WITH PREDNISOLONE',
    'CINNARIZINE',
    'CINNARIZINE WITH DIMENHYDRINATE',
    'CIPROFIBRATE',
    'CIPROFLOXACIN',
    'CIPROFLOXACIN WITH DEXAMETHASONE',
    'CIPROFLOXACIN WITH FLUOCINOLONE ACETONIDE',
    'CISATRACURIUM',
    'CISPLATIN',
    'CITALOPRAM',
    'CITRIC ACID',
    'CITRIC ACID WITH MAGNESIUM CARBONATE',
    'CITRIC ACID WITH POTASSIUM CITRATE ',
    'CLADRIBINE',
    'CLARITHROMYCIN',
    'CLEMASTINE',
    'CLINDAMYCIN',
    'CLOBAZAM',
    'CLOBETASOL PROPIONATE',
    'CLOBETASOL PROPIONATE WITH NEOMYCIN SULFATE AND NYSTATIN ',
    'CLOBETASONE BUTYRATE',
    'CLOBETASONE BUTYRATE WITH NYSTATIN AND OXYTETRACYCLINE ',
    'CLOFARABINE',
    'CLOFAZIMINE',
    'CLOMETHIAZOLE',
    'CLOMIFENE CITRATE',
    'CLOMIPRAMINE HYDROCHLORIDE',
    'CLONAZEPAM',
    'CLONIDINE HYDROCHLORIDE',
    'CLOPIDOGREL',
    'CLOTRIMAZOLE',
    'CLOZAPINE',
    'CO-AMILOFRUSE',
    'CO-AMILOZIDE',
    'CO-AMOXICLAV',
    'CO-BENELDOPA',
    'CO-CARELDOPA',
    'CO-CODAMOL',
    'CO-CYPRINDIOL',
    'CO-DANTHRAMER',
    'CO-DANTHRUSATE',
    'CO-FLUAMPICIL',
    'CO-FLUMACTONE',
    'CO-MAGALDROX',
    'CO-PHENOTROPE',
    'CO-SIMALCITE',
    'CO-TENIDONE',
    'CO-TRIAMTERZIDE',
    'CO-TRIMOXAZOLE',
    'COAL TAR',
    'COAL TAR WITH CALAMINE',
    'COAL TAR WITH COCONUT OIL AND SALICYLIC ACID',
    'COAL TAR WITH DITHRANOL AND SALICYLIC ACID ',
    'COAL TAR WITH SALICYLIC ACID',
    'COAL TAR WITH SALICYLIC ACID AND PRECIPITATED SULFUR',
    'COAL TAR WITH ZINC OXIDE',
    'COBICISTAT',
    'COBIMETINIB',
    'CODEINE PHOSPHATE',
    'COLCHICINE',
    'COLECALCIFEROL',
    'COLECALCIFEROL WITH CALCIUM CARBONATE',
    'COLECALCIFEROL WITH CALCIUM PHOSPHATE',
    'COLESEVELAM HYDROCHLORIDE',
    'COLESTIPOL HYDROCHLORIDE',
    'COLESTYRAMINE',
    'COLISTIMETHATE SODIUM',
    'CONESTAT ALFA',
    'CONJUGATED OESTROGENS (EQUINE)',
    'CONJUGATED OESTROGENS WITH MEDROXYPROGESTERONE',
    'COVID-19 VACCINE',
    'CRANBERRY',
    'CRISANTASPASE',
    'CRIZOTINIB',
    'CROTAMITON',
    'CYANOCOBALAMIN',
    'CYCLIZINE',
    'CYCLOPENTOLATE HYDROCHLORIDE',
    'CYCLOPHOSPHAMIDE',
    'CYCLOSERINE',
    'CYPROHEPTADINE HYDROCHLORIDE',
    'CYPROTERONE ACETATE',
    'CYTARABINE',
    'CYTOMEGALOVIRUS IMMUNOGLOBULIN',
    'DABIGATRAN ETEXILATE',
    'DABRAFENIB',
    'DACARBAZINE',
    'DACOMITINIB',
    'DAIRY PRODUCTS',
    'DALBAVANCIN ',
    'DALTEPARIN SODIUM',
    'DANAPAROID SODIUM',
    'DANTROLENE SODIUM',
    'DAPAGLIFLOZIN',
    'DAPAGLIFLOZIN WITH METFORMIN',
    'DAPOXETINE',
    'DAPSONE',
    'DAPTOMYCIN',
    'DARATUMUMAB',
    'DARBEPOETIN ALFA',
    'DARIFENACIN',
    'DAROLUTAMIDE',
    'DARUNAVIR',
    'DARUNAVIR WITH COBICISTAT',
    'DARUNAVIR WITH COBICISTAT, EMTRICITABINE AND TENOFOVIR ALAFENAMIDE',
    'DASATINIB',
    'DAUNORUBICIN',
    'DAUNORUBICIN WITH CYTARABINE',
    'DECITABINE',
    'DEFERASIROX',
    'DEFERIPRONE',
    'DEFLAZACORT',
    'DEGARELIX',
    'DELAFLOXACIN',
    'DELAMANID',
    'DEMECLOCYCLINE HYDROCHLORIDE',
    'DENOSUMAB',
    'DEQUALINIUM CHLORIDE',
    'DESFERRIOXAMINE MESILATE',
    'DESFLURANE',
    'DESLORATADINE',
    'DESMOPRESSIN',
    'DESOGESTREL',
    'DEXAMETHASONE',
    'DEXAMETHASONE WITH FRAMYCETIN SULFATE AND GRAMICIDIN ',
    'DEXAMETHASONE WITH GLACIAL ACETIC ACID AND NEOMYCIN SULFATE ',
    'DEXAMETHASONE WITH HYPROMELLOSE, NEOMYCIN AND POLYMYXIN B SULFATE',
    'DEXAMETHASONE WITH TOBRAMYCIN',
    'DEXAMFETAMINE SULFATE',
    'DEXKETOPROFEN',
    'DEXMEDETOMIDINE',
    'DEXRAZOXANE',
    'DIAMORPHINE HYDROCHLORIDE',
    'DIAZEPAM',
    'DIAZOXIDE',
    'DICLOFENAC',
    'DICLOFENAC POTASSIUM',
    'DICLOFENAC SODIUM',
    'DICLOFENAC SODIUM WITH MISOPROSTOL',
    'DICOBALT EDETATE',
    'DICYCLOVERINE HYDROCHLORIDE',
    'DICYCLOVERINE HYDROCHLORIDE WITH ALUMINIUM HYDROXIDE, MAGNESIUM OXIDE AND SIMETICONE',
    'DIENOGEST',
    'DIENOGEST WITH ESTRADIOL VALERATE',
    'DIETHYL PHTHALATE WITH METHYL SALICYLATE',
    'DIETHYLCARBAMAZINE',
    'DIETHYLSTILBESTROL',
    'DIFLUCORTOLONE VALERATE',
    'DIGOXIN',
    'DIGOXIN-SPECIFIC ANTIBODY',
    'DIHYDROCODEINE TARTRATE',
    'DIHYDROCODEINE WITH PARACETAMOL',
    'DILTIAZEM HYDROCHLORIDE',
    'DIMETHYL FUMARATE',
    'DIMETICONE',
    'DINOPROSTONE',
    'DINUTUXIMAB BETA',
    'DIPHTHERIA ANTITOXIN',
    'DIPHTHERIA WITH TETANUS AND POLIOMYELITIS VACCINE',
    'DIPHTHERIA WITH TETANUS, PERTUSSIS AND POLIOMYELITIS VACCINE ',
    'DIPHTHERIA WITH TETANUS, PERTUSSIS, HEPATITIS B, POLIOMYELITIS AND HAEMOPHILUS INFLUENZAE TYPE B VACCINE',
    'DIPHTHERIA WITH TETANUS, PERTUSSIS, POLIOMYELITIS AND HAEMOPHILUS INFLUENZAE TYPE B VACCINE',
    'DIPIPANONE HYDROCHLORIDE WITH CYCLIZINE',
    'DIPYRIDAMOLE',
    'DIPYRIDAMOLE WITH ASPIRIN',
    'DISODIUM HYDROGEN CITRATE WITH GLUCOSE, POTASSIUM CHLORIDE AND SODIUM CHLORIDE',
    'DISOPYRAMIDE',
    'DISULFIRAM',
    'DITHRANOL',
    'DITHRANOL WITH SALICYLIC ACID AND ZINC OXIDE',
    'DOBUTAMINE',
    'DOCETAXEL',
    'DOCUSATE SODIUM',
    'DOLUTEGRAVIR',
    'DOLUTEGRAVIR WITH RILPIVIRINE',
    'DOMPERIDONE',
    'DONEPEZIL HYDROCHLORIDE',
    'DOPAMINE HYDROCHLORIDE',
    'DORAVIRINE',
    'DORNASE ALFA',
    'DORZOLAMIDE',
    'DORZOLAMIDE WITH TIMOLOL',
    'DOSULEPIN HYDROCHLORIDE',
    'DOXAPRAM HYDROCHLORIDE',
    'DOXAZOSIN',
    'DOXEPIN',
    'DOXORUBICIN HYDROCHLORIDE',
    'DOXYCYCLINE',
    'DOXYLAMINE WITH PYRIDOXINE',
    'DRIED PROTHROMBIN COMPLEX',
    'DRONEDARONE',
    'DROPERIDOL',
    'DULAGLUTIDE',
    'DULOXETINE',
    'DUPILUMAB',
    'DURVALUMAB',
    'DUTASTERIDE',
    'ECONAZOLE NITRATE',
    'ECULIZUMAB',
    'EDOXABAN',
    'EFAVIRENZ',
    'EFAVIRENZ WITH EMTRICITABINE AND TENOFOVIR DISOPROXIL',
    'EFLORNITHINE',
    'ELBASVIR WITH GRAZOPREVIR',
    'ELETRIPTAN',
    'ELIGLUSTAT',
    'ELOSULFASE ALFA',
    'ELOTUZUMAB',
    'ELTROMBOPAG',
    'ELVITEGRAVIR',
    'ELVITEGRAVIR WITH COBICISTAT, EMTRICITABINE AND TENOFOVIR ALAFENAMIDE',
    'ELVITEGRAVIR WITH COBICISTAT, EMTRICITABINE AND TENOFOVIR DISOPROXIL',
    'EMICIZUMAB',
    'EMOLLIENT BATH AND SHOWER PRODUCTS, ANTIMICROBIAL-CONTAINING',
    'EMOLLIENT BATH AND SHOWER PRODUCTS, PARAFFIN-CONTAINING',
    'EMOLLIENT BATH AND SHOWER PRODUCTS, SOYA-BEAN OIL-CONTAINING',
    'EMOLLIENT BATH AND SHOWER PRODUCTS, TAR-CONTAINING',
    'EMOLLIENT CREAMS AND OINTMENTS, ANTIMICROBIAL-CONTAINING',
    'EMOLLIENT CREAMS AND OINTMENTS, COLLOIDAL OATMEAL-CONTAINING',
    'EMOLLIENT CREAMS AND OINTMENTS, PARAFFIN-CONTAINING',
    'EMOLLIENTS, UREA-CONTAINING',
    'EMPAGLIFLOZIN',
    'EMPAGLIFLOZIN WITH LINAGLIPTIN',
    'EMPAGLIFLOZIN WITH METFORMIN',
    'EMTRICITABINE',
    'EMTRICITABINE WITH RILPIVIRINE AND TENOFOVIR ALAFENAMIDE',
    'EMTRICITABINE WITH RILPIVIRINE AND TENOFOVIR DISOPROXIL',
    'EMTRICITABINE WITH TENOFOVIR ALAFENAMIDE',
    'EMTRICITABINE WITH TENOFOVIR DISOPROXIL',
    'ENALAPRIL MALEATE',
    'ENALAPRIL WITH HYDROCHLOROTHIAZIDE',
    'ENCORAFENIB',
    'ENFUVIRTIDE',
    'ENOXAPARIN SODIUM',
    'ENOXIMONE',
    'ENTACAPONE',
    'ENTECAVIR',
    'ENTERAL FEEDS',
    'ENTRECTINIB',
    'ENZALUTAMIDE',
    'EPHEDRINE HYDROCHLORIDE',
    'EPINASTINE HYDROCHLORIDE',
    'EPIRUBICIN HYDROCHLORIDE',
    'EPLERENONE',
    'EPOETIN ALFA',
    'EPOETIN BETA',
    'EPOETIN ZETA',
    'EPOPROSTENOL',
    'EPROSARTAN',
    'EPTIFIBATIDE',
    'ERDOSTEINE',
    'ERENUMAB',
    'ERGOCALCIFEROL',
    'ERGOCALCIFEROL WITH CALCIUM LACTATE AND CALCIUM PHOSPHATE',
    'ERGOMETRINE MALEATE',
    'ERGOMETRINE WITH OXYTOCIN',
    'ERGOTAMINE TARTRATE',
    'ERGOTAMINE TARTRATE WITH CAFFEINE HYDRATE AND CYCLIZINE HYDROCHLORIDE ',
    'ERIBULIN',
    'ERLOTINIB',
    'ERTAPENEM',
    'ERTUGLIFLOZIN',
    'ERYTHROMYCIN',
    'ERYTHROMYCIN WITH ZINC ACETATE',
    'ESCITALOPRAM',
    'ESKETAMINE',
    'ESLICARBAZEPINE ACETATE',
    'ESMOLOL HYDROCHLORIDE',
    'ESOMEPRAZOLE',
    'ESTRADIOL',
    'ESTRADIOL WITH DYDROGESTERONE',
    'ESTRADIOL WITH LEVONORGESTREL',
    'ESTRADIOL WITH MEDROXYPROGESTERONE',
    'ESTRADIOL WITH NOMEGESTROL',
    'ESTRADIOL WITH NORETHISTERONE',
    'ESTRAMUSTINE PHOSPHATE',
    'ESTRIOL',
    'ETANERCEPT',
    'ETELCALCETIDE',
    'ETHAMBUTOL HYDROCHLORIDE',
    'ETHINYLESTRADIOL',
    'ETHINYLESTRADIOL WITH DESOGESTREL',
    'ETHINYLESTRADIOL WITH DROSPIRENONE',
    'ETHINYLESTRADIOL WITH ETONOGESTREL',
    'ETHINYLESTRADIOL WITH GESTODENE',
    'ETHINYLESTRADIOL WITH LEVONORGESTREL',
    'ETHINYLESTRADIOL WITH NORELGESTROMIN',
    'ETHINYLESTRADIOL WITH NORETHISTERONE',
    'ETHINYLESTRADIOL WITH NORGESTIMATE',
    'ETHOSUXIMIDE',
    'ETODOLAC',
    'ETOMIDATE',
    'ETONOGESTREL',
    'ETOPOSIDE',
    'ETORICOXIB',
    'ETRAVIRINE',
    'EUCALYPTUS WITH MENTHOL ',
    'EUROPEAN VIPER SNAKE VENOM ANTISERUM',
    'EVEROLIMUS',
    'EVOLOCUMAB',
    'EXEMESTANE',
    'EXENATIDE',
    'EZETIMIBE',
    'FACTOR IX FRACTION, DRIED',
    'FACTOR VIIA (RECOMBINANT)',
    'FACTOR VIII FRACTION, DRIED',
    'FACTOR VIII INHIBITOR BYPASSING FRACTION',
    'FACTOR XIII FRACTION, DRIED',
    'FAMCICLOVIR',
    'FAMOTIDINE',
    'FAMPRIDINE',
    'FEBUXOSTAT',
    'FELBINAC',
    'FELODIPINE',
    'FENOFIBRATE',
    'FENTANYL',
    'FERRIC CARBOXYMALTOSE',
    'FERRIC DERISOMALTOSE',
    'FERRIC MALTOL',
    'FERROUS FUMARATE',
    'FERROUS FUMARATE WITH FOLIC ACID',
    'FERROUS GLUCONATE',
    'FERROUS SULFATE',
    'FERROUS SULFATE WITH ASCORBIC ACID',
    'FERROUS SULFATE WITH FOLIC ACID',
    'FESOTERODINE FUMARATE',
    'FEXOFENADINE HYDROCHLORIDE',
    'FIBRINOGEN, DRIED',
    'FIDAXOMICIN',
    'FILGOTINIB',
    'FILGRASTIM',
    'FINASTERIDE',
    'FINGOLIMOD',
    'FLAVOXATE HYDROCHLORIDE',
    'FLECAINIDE ACETATE',
    'FLUCLOXACILLIN',
    'FLUCONAZOLE',
    'FLUCYTOSINE',
    'FLUDARABINE PHOSPHATE',
    'FLUDROCORTISONE ACETATE',
    'FLUDROXYCORTIDE',
    'FLUMAZENIL',
    'FLUMETASONE PIVALATE WITH CLIOQUINOL',
    'FLUOCINOLONE ACETONIDE',
    'FLUOCINOLONE ACETONIDE WITH CLIOQUINOL',
    'FLUOCINOLONE ACETONIDE WITH NEOMYCIN',
    'FLUOCINONIDE',
    'FLUORESCEIN SODIUM',
    'FLUORESCEIN WITH LIDOCAINE ',
    'FLUOROMETHOLONE',
    'FLUOROURACIL',
    'FLUOROURACIL WITH SALICYLIC ACID',
    'FLUOXETINE',
    'FLUPENTIXOL',
    'FLUPENTIXOL DECANOATE',
    'FLUPHENAZINE DECANOATE',
    'FLURAZEPAM',
    'FLURBIPROFEN',
    'FLUTAMIDE',
    'FLUTICASONE',
    'FLUTICASONE WITH AZELASTINE',
    'FLUTICASONE WITH FORMOTEROL',
    'FLUTICASONE WITH SALMETEROL',
    'FLUTICASONE WITH UMECLIDINIUM AND VILANTEROL',
    'FLUTICASONE WITH VILANTEROL',
    'FLUVASTATIN',
    'FLUVOXAMINE MALEATE',
    'FOLIC ACID',
    'FOLINIC ACID',
    'FOLLITROPIN ALFA',
    'FOLLITROPIN ALFA WITH LUTROPIN ALFA',
    'FOLLITROPIN DELTA',
    'FONDAPARINUX SODIUM',
    'FORMALDEHYDE',
    'FORMOTEROL FUMARATE',
    'FORMOTEROL FUMARATE WITH GLYCOPYRRONIUM AND BUDESONIDE',
    'FOSAMPRENAVIR',
    'FOSAPREPITANT',
    'FOSCARNET SODIUM',
    'FOSFOMYCIN',
    'FOSINOPRIL SODIUM',
    'FOSPHENYTOIN SODIUM',
    'FOSTAMATINIB',
    'FRAMYCETIN SULFATE',
    'FREMANEZUMAB',
    'FRESH FROZEN PLASMA',
    'FROVATRIPTAN',
    'FULVESTRANT',
    'FUROSEMIDE',
    'FUROSEMIDE WITH TRIAMTERENE',
    'FUSIDIC ACID',
    'GABAPENTIN',
    'GALANTAMINE',
    'GALCANEZUMAB',
    'GALSULFASE',
    'GANCICLOVIR',
    'GANIRELIX',
    'GEFITINIB',
    'GELATIN',
    'GEMCITABINE',
    'GEMEPROST',
    'GEMFIBROZIL',
    'GEMTUZUMAB OZOGAMICIN',
    'GENTAMICIN',
    'GENTAMICIN WITH HYDROCORTISONE ',
    'GILTERITINIB',
    'GLASDEGIB',
    'GLATIRAMER ACETATE',
    'GLECAPREVIR WITH PIBRENTASVIR',
    'GLICLAZIDE',
    'GLIMEPIRIDE',
    'GLIPIZIDE',
    'GLUCAGON',
    'GLUCOSAMINE',
    'GLUCOSE',
    'GLUTARALDEHYDE',
    'GLYCEROL',
    'GLYCEROL PHENYLBUTYRATE',
    'GLYCEROL WITH MAGNESIUM SULFATE AND PHENOL',
    'GLYCERYL TRINITRATE',
    'GLYCINE',
    'GLYCOPYRRONIUM BROMIDE',
    'GLYCOPYRRONIUM WITH FORMOTEROL FUMARATE',
    'GLYCOPYRRONIUM WITH INDACATEROL',
    'GOLIMUMAB',
    'GONADORELIN',
    'GOSERELIN',
    'GRANISETRON',
    'GRAPEFRUIT',
    'GRASS POLLEN EXTRACT',
    'GRISEOFULVIN',
    'GUANFACINE',
    'GUSELKUMAB',
    'HAEM ARGINATE',
    'HAEMOPHILUS INFLUENZAE TYPE B WITH MENINGOCOCCAL GROUP C VACCINE',
    'HALOPERIDOL',
    'HALOPERIDOL DECANOATE',
    'HEPARIN (UNFRACTIONATED)',
    'HEPARINOID',
    'HEPATITIS A AND B VACCINE',
    'HEPATITIS A VACCINE',
    'HEPATITIS A WITH TYPHOID VACCINE',
    'HEPATITIS B IMMUNOGLOBULIN',
    'HEPATITIS B VACCINE',
    'HEXETIDINE',
    'HOMATROPINE HYDROBROMIDE',
    'HUMAN ALPHA1-PROTEINASE INHIBITOR',
    'HUMAN PAPILLOMAVIRUS VACCINES',
    'HYALURONIDASE',
    'HYDRALAZINE HYDROCHLORIDE',
    'HYDROCHLOROTHIAZIDE',
    'HYDROCORTISONE',
    'HYDROCORTISONE BUTYRATE',
    'HYDROCORTISONE WITH BENZALKONIUM CHLORIDE, DIMETICONE AND NYSTATIN',
    'HYDROCORTISONE WITH CHLORHEXIDINE HYDROCHLORIDE AND NYSTATIN ',
    'HYDROCORTISONE WITH CLOTRIMAZOLE',
    'HYDROCORTISONE WITH FUSIDIC ACID',
    'HYDROCORTISONE WITH LIDOCAINE ',
    'HYDROCORTISONE WITH MICONAZOLE',
    'HYDROCORTISONE WITH NEOMYCIN AND POLYMYXIN B SULFATE',
    'HYDROCORTISONE WITH OXYTETRACYCLINE ',
    'HYDROGEN PEROXIDE',
    'HYDROMORPHONE HYDROCHLORIDE',
    'HYDROXOCOBALAMIN',
    'HYDROXYCARBAMIDE',
    'HYDROXYCHLOROQUINE SULFATE',
    'HYDROXYETHYLCELLULOSE',
    'HYDROXYPROPYL GUAR WITH POLYETHYLENE GLYCOL AND PROPYLENE GLYCOL',
    'HYDROXYZINE HYDROCHLORIDE',
    'HYOSCINE BUTYLBROMIDE',
    'HYOSCINE HYDROBROMIDE',
    'HYPROMELLOSE',
    'HYPROMELLOSE WITH DEXTRAN 70',
    'IBANDRONIC ACID',
    'IBRUTINIB',
    'IBUPROFEN',
    'ICATIBANT',
    'ICHTHAMMOL',
    'ICHTHAMMOL WITH ZINC OXIDE',
    'IDARUBICIN HYDROCHLORIDE',
    'IDARUCIZUMAB',
    'IDEBENONE',
    'IDELALISIB',
    'IDURSULFASE',
    'IFOSFAMIDE',
    'ILOPROST',
    'IMATINIB',
    'IMIDAPRIL HYDROCHLORIDE',
    'IMIGLUCERASE',
    'IMIPENEM WITH CILASTATIN',
    'IMIPENEM WITH CILASTATIN AND RELEBACTAM',
    'IMIPRAMINE HYDROCHLORIDE',
    'IMIQUIMOD',
    'INDACATEROL',
    'INDACATEROL WITH MOMETASONE FUROATE',
    'INDAPAMIDE',
    'INDOMETACIN',
    'INDORAMIN',
    'INFLIXIMAB',
    'INFLUENZA VACCINE',
    'INGENOL MEBUTATE',
    'INOSINE PRANOBEX',
    'INOSITOL NICOTINATE',
    'INOTERSEN',
    'INOTUZUMAB OZOGAMICIN',
    'INSULIN',
    'INSULIN ASPART',
    'INSULIN DEGLUDEC',
    'INSULIN DEGLUDEC WITH LIRAGLUTIDE',
    'INSULIN DETEMIR',
    'INSULIN GLARGINE',
    'INSULIN GLARGINE WITH LIXISENATIDE',
    'INSULIN GLULISINE',
    'INSULIN LISPRO',
    'INTERFERON BETA',
    'INTERFERON GAMMA-1B',
    'INTRA-UTERINE CONTRACEPTIVE DEVICES (COPPER)',
    'IODIDE WITH IODINE',
    'IPILIMUMAB',
    'IPRATROPIUM BROMIDE',
    'IPRATROPIUM WITH SALBUTAMOL ',
    'IRBESARTAN',
    'IRBESARTAN WITH HYDROCHLOROTHIAZIDE',
    'IRINOTECAN HYDROCHLORIDE',
    'IRON DEXTRAN',
    'IRON SUCROSE',
    'ISATUXIMAB',
    'ISAVUCONAZOLE',
    'ISOCARBOXAZID',
    'ISOFLURANE',
    'ISONIAZID',
    'ISOPHANE INSULIN',
    'ISOSORBIDE DINITRATE',
    'ISOSORBIDE MONONITRATE',
    'ISOTRETINOIN',
    'ISPAGHULA HUSK',
    'ITRACONAZOLE',
    'IVABRADINE',
    'IVACAFTOR',
    'IVERMECTIN',
    'IXAZOMIB',
    'IXEKIZUMAB',
    'JAPANESE ENCEPHALITIS VACCINE',
    'KAOLIN WITH MORPHINE',
    'KETAMINE',
    'KETOCONAZOLE',
    'KETOPROFEN',
    'KETOROLAC TROMETAMOL',
    'KETOTIFEN',
    'LABETALOL HYDROCHLORIDE',
    'LACIDIPINE',
    'LACOSAMIDE',
    'LACTIC ACID',
    'LACTULOSE',
    'LAMIVUDINE',
    'LAMIVUDINE WITH DOLUTEGRAVIR',
    'LAMIVUDINE WITH TENOFOVIR DISOPROXIL',
    'LAMIVUDINE WITH TENOFOVIR DISOPROXIL AND DORAVIRINE',
    'LAMOTRIGINE',
    'LANADELUMAB',
    'LANREOTIDE',
    'LANSOPRAZOLE',
    'LANTHANUM',
    'LAPATINIB',
    'LARONIDASE',
    'LAROTRECTINIB',
    'LATANOPROST',
    'LATANOPROST WITH TIMOLOL',
    'LEDIPASVIR WITH SOFOSBUVIR',
    'LEFLUNOMIDE',
    'LENALIDOMIDE',
    'LENOGRASTIM',
    'LENVATINIB',
    'LERCANIDIPINE HYDROCHLORIDE',
    'LETERMOVIR',
    'LETROZOLE',
    'LEUPRORELIN ACETATE',
    'LEVAMISOLE',
    'LEVETIRACETAM',
    'LEVOBUNOLOL HYDROCHLORIDE',
    'LEVOBUPIVACAINE',
    'LEVOCARNITINE',
    'LEVOCETIRIZINE HYDROCHLORIDE',
    'LEVODOPA WITH CARBIDOPA AND ENTACAPONE',
    'LEVOFLOXACIN',
    'LEVOFOLINIC ACID',
    'LEVOMENTHOL',
    'LEVOMEPROMAZINE',
    'LEVONORGESTREL',
    'LEVOTHYROXINE SODIUM',
    'LIDOCAINE HYDROCHLORIDE',
    'LIDOCAINE WITH ADRENALINE',
    'LIDOCAINE WITH CETRIMIDE',
    'LIDOCAINE WITH PHENYLEPHRINE',
    'LIDOCAINE WITH PRILOCAINE',
    'LINACLOTIDE',
    'LINAGLIPTIN',
    'LINAGLIPTIN WITH METFORMIN',
    'LINEZOLID',
    'LIOTHYRONINE SODIUM',
    'LIPEGFILGRASTIM',
    'LIQUID PARAFFIN',
    'LIQUID PARAFFIN WITH WHITE SOFT PARAFFIN AND WOOL ALCOHOLS ',
    'LIRAGLUTIDE',
    'LISDEXAMFETAMINE MESILATE',
    'LISINOPRIL',
    'LISINOPRIL WITH HYDROCHLOROTHIAZIDE',
    'LITHIUM CARBONATE',
    'LITHIUM CITRATE',
    'LIXISENATIDE',
    'LODOXAMIDE',
    'LOFEPRAMINE',
    'LOFEXIDINE HYDROCHLORIDE',
    'LOMITAPIDE',
    'LOMUSTINE',
    'LOPERAMIDE HYDROCHLORIDE',
    'LOPERAMIDE WITH SIMETICONE',
    'LOPINAVIR WITH RITONAVIR',
    'LOPRAZOLAM',
    'LORATADINE',
    'LORAZEPAM',
    'LORLATINIB',
    'LORMETAZEPAM',
    'LOSARTAN POTASSIUM',
    'LOSARTAN WITH HYDROCHLOROTHIAZIDE',
    'LOTEPREDNOL ETABONATE',
    'LOXAPINE',
    'LUMACAFTOR WITH IVACAFTOR',
    'LURASIDONE HYDROCHLORIDE',
    'LUSUTROMBOPAG',
    'LUTROPIN ALFA',
    'LYMECYCLINE',
    'MACITENTAN',
    'MACROGOL 3350',
    'MACROGOL 3350 WITH ANHYDROUS SODIUM SULFATE, ASCORBIC ACID, POTASSIUM CHLORIDE, SODIUM ASCORBATE AND SODIUM CHLORIDE',
    'MACROGOL 3350 WITH ANHYDROUS SODIUM SULFATE, POTASSIUM CHLORIDE, SODIUM BICARBONATE AND SODIUM CHLORIDE ',
    'MACROGOL 3350 WITH POTASSIUM CHLORIDE, SODIUM BICARBONATE AND SODIUM CHLORIDE',
    'MAGNESIUM ASPARTATE',
    'MAGNESIUM CARBONATE',
    'MAGNESIUM CITRATE WITH SODIUM PICOSULFATE',
    'MAGNESIUM GLYCEROPHOSPHATE',
    'MAGNESIUM HYDROXIDE',
    'MAGNESIUM SULFATE',
    'MAGNESIUM TRISILICATE',
    'MAGNESIUM TRISILICATE WITH MAGNESIUM CARBONATE AND SODIUM BICARBONATE',
    'MALATHION',
    'MANNITOL',
    'MARAVIROC',
    'MEASLES, MUMPS AND RUBELLA VACCINE, LIVE',
    'MEBENDAZOLE',
    'MEBEVERINE HYDROCHLORIDE',
    'MEBEVERINE WITH ISPAGHULA HUSK',
    'MEDROXYPROGESTERONE ACETATE',
    'MEFENAMIC ACID',
    'MEFLOQUINE',
    'MEGESTROL ACETATE',
    'MELATONIN',
    'MELOXICAM',
    'MELPHALAN',
    'MEMANTINE HYDROCHLORIDE',
    'MENADIOL SODIUM PHOSPHATE',
    'MENINGOCOCCAL GROUP B VACCINE (RDNA, COMPONENT, ADSORBED)',
    'MENINGOCOCCAL GROUP C VACCINE',
    'MENINGOCOCCAL GROUPS A WITH C AND W135 AND Y VACCINE',
    'MENOTROPHIN',
    'MEPACRINE HYDROCHLORIDE',
    'MEPIVACAINE HYDROCHLORIDE',
    'MEPIVACAINE WITH ADRENALINE ',
    'MEPOLIZUMAB',
    'MEPTAZINOL',
    'MERCAPTAMINE',
    'MERCAPTOPURINE',
    'MEROPENEM',
    'MEROPENEM WITH VABORBACTAM',
    'MESALAZINE',
    'MESNA',
    'METARAMINOL',
    'METFORMIN HYDROCHLORIDE',
    'METHADONE HYDROCHLORIDE',
    'METHENAMINE HIPPURATE',
    'METHOCARBAMOL',
    'METHOTREXATE',
    'METHOXY POLYETHYLENE GLYCOL-EPOETIN BETA',
    'METHOXYFLURANE',
    'METHYLDOPA',
    'METHYLNALTREXONE BROMIDE',
    'METHYLPHENIDATE HYDROCHLORIDE',
    'METHYLPREDNISOLONE',
    'METHYLPREDNISOLONE WITH LIDOCAINE',
    'METHYLTHIONINIUM CHLORIDE',
    'METOCLOPRAMIDE HYDROCHLORIDE',
    'METOLAZONE',
    'METOPROLOL TARTRATE',
    'METRELEPTIN',
    'METRONIDAZOLE',
    'METYRAPONE',
    'MEXILETINE',
    'MIANSERIN HYDROCHLORIDE',
    'MICAFUNGIN',
    'MICONAZOLE',
    'MIDAZOLAM',
    'MIDODRINE HYDROCHLORIDE',
    'MIDOSTAURIN',
    'MIFAMURTIDE',
    'MIFEPRISTONE',
    'MIFEPRISTONE AND MISOPROSTOL',
    'MIGALASTAT',
    'MIGLUSTAT',
    'MILRINONE',
    'MINOCYCLINE',
    'MINOXIDIL',
    'MIRABEGRON',
    'MIRTAZAPINE',
    'MISOPROSTOL',
    'MITOMYCIN',
    'MITOTANE',
    'MITOXANTRONE',
    'MIVACURIUM',
    'MIZOLASTINE',
    'MOCLOBEMIDE',
    'MODAFINIL',
    'MOGAMULIZUMAB',
    'MOMETASONE FUROATE',
    'MOMETASONE FUROATE WITH GLYCOPYRRONIUM BROMIDE AND INDACATEROL',
    'MONTELUKAST',
    'MORPHINE',
    'MORPHINE WITH CYCLIZINE',
    'MOXIFLOXACIN',
    'MOXISYLYTE',
    'MOXONIDINE',
    'MUPIROCIN',
    'MYCOPHENOLATE MOFETIL',
    'NABILONE',
    'NABUMETONE',
    'NADOLOL',
    'NAFARELIN',
    'NAFTIDROFURYL OXALATE',
    'NALDEMEDINE',
    'NALMEFENE',
    'NALOXEGOL',
    'NALOXONE HYDROCHLORIDE',
    'NALTREXONE HYDROCHLORIDE',
    'NALTREXONE WITH BUPROPION',
    'NANDROLONE',
    'NAPROXEN',
    'NAPROXEN WITH ESOMEPRAZOLE',
    'NARATRIPTAN',
    'NATALIZUMAB',
    'NEBIVOLOL',
    'NEFOPAM HYDROCHLORIDE',
    'NELARABINE',
    'NEOMYCIN SULFATE',
    'NEOSTIGMINE',
    'NEOSTIGMINE WITH GLYCOPYRRONIUM BROMIDE',
    'NEPAFENAC',
    'NERATINIB',
    'NEVIRAPINE',
    'NICARDIPINE HYDROCHLORIDE',
    'NICORANDIL',
    'NICOTINAMIDE',
    'NICOTINE',
    'NICOTINIC ACID',
    'NIFEDIPINE',
    'NILOTINIB',
    'NIMODIPINE',
    'NINTEDANIB',
    'NIRAPARIB',
    'NITISINONE',
    'NITRAZEPAM',
    'NITROFURANTOIN',
    'NITROUS OXIDE',
    'NIVOLUMAB',
    'NIZATIDINE',
    'NONOXINOL',
    'NORADRENALINE/NOREPINEPHRINE',
    'NORETHISTERONE',
    'NORETHISTERONE WITH MESTRANOL',
    'NORMAL IMMUNOGLOBULIN',
    'NORTRIPTYLINE',
    'NUSINERSEN',
    'NYSTATIN',
    'OBETICHOLIC ACID',
    'OBINUTUZUMAB',
    'OCRELIZUMAB',
    'OCRIPLASMIN',
    'OCTREOTIDE',
    'OFLOXACIN',
    'OLANZAPINE',
    'OLANZAPINE EMBONATE',
    'OLAPARIB',
    'OLIVE OIL',
    'OLMESARTAN MEDOXOMIL',
    'OLMESARTAN WITH AMLODIPINE',
    'OLMESARTAN WITH AMLODIPINE AND HYDROCHLOROTHIAZIDE',
    'OLMESARTAN WITH HYDROCHLOROTHIAZIDE',
    'OLODATEROL',
    'OLOPATADINE',
    'OLSALAZINE SODIUM',
    'OMALIZUMAB',
    'OMEGA-3-ACID ETHYL ESTERS',
    'OMEPRAZOLE',
    'ONDANSETRON',
    'OPICAPONE',
    'ORLISTAT',
    'ORPHENADRINE HYDROCHLORIDE',
    'OSELTAMIVIR',
    'OSIMERTINIB',
    'OSPEMIFENE',
    'OXALIPLATIN',
    'OXAZEPAM',
    'OXCARBAZEPINE',
    'OXERUTINS',
    'OXYBUPROCAINE HYDROCHLORIDE',
    'OXYBUTYNIN HYDROCHLORIDE',
    'OXYCODONE HYDROCHLORIDE',
    'OXYCODONE WITH NALOXONE',
    'OXYMETHOLONE',
    'OXYTETRACYCLINE',
    'OXYTOCIN',
    'OZANIMOD',
    'PACLITAXEL',
    'PALBOCICLIB',
    'PALIPERIDONE',
    'PALIVIZUMAB',
    'PALONOSETRON',
    'PALONOSETRON WITH NETUPITANT',
    'PAMIDRONATE DISODIUM',
    'PANCREATIN',
    'PANCURONIUM BROMIDE',
    'PANITUMUMAB',
    'PANOBINOSTAT',
    'PANTOPRAZOLE',
    'PARACETAMOL',
    'PARACETAMOL WITH BUCLIZINE HYDROCHLORIDE AND CODEINE PHOSPHATE',
    'PARACETAMOL WITH ISOMETHEPTENE',
    'PARAFFIN, YELLOW, SOFT',
    'PARATHYROID HORMONE',
    'PARECOXIB',
    'PARENTERAL NUTRITION SUPPLEMENTS',
    'PARICALCITOL',
    'PAROXETINE',
    'PASIREOTIDE',
    'PATIROMER CALCIUM',
    'PATISIRAN',
    'PAZOPANIB',
    'PEGASPARGASE',
    'PEGFILGRASTIM',
    'PEGINTERFERON ALFA',
    'PEGINTERFERON BETA-1A',
    'PEGVISOMANT',
    'PEMBROLIZUMAB',
    'PEMETREXED',
    'PENICILLAMINE',
    'PENTAMIDINE ISETIONATE',
    'PENTAZOCINE',
    'PENTOSAN POLYSULFATE SODIUM',
    'PENTOSTATIN',
    'PENTOXIFYLLINE',
    'PEPPERMINT OIL',
    'PERAMPANEL',
    'PERICYAZINE',
    'PERINDOPRIL ARGININE',
    'PERINDOPRIL ARGININE WITH INDAPAMIDE',
    'PERINDOPRIL ERBUMINE',
    'PERMETHRIN',
    'PERTUZUMAB',
    'PERTUZUMAB WITH TRASTUZUMAB',
    'PETHIDINE HYDROCHLORIDE',
    'PHENAZONE WITH LIDOCAINE',
    'PHENELZINE',
    'PHENINDIONE',
    'PHENOBARBITAL',
    'PHENOL',
    'PHENOXYBENZAMINE HYDROCHLORIDE',
    'PHENOXYMETHYLPENICILLIN',
    'PHENYLEPHRINE HYDROCHLORIDE',
    'PHENYLEPHRINE WITH TROPICAMIDE',
    'PHENYTOIN',
    'PHOLCODINE',
    'PHOSPHATE',
    'PHYTOMENADIONE',
    'PILOCARPINE',
    'PIMECROLIMUS',
    'PIMOZIDE',
    'PINDOLOL',
    'PIOGLITAZONE',
    'PIOGLITAZONE WITH METFORMIN',
    'PIPERACILLIN WITH TAZOBACTAM',
    'PIRACETAM',
    'PIRFENIDONE',
    'PIROXICAM',
    'PITOLISANT',
    'PIVMECILLINAM HYDROCHLORIDE',
    'PIXANTRONE',
    'PIZOTIFEN',
    'PLERIXAFOR',
    'PNEUMOCOCCAL POLYSACCHARIDE CONJUGATE VACCINE (ADSORBED)',
    'PNEUMOCOCCAL POLYSACCHARIDE VACCINE',
    'PODOPHYLLOTOXIN',
    'POLATUZUMAB VEDOTIN',
    'POLYVINYL ALCOHOL',
    'POMALIDOMIDE',
    'PONATINIB',
    'PORFIMER SODIUM',
    'POSACONAZOLE',
    'POTASSIUM AMINOBENZOATE',
    'POTASSIUM BICARBONATE WITH POTASSIUM ACID TARTRATE ',
    'POTASSIUM CHLORIDE',
    'POTASSIUM CHLORIDE WITH CALCIUM CHLORIDE DIHYDRATE AND SODIUM CHLORIDE',
    'POTASSIUM CHLORIDE WITH CALCIUM CHLORIDE, SODIUM CHLORIDE AND SODIUM LACTATE',
    'POTASSIUM CHLORIDE WITH GLUCOSE',
    'POTASSIUM CHLORIDE WITH GLUCOSE AND SODIUM CHLORIDE',
    'POTASSIUM CHLORIDE WITH POTASSIUM BICARBONATE',
    'POTASSIUM CHLORIDE WITH RICE POWDER, SODIUM CHLORIDE AND SODIUM CITRATE ',
    'POTASSIUM CHLORIDE WITH SODIUM CHLORIDE',
    'POTASSIUM PERMANGANATE',
    'POVIDONE-IODINE',
    'PRALIDOXIME CHLORIDE',
    'PRAMIPEXOLE',
    'PRASTERONE',
    'PRASUGREL',
    'PRAVASTATIN SODIUM',
    'PRAZIQUANTEL',
    'PRAZOSIN',
    'PREDNISOLONE',
    'PREGABALIN',
    'PRIDINOL',
    'PRILOCAINE HYDROCHLORIDE',
    'PRILOCAINE WITH FELYPRESSIN',
    'PRIMAQUINE',
    'PRIMIDONE',
    'PROCARBAZINE',
    'PROCHLORPERAZINE',
    'PROCYCLIDINE HYDROCHLORIDE',
    'PROFLAVINE',
    'PROGESTERONE',
    'PROGUANIL HYDROCHLORIDE',
    'PROMAZINE HYDROCHLORIDE',
    'PROMETHAZINE HYDROCHLORIDE',
    'PROMETHAZINE TEOCLATE',
    'PROPAFENONE HYDROCHLORIDE',
    'PROPAMIDINE ISETIONATE',
    'PROPANTHELINE BROMIDE',
    'PROPIVERINE HYDROCHLORIDE',
    'PROPOFOL',
    'PROPRANOLOL HYDROCHLORIDE',
    'PROPYLTHIOURACIL',
    'PROTAMINE SULFATE',
    'PROTEIN C CONCENTRATE',
    'PROXYMETACAINE HYDROCHLORIDE',
    'PRUCALOPRIDE',
    'PSEUDOEPHEDRINE HYDROCHLORIDE',
    'PYRAZINAMIDE',
    'PYRIDOSTIGMINE BROMIDE',
    'PYRIDOXINE HYDROCHLORIDE',
    'PYRIMETHAMINE',
    'QUETIAPINE',
    'QUINAGOLIDE',
    'QUINAPRIL',
    'QUINAPRIL WITH HYDROCHLOROTHIAZIDE',
    'QUININE',
    'RABEPRAZOLE SODIUM',
    'RABIES IMMUNOGLOBULIN',
    'RABIES VACCINE',
    'RALOXIFENE HYDROCHLORIDE',
    'RALTEGRAVIR',
    'RALTITREXED',
    'RAMIPRIL',
    'RAMIPRIL WITH FELODIPINE',
    'RAMUCIRUMAB',
    'RANIBIZUMAB',
    'RANITIDINE',
    'RANOLAZINE',
    'RASAGILINE',
    'RASBURICASE',
    'RAVULIZUMAB',
    'REBOXETINE',
    'REGORAFENIB',
    'REMDESIVIR',
    'REMIFENTANIL',
    'REPAGLINIDE',
    'RESLIZUMAB',
    'RETINOL PALMITATE WITH WHITE SOFT PARAFFIN, LIGHT LIQUID PARAFFIN, LIQUID PARAFFIN AND WOOL FAT',
    'RIBAVIRIN',
    'RIBOCICLIB',
    'RIFABUTIN',
    'RIFAMPICIN',
    'RIFAMPICIN WITH ETHAMBUTOL, ISONIAZID AND PYRAZINAMIDE',
    'RIFAMPICIN WITH ISONIAZID',
    'RIFAMPICIN WITH ISONIAZID AND PYRAZINAMIDE',
    'RIFAXIMIN',
    'RILPIVIRINE',
    'RILUZOLE',
    'RIOCIGUAT',
    'RISANKIZUMAB',
    'RISEDRONATE SODIUM',
    'RISEDRONATE WITH CALCIUM CARBONATE AND COLECALCIFEROL',
    'RISPERIDONE',
    'RITONAVIR',
    'RITUXIMAB',
    'RIVAROXABAN',
    'RIVASTIGMINE',
    'RIZATRIPTAN',
    'ROCURONIUM BROMIDE',
    'ROFLUMILAST',
    'ROMIPLOSTIM',
    'ROMOSOZUMAB',
    'ROPINIROLE',
    'ROPIVACAINE HYDROCHLORIDE',
    'ROSUVASTATIN',
    'ROTAVIRUS VACCINE',
    'ROTIGOTINE',
    'RUCAPARIB',
    'RUFINAMIDE',
    'RUPATADINE',
    'RUXOLITINIB',
    'SACUBITRIL WITH VALSARTAN',
    'SAFINAMIDE',
    'SALBUTAMOL',
    'SALICYLIC ACID',
    'SALICYLIC ACID WITH LACTIC ACID ',
    'SALICYLIC ACID WITH RHUBARB EXTRACT',
    'SALICYLIC ACID WITH ZINC OXIDE',
    'SALMETEROL',
    'SAPROPTERIN DIHYDROCHLORIDE',
    'SARILUMAB',
    'SAXAGLIPTIN',
    'SAXAGLIPTIN WITH DAPAGLIFLOZIN',
    'SAXAGLIPTIN WITH METFORMIN',
    'SECUKINUMAB',
    'SELEGILINE HYDROCHLORIDE',
    'SELENIUM',
    'SELEXIPAG',
    'SEMAGLUTIDE',
    'SENNA',
    'SENNA WITH ISPAGHULA HUSK',
    'SERTRALINE',
    'SEVELAMER',
    'SEVOFLURANE',
    'SILDENAFIL',
    'SILTUXIMAB',
    'SILVER NITRATE',
    'SILVER SULFADIAZINE',
    'SIMETICONE',
    'SIMETICONE WITH ALUMINIUM HYDROXIDE AND MAGNESIUM HYDROXIDE',
    'SIMVASTATIN',
    'SIMVASTATIN WITH EZETIMIBE',
    'SIMVASTATIN WITH FENOFIBRATE',
    'SIPONIMOD',
    'SIROLIMUS',
    'SITAGLIPTIN',
    'SITAGLIPTIN WITH METFORMIN',
    'SODIUM ACID PHOSPHATE WITH SODIUM BICARBONATE',
    'SODIUM ACID PHOSPHATE WITH SODIUM PHOSPHATE',
    'SODIUM ALGINATE WITH CALCIUM CARBONATE AND SODIUM BICARBONATE',
    'SODIUM ALGINATE WITH POTASSIUM BICARBONATE',
    'SODIUM AUROTHIOMALATE',
    'SODIUM BICARBONATE',
    'SODIUM BICARBONATE WITH SODIUM CHLORIDE',
    'SODIUM CHLORIDE',
    'SODIUM CHLORIDE WITH GLUCOSE',
    'SODIUM CITRATE',
    'SODIUM CLODRONATE',
    'SODIUM CROMOGLICATE',
    'SODIUM FEREDETATE',
    'SODIUM FLUORIDE',
    'SODIUM HYALURONATE',
    'SODIUM HYALURONATE WITH TREHALOSE',
    'SODIUM NITRITE',
    'SODIUM NITROPRUSSIDE',
    'SODIUM OXYBATE',
    'SODIUM PHENYLBUTYRATE',
    'SODIUM PICOSULFATE',
    'SODIUM POLYSTYRENE SULFONATE',
    'SODIUM TETRADECYL SULFATE',
    'SODIUM THIOSULFATE',
    'SODIUM VALPROATE',
    'SODIUM ZIRCONIUM CYCLOSILICATE',
    'SOFOSBUVIR',
    'SOFOSBUVIR WITH VELPATASVIR',
    'SOFOSBUVIR WITH VELPATASVIR AND VOXILAPREVIR',
    'SOLIFENACIN SUCCINATE',
    'SOLRIAMFETOL',
    'SOMATROPIN',
    'SORAFENIB',
    'SOTALOL HYDROCHLORIDE',
    'SOYBEAN OIL',
    'SPIRONOLACTONE',
    'SPIRONOLACTONE WITH FUROSEMIDE',
    "ST JOHN'S WORT",
    'STERCULIA',
    'STERCULIA WITH FRANGULA',
    'STIRIPENTOL',
    'STREPTOKINASE',
    'STREPTOMYCIN',
    'STREPTOZOCIN',
    'STRONTIUM RANELATE',
    'SUCRALFATE',
    'SUCROFERRIC OXYHYDROXIDE',
    'SUGAMMADEX',
    'SULFADIAZINE',
    'SULFASALAZINE',
    'SULINDAC',
    'SULPIRIDE',
    'SUMATRIPTAN',
    'SUNITINIB',
    'SUXAMETHONIUM CHLORIDE',
    'TACALCITOL',
    'TACROLIMUS',
    'TADALAFIL',
    'TAFAMIDIS',
    'TAFLUPROST',
    'TAFLUPROST WITH TIMOLOL',
    'TALAZOPARIB',
    'TALIMOGENE LAHERPAREPVEC',
    'TAMOXIFEN',
    'TAMSULOSIN HYDROCHLORIDE',
    'TAMSULOSIN WITH DUTASTERIDE',
    'TAMSULOSIN WITH SOLIFENACIN',
    'TAPENTADOL',
    'TAZAROTENE',
    'TEDIZOLID',
    'TEDUGLUTIDE',
    'TEGAFUR WITH GIMERACIL AND OTERACIL',
    'TEICOPLANIN',
    'TELAVANCIN',
    'TELMISARTAN',
    'TELMISARTAN WITH HYDROCHLOROTHIAZIDE',
    'TELOTRISTAT ETHYL',
    'TEMAZEPAM',
    'TEMOCILLIN',
    'TEMOPORFIN',
    'TEMOZOLOMIDE',
    'TEMSIROLIMUS',
    'TENECTEPLASE',
    'TENOFOVIR ALAFENAMIDE',
    'TENOFOVIR DISOPROXIL',
    'TENOXICAM',
    'TERAZOSIN',
    'TERBINAFINE',
    'TERBUTALINE SULFATE',
    'TERIFLUNOMIDE',
    'TERIPARATIDE',
    'TERLIPRESSIN ACETATE',
    'TESTOSTERONE',
    'TESTOSTERONE DECANOATE, ISOCAPROATE, PHENYLPROPIONATE AND PROPIONATE',
    'TESTOSTERONE ENANTATE',
    'TESTOSTERONE PROPIONATE',
    'TESTOSTERONE UNDECANOATE',
    'TETANUS IMMUNOGLOBULIN',
    'TETRABENAZINE',
    'TETRACAINE',
    'TETRACOSACTIDE',
    'TETRACYCLINE',
    'TEZACAFTOR WITH IVACAFTOR',
    'TEZACAFTOR WITH IVACAFTOR AND ELEXACAFTOR',
    'THALIDOMIDE',
    'THEOPHYLLINE',
    'THIAMINE',
    'THIOPENTAL SODIUM',
    'THIOTEPA',
    'THYROTROPIN ALFA',
    'TIAGABINE',
    'TIAPROFENIC ACID',
    'TIBOLONE',
    'TICAGRELOR',
    'TICK-BORNE ENCEPHALITIS VACCINE, INACTIVATED',
    'TIGECYCLINE',
    'TILDRAKIZUMAB',
    'TIMOLOL MALEATE',
    'TIMOLOL WITH AMILORIDE AND HYDROCHLOROTHIAZIDE',
    'TIMOLOL WITH BENDROFLUMETHIAZIDE',
    'TINIDAZOLE',
    'TINZAPARIN SODIUM',
    'TIOCONAZOLE',
    'TIOGUANINE',
    'TIOTROPIUM',
    'TIOTROPIUM WITH OLODATEROL',
    'TIPRANAVIR',
    'TIROFIBAN',
    'TIVOZANIB',
    'TIZANIDINE',
    'TOBRAMYCIN',
    'TOCILIZUMAB',
    'TOFACITINIB',
    'TOLBUTAMIDE',
    'TOLCAPONE',
    'TOLFENAMIC ACID',
    'TOLTERODINE TARTRATE',
    'TOLVAPTAN',
    'TOPIRAMATE',
    'TOPOTECAN',
    'TORASEMIDE',
    'TOREMIFENE',
    'TRABECTEDIN',
    'TRAMADOL HYDROCHLORIDE',
    'TRAMADOL WITH DEXKETOPROFEN',
    'TRAMADOL WITH PARACETAMOL',
    'TRAMETINIB',
    'TRANDOLAPRIL',
    'TRANEXAMIC ACID',
    'TRANYLCYPROMINE',
    'TRASTUZUMAB',
    'TRASTUZUMAB DERUXTECAN',
    'TRASTUZUMAB EMTANSINE',
    'TRAVOPROST',
    'TRAVOPROST WITH TIMOLOL',
    'TRAZODONE HYDROCHLORIDE',
    'TREE POLLEN EXTRACT',
    'TREOSULFAN',
    'TREPROSTINIL',
    'TRETINOIN',
    'TRETINOIN WITH CLINDAMYCIN',
    'TRETINOIN WITH ERYTHROMYCIN',
    'TRIAMCINOLONE ACETONIDE',
    'TRIAMCINOLONE HEXACETONIDE',
    'TRIAMTERENE',
    'TRIAMTERENE WITH CHLORTALIDONE',
    'TRIENTINE DIHYDROCHLORIDE',
    'TRIFLUOPERAZINE',
    'TRIFLURIDINE WITH TIPIRACIL',
    'TRIHEXYPHENIDYL HYDROCHLORIDE',
    'TRIMETHOPRIM',
    'TRIMIPRAMINE',
    'TRIPTORELIN',
    'TROPICAMIDE',
    'TROPICAMIDE WITH PHENYLEPHRINE AND LIDOCAINE',
    'TROSPIUM CHLORIDE',
    'TRYPTOPHAN',
    'TUBERCULIN PURIFIED PROTEIN DERIVATIVE',
    'TYPHOID VACCINE',
    'ULIPRISTAL ACETATE',
    'UMECLIDINIUM',
    'UMECLIDINIUM WITH VILANTEROL',
    'UNDECENOIC ACID WITH ZINC UNDECENOATE',
    'UPADACITINIB',
    'UREA (13C)',
    'UREA HYDROGEN PEROXIDE',
    'UROFOLLITROPIN',
    'UROKINASE',
    'URSODEOXYCHOLIC ACID',
    'USTEKINUMAB',
    'VALACICLOVIR',
    'VALGANCICLOVIR',
    'VALPROIC ACID',
    'VALSARTAN',
    'VALSARTAN WITH HYDROCHLOROTHIAZIDE',
    'VANCOMYCIN',
    'VANDETANIB',
    'VARDENAFIL',
    'VARENICLINE',
    'VARICELLA-ZOSTER IMMUNOGLOBULIN',
    'VARICELLA-ZOSTER VACCINE',
    'VASOPRESSIN',
    'VECURONIUM BROMIDE',
    'VEDOLIZUMAB',
    'VELAGLUCERASE ALFA',
    'VEMURAFENIB',
    'VENETOCLAX',
    'VENLAFAXINE',
    'VERAPAMIL HYDROCHLORIDE',
    'VERNAKALANT',
    'VERTEPORFIN',
    'VIGABATRIN',
    'VILDAGLIPTIN',
    'VILDAGLIPTIN WITH METFORMIN',
    'VINBLASTINE SULFATE',
    'VINCRISTINE SULFATE',
    'VINDESINE SULFATE',
    'VINFLUNINE',
    'VINORELBINE',
    'VISMODEGIB',
    'VITAMIN A',
    'VITAMIN B COMPLEX',
    'VITAMIN B SUBSTANCES WITH ASCORBIC ACID',
    'VITAMINS A AND D',
    'VITAMINS A, C AND D',
    'VITAMINS WITH MINERALS AND TRACE ELEMENTS',
    'VOLANESORSEN',
    'VON WILLEBRAND FACTOR',
    'VORICONAZOLE',
    'VORTIOXETINE',
    'WARFARIN SODIUM',
    'WASP VENOM EXTRACT',
    'XIPAMIDE',
    'XYLOMETAZOLINE HYDROCHLORIDE',
    'YELLOW FEVER VACCINE, LIVE',
    'ZANAMIVIR',
    'ZIDOVUDINE',
    'ZIDOVUDINE WITH LAMIVUDINE',
    'ZINC ACETATE',
    'ZINC SULFATE',
    'ZOLEDRONIC ACID',
    'ZOLMITRIPTAN',
    'ZOLPIDEM TARTRATE',
    'ZONISAMIDE',
    'ZOPICLONE',
    'ZUCLOPENTHIXOL',
    'ZUCLOPENTHIXOL ACETATE',
    'ZUCLOPENTHIXOL DECANOATE'
];

module.exports = commonDrugsList;
