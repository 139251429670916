import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';
import React from 'react';
import { useForms } from '../../../../hooks/forms';
import PropTypes from 'prop-types';
import { hasErrors } from '../../../../helpers';

const CheckBoxWidget = ({ widgetProps, onChange }) => {
    const { isJourney, isReadOnly, errors } = useForms();

    return (
        <>
            <FormControl>
                <FormControlLabel
                    id={widgetProps.id}
                    disabled={!isJourney || isReadOnly}
                    required={widgetProps.isRequired}
                    control={
                        <Checkbox
                            checked={widgetProps.value || false}
                            onClick={() => {
                                const newValue = !widgetProps.value;
                                onChange(widgetProps.id, newValue);
                            }}
                            name={widgetProps.id}
                        />
                    }
                    label={widgetProps.title}
                />
                {hasErrors(widgetProps.id, errors) && (
                    <FormHelperText error>This field must be checked to proceed</FormHelperText>
                )}
            </FormControl>
        </>
    );
};

CheckBoxWidget.propTypes = {
    widgetProps: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
};

export default CheckBoxWidget;
