const getVH = () => {
    const updateVh = () => document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

    window.addEventListener('resize', updateVh);
    window.addEventListener('orientationchange', updateVh);

    updateVh();
};

export default getVH;
