import TextFieldsIcon from '@material-ui/icons/TextFields';
import TitleIcon from '@material-ui/icons/Title';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import ImageIcon from '@material-ui/icons/Image';
import QueueIcon from '@material-ui/icons/Queue';
import NotesIcon from '@material-ui/icons/Notes';
import ListIcon from '@material-ui/icons/List';
import DescriptionIcon from '@material-ui/icons/Description';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import BorderColorIcon from '@material-ui/icons/BorderColor';

const widgetList = [
    {
        title: 'Title',
        type: 'Title',
        icon: TitleIcon,
        info: '',
        startValue: {
            title: 'Title'
        }
    },
    {
        title: 'Paragraph',
        type: 'Paragraph',
        icon: NotesIcon,
        info: '',
        startValue: {
            title: 'Title',
            content: 'Content'
        }
    },
    {
        title: 'Text input field',
        type: 'Text',
        icon: TextFieldsIcon,
        info: '',
        startValue: {
            title: 'Placeholder Text'
        }
    },
    {
        title: 'Checkbox',
        type: 'Checkbox',
        icon: CheckBoxIcon,
        info: '',
        startValue: {
            title: 'Checkbox'
        }
    },
    {
        title: 'Radio buttons',
        type: 'Radio',
        icon: RadioButtonCheckedIcon,
        info: 'Choose one from a list of options',
        startValue: {
            title: 'Radio buttons',
            options: ['Option 1', 'Option 2', 'Option 3']
        }
    },
    /* {
        title: 'Select',
        type: 'Select',
        icon: ArrowDropDownCircleIcon,
        info: 'Dropdown with a list of items',
        startValue: {
            title: 'Select',
            options: ['Option 1', 'Option 2', 'Option 3']
        }
    }, */
    {
        title: 'Dropdown list',
        type: 'AutoComplete',
        icon: QueueIcon,
        info: 'Input with options to select or add a new option',
        startValue: {
            title: 'Dropdown list',
            options: ['Option 1', 'Option 2', 'Option 3'],
            multiple: true,
            autocompleteSearch: false
        }
    },
    {
        title: 'Radio with dropdown',
        type: 'RadioAutoComplete',
        icon: QueueIcon,
        info: 'Dropdown appears if select “Yes”',
        startValue: {
            title: 'Radio with dropdown',
            options: ['Option 1', 'Option 2', 'Option 3'],
            autocompleteSearch: false,
            multiple: false
        }
    },
    {
        title: 'Radios with text field',
        type: 'RadioText',
        icon: TextFieldsIcon,
        info: 'Text input field appears if radio = “Yes”',
        startValue: {
            title: 'Radios with text field',
            textTitle: 'More info'
        }
    },
    {
        title: 'Dropdowns & text fields',
        type: 'List',
        icon: ListIcon,
        info: 'Add multiple dropdown lists, each with a text field input',
        startValue: {
            title: 'Dropdowns & text fields',
            options: ['Option 1', 'Option 2', 'Option 3'],
            autocompleteSearch: false
        }
    },
    {
        title: 'Radio with Dropdowns & text fields',
        type: 'RadioList',
        icon: ListIcon,
        info: 'Add multiple dropdown lists, each with a text field input if radio = “Yes”',
        startValue: {
            title: 'Radio with Dropdowns & text fields',
            options: ['Option 1', 'Option 2', 'Option 3']
        }
    },
    {
        title: 'Text Area',
        type: 'TextArea',
        icon: DescriptionIcon,
        info: 'Larger space for text input',
        startValue: {
            title: 'Text Area'
        }
    },
    {
        title: 'Blank space',
        type: 'Block',
        icon: CheckBoxOutlineBlankIcon,
        info: 'Empty space to separate sections',
        startValue: {
            size: 50
        }
    },
    {
        title: 'Image',
        type: 'Image',
        icon: ImageIcon,
        info: '',
        startValue: {
            title: 'Image',
            src: ''
        }
    },
    {
        title: 'Signature',
        type: 'SignatureField',
        icon: BorderColorIcon,
        info: 'Button to open a space to insert a signature',
        startValue: {
            title: 'Click button below to sign'
        }
    }
];

export default widgetList;
