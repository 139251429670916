import { isPossiblePhoneNumber } from 'libphonenumber-js';

const isValidPhone = (phoneNumber, countryCode = 'uk') => {
    try {
        return isPossiblePhoneNumber(phoneNumber, countryCode);
    } catch (err) {
        console.error(err);
    }
    return false;
};

export default isValidPhone;
