import Api from './config';
import axios from 'axios';
import imageCompression from 'browser-image-compression';

class TreatmentNoteApi {
    static query(query = '') {
        return Api.request({
            method: 'GET',
            url: `/treatment-notes?${query}`
        });
    }

    static create(data, options) {
        return Api.request({
            method: 'POST',
            url: '/treatment-notes',
            data,
            ...options
        });
    }

    static update(id, data, options) {
        return Api.request({
            method: 'PUT',
            url: `/treatment-notes/${id}`,
            data,
            ...options
        });
    }

    static getNote(id, isFromJourney) {
        return Api.request({
            method: 'GET',
            url: `/treatment-notes/${id}${isFromJourney ? '?isFromJourney=true' : ''}`
        });
    }

    static getTemplates() {
        return Api.request({
            method: 'GET',
            url: '/treatment-notes/templates'
        });
    }

    static createTemplate(type, data) {
        return Api.request({
            method: 'POST',
            url: `/treatment-notes/templates/${type}`,
            data
        });
    }

    static deleteNotesTemplate(ids) {
        return Api.request({
            method: 'DELETE',
            url: '/treatment-notes/notes-templates',
            data: {
                ids
            }
        });
    }

    static deleteToxinsTemplate(ids) {
        return Api.request({
            method: 'DELETE',
            url: '/treatment-notes/toxins-templates',
            data: {
                ids
            }
        });
    }

    static getServices(customerId) {
        return Api.request({
            method: 'GET',
            url: `/treatment-notes/${customerId}/services`
        });
    }

    static getCategories(customerId) {
        return Api.request({
            method: 'GET',
            url: `/treatment-notes/${customerId}/categories`
        });
    }

    static getPractitioners(customerId) {
        return Api.request({
            method: 'GET',
            url: `/treatment-notes/${customerId}/practitioners`
        });
    }

    static getPresignedURI({ treatmentId, photoId, customerId, name }) {
        const type = 'image/png';
        return Api.request({
            method: 'PUT',
            url: `/treatment-notes/${treatmentId}/upload-photo/${photoId}?customerId=${customerId}&type=${type}&name=${name}`
        });
    }
    static savePhotos(treatmentId, photos, customerId) {
        return Api.request({
            method: 'PUT',
            url: `/treatment-notes/${treatmentId}/save-photos/${customerId}`,
            data: {
                photos
            }
        });
    }

    static removePhoto({ treatmentId, photoId, customerId }) {
        return Api.request({
            method: 'PUT',
            url: `/treatment-notes/${treatmentId}/remove-photo/${photoId}?customerId=${customerId}`
        });
    }

    static getDailyTreatmentData() {
        return Api.request({
            method: 'GET',
            url: '/treatment-notes/get-daily-data'
        });
    }

    static async uploadPhotos(uploadedPhotos, treatmentId, customerId) {
        const uploadPhoto = async (url, file) => {
            const optimizedImg = await imageCompression(file, { maxSizeMB: 0.1 });
            const optimizedFile = new File([optimizedImg], file.name, { type: file.type });

            const options = {
                headers: {
                    'Content-Type': file.name.split('.')[1],
                    'x-amz-acl': 'public-read'
                }
            };

            try {
                // signedRequest, file, options
                await axios({ url: url, method: 'PUT', data: optimizedFile, options });
                return url;
            } catch (error) {
                console.error(error);
            }
        };

        for (const [i, photo] of uploadedPhotos.entries()) {
            const url = await this.getPresignedURI({
                treatmentId,
                customerId,
                name: photo.name || '',
                photoId: `${photo.name}-${i}`
            });

            try {
                await uploadPhoto(url.signedRequest, photo.file);
            } catch (error) {
                console.error(error);
            }
        }
    }

    static getTreatmentNotesLog(treatmentId) {
        return Api.request({
            method: 'GET',
            url: `/treatment-notes/notes-log/${treatmentId}`
        });
    }

    static getTreatmentLog(treatmentId) {
        return Api.request({
            method: 'GET',
            url: `/treatment-notes/log/${treatmentId}`
        });
    }

    static updatePhotoList(treatmentId, skipDraft) {
        return Api.request({
            method: 'PUT',
            url: `/treatment-notes/update-photo-list/${treatmentId}${skipDraft ? '?skipDraft=true' : ''}`
        });
    }
}

export default TreatmentNoteApi;
