import React from 'react';
import { useForms } from '../../../../hooks/forms';
import { withStyles } from '@material-ui/core';
import formStyles from '../../../../../collums-constants/styles/stylesheets/formStyles';
import PropTypes from 'prop-types';
import { hasErrors } from '../../../../helpers';

const TextAreaWidget = ({ classes, widgetProps, onChange }) => {
    const { isJourney, isReadOnly, errors } = useForms();

    return (
        <div>
            <textarea
                className={`${classes.textArea} usePlaceholder ${
                    hasErrors(widgetProps.id, errors) && !isReadOnly
                        ? `${classes.error} errorPlaceholder`
                        : 'transparentPlaceholder'
                }`}
                placeholder={widgetProps.title}
                rows={4}
                disabled={!isJourney || isReadOnly}
                value={widgetProps.value || ''}
                id={widgetProps.id}
                onChange={({ target }) => {
                    const newValue = target.value || '';
                    onChange(widgetProps.id, newValue);
                }}
                required={widgetProps.isRequired}
            />
        </div>
    );
};

TextAreaWidget.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    widgetProps: PropTypes.object.isRequired
};

export default withStyles(formStyles)(TextAreaWidget);
