import React from 'react';
import {
    Button,
    IconButton,
    Popover,
    TextField,
    Checkbox,
    FormControlLabel,
    withStyles,
    makeStyles,
    RadioGroup,
    Tooltip,
    Grid,
    Typography,
    Radio,
    Divider
} from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import {
    WIDGET_GROUPS,
    WIDGET_TYPES,
    IMAGE_WIDGET_ALIGNMENT,
    IMAGE_WIDGET_SIZE,
    MEDICAL_HISTORY_SELECTIONS
} from '../../../../../collums-constants';
import { useForms } from '../../../../hooks/forms';
import { toastr } from 'react-redux-toastr';
import { widgetConfigStyles as styles } from './styles';
import PropTypes from 'prop-types';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import widgetList from './../../menu/widgetList';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import CreateIcon from '@material-ui/icons/Create';
import { swapPosition } from '../../../../helpers';
import { Autocomplete } from '@material-ui/lab';
import formStyles from '../../../../../collums-constants/styles/stylesheets/formStyles';

const WidgetConfig = ({ classes, anchorEl, widgetId, setAnchorEl }) => {
    const { data, setData } = useForms();
    const formClasses = makeStyles(formStyles)();

    const [widgetData, setWidgetData] = useState();
    const [widgetDisplayingData, setWidgetDisplayingData] = useState();
    const [editingOption, setEditingOption] = useState({});
    const changedOptionRef = useRef();

    const popoverItemsRef = useRef();
    const newOptionRef = useRef('');

    useEffect(() => {
        const widget = data.fields.find(el => el.id === widgetId);
        if (!widget) return setAnchorEl(null);
        setWidgetData(widget);
        setWidgetDisplayingData(widget);
        popoverItemsRef.current = { ...widget };
        //eslint-disable-next-line
    }, [widgetId]);

    useEffect(() => {
        const widget = data.fields.find(el => el.id === widgetId);
        setWidgetDisplayingData(widget);
    }, [data, widgetId]);

    const getDefaultWidgetProps = () => {
        return widgetList.find(widgetItem => {
            return widgetItem.type === widgetData?.type;
        });
    };

    const WidgetTitle = () => {
        const defaultWidgetProps = getDefaultWidgetProps();
        return <span className={`${classes.titleSpan} ${classes.bold}`}>{defaultWidgetProps?.title || ''}</span>;
    };

    const TextInput = ({ id, label, isNumber, multiLine }) => {
        const defaultWidgetProps = getDefaultWidgetProps()?.startValue;
        const multiLineProps = {
            multiline: true,
            rows: 22
        };
        return (
            <TextField
                id={`${widgetData.id}-edit-${id}`}
                variant="outlined"
                label={label}
                onChange={({ target }) => {
                    const newData = { ...popoverItemsRef.current, [id]: target.value };
                    popoverItemsRef.current = newData;
                }}
                type={isNumber ? 'number' : 'text'}
                onClick={({ target }) => {
                    if (!isNumber && (defaultWidgetProps || {})[id] === popoverItemsRef.current[id]) {
                        target.value = '';
                        const newData = { ...popoverItemsRef.current, [id]: '' };
                        popoverItemsRef.current = newData;
                    }
                }}
                defaultValue={widgetData[id] || ''}
                className={`${classes.textInput} ${isNumber ? classes.numberInput : ''} ${
                    multiLine ? classes.multilineInput : ''
                }`}
                {...(multiLine ? multiLineProps : {})}
            />
        );
    };

    TextInput.propTypes = {
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        isNumber: PropTypes.bool,
        multiLine: PropTypes.bool
    };

    const CheckboxInput = ({ id, label, updateWidgetOnChange = false }) => {
        return (
            <FormControlLabel
                id={`${widgetData.id}-edit-${id}`}
                control={
                    <Checkbox
                        defaultChecked={widgetData[id] || false}
                        onChange={() => {
                            const newData = { ...popoverItemsRef.current, [id]: !popoverItemsRef.current[id] };
                            popoverItemsRef.current = newData;
                            if (updateWidgetOnChange) {
                                const updatedWidget = {
                                    ...widgetDisplayingData,
                                    [id]: popoverItemsRef.current[id]
                                };
                                const newValues = data.fields.map(el => {
                                    if (el.id === widgetId) return { ...updatedWidget };
                                    return el;
                                });
                                setData({ ...data, fields: newValues });
                                setWidgetData(popoverItemsRef.current);
                                return;
                            }
                        }}
                        name={`${widgetData.id}-edit-${id}`}
                    />
                }
                label={label}
            />
        );
    };

    CheckboxInput.propTypes = {
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        updateWidgetOnChange: PropTypes.bool
    };

    const changePosition = (optionIndex, newOptionIndex, id) => {
        const updatedOptions = swapPosition([...(widgetData[id] || [])], optionIndex, newOptionIndex);
        const updatedWidget = {
            ...widgetDisplayingData,
            [id]: updatedOptions
        };
        const updatedFields = data.fields.map(el => {
            if (el.id === widgetId) return { ...updatedWidget };
            return el;
        });
        const updatedWidgetRef = {
            ...popoverItemsRef.current,
            [id]: updatedOptions
        };
        popoverItemsRef.current = updatedWidgetRef;
        setData({ ...data, fields: updatedFields });
        setWidgetData(updatedWidgetRef);
    };

    const cancelEditingOption = () => {
        changedOptionRef.current = undefined;
        setEditingOption({});
    };

    const OptionsInput = ({ id, label, isRadio = false, requireDefault, afterHeaderComponents = null }) => {
        const availableOptions = widgetData[id];
        const isEditingField = editingOption.id === id;
        return (
            <>
                <div className={classes.optionContainer}>
                    <div className={classes.separatedRow}>
                        <span className={classes.bold}>{label}</span>
                    </div>
                    {afterHeaderComponents && (
                        <>
                            {afterHeaderComponents}
                            <br />
                        </>
                    )}
                    {isRadio ? (
                        <div></div>
                    ) : (
                        <>
                            <FormControlLabel
                                id={`${widgetData.id}-edit-${id}-drugs-checkbox`}
                                control={
                                    <Checkbox
                                        defaultChecked={widgetData.useDrugList || false}
                                        onChange={() => {
                                            const updatedWidget = {
                                                ...widgetDisplayingData,
                                                useDrugList: !popoverItemsRef.current.useDrugList
                                            };
                                            popoverItemsRef.current = {
                                                ...popoverItemsRef.current,
                                                useDrugList: !popoverItemsRef.current.useDrugList
                                            };
                                            const newValues = data.fields.map(el => {
                                                if (el.id === widgetId) return { ...updatedWidget };
                                                return el;
                                            });
                                            setData({ ...data, fields: newValues });
                                            setWidgetData(popoverItemsRef.current);
                                        }}
                                        name={`${widgetData.id}-edit-${id}-drugs-checkbox`}
                                    />
                                }
                                label={'Use BNF drug list'}
                            />
                        </>
                    )}
                    {availableOptions.map((el, ind) => {
                        const isEditingOption = isEditingField && editingOption.index === ind;

                        const rightIcons = (() => {
                            if (isEditingOption) {
                                return (
                                    <>
                                        <IconButton
                                            className={classes.actionOption}
                                            onClick={() => {
                                                cancelEditingOption();
                                            }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                const isExistingOption = widgetData.options.some(
                                                    (optItem, optIndex) =>
                                                        optItem === changedOptionRef.current && optIndex !== ind
                                                );
                                                if (isExistingOption) {
                                                    toastr.error('Option already insert');
                                                    return;
                                                }
                                                const newWidgetValue =
                                                    widgetData.value === widgetData.options[ind]
                                                        ? changedOptionRef.current
                                                        : widgetData.value;
                                                if (changedOptionRef.current) {
                                                    const updatedOptions = (widgetData[id] || []).map(
                                                        (item, itemIndex) => {
                                                            if (itemIndex === ind) {
                                                                return changedOptionRef.current;
                                                            }
                                                            return item;
                                                        }
                                                    );
                                                    const updatedWidget = {
                                                        ...widgetDisplayingData,
                                                        [id]: updatedOptions,
                                                        value: newWidgetValue
                                                    };
                                                    const updatedFields = data.fields.map(el => {
                                                        if (el.id === widgetId) return { ...updatedWidget };
                                                        return el;
                                                    });
                                                    const updatedWidgetRef = {
                                                        ...popoverItemsRef.current,
                                                        [id]: updatedOptions,
                                                        value: newWidgetValue
                                                    };
                                                    popoverItemsRef.current = updatedWidgetRef;
                                                    setData({ ...data, fields: updatedFields });
                                                    setWidgetData(updatedWidgetRef);
                                                }
                                                cancelEditingOption();
                                            }}
                                            className={classes.actionOption}
                                        >
                                            <DoneIcon />
                                        </IconButton>
                                    </>
                                );
                            }
                            return (
                                <>
                                    <IconButton
                                        className={classes.actionOption}
                                        disabled={isEditingField}
                                        onClick={() => {
                                            const newWidgetValue =
                                                widgetData.value === widgetData.options[ind]
                                                    ? undefined
                                                    : widgetData.value;

                                            const newOptions = widgetData[id].filter((_, index) => index !== ind);
                                            const updatedWidget = {
                                                ...widgetDisplayingData,
                                                [id]: newOptions,
                                                value: newWidgetValue
                                            };
                                            const newValues = data.fields.map(el => {
                                                if (el.id === widgetId) return { ...updatedWidget };
                                                return el;
                                            });
                                            const updatedWidgetRef = {
                                                ...popoverItemsRef.current,
                                                [id]: newOptions,
                                                value: newWidgetValue
                                            };
                                            popoverItemsRef.current = updatedWidgetRef;
                                            setData({ ...data, fields: newValues });
                                            setWidgetData(updatedWidgetRef);
                                        }}
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => {
                                            changedOptionRef.current = undefined;
                                            setEditingOption({
                                                id,
                                                index: ind,
                                                defaultValue: el
                                            });
                                        }}
                                        className={classes.actionOption}
                                    >
                                        <CreateIcon />
                                    </IconButton>
                                </>
                            );
                        })();

                        return (
                            <div key={ind} className={classes.availableOptions}>
                                <div className={classes.option}>
                                    <div className={classes.arrowContainer}>
                                        <IconButton
                                            onClick={() => changePosition(ind, ind - 1, id)}
                                            disabled={ind === 0 || isEditingField}
                                        >
                                            <ArrowUpwardIcon className={classes.arrowIcons} />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => changePosition(ind, ind + 1, id)}
                                            disabled={ind === availableOptions.length - 1 || isEditingField}
                                        >
                                            <ArrowDownwardIcon className={classes.arrowIcons} />
                                        </IconButton>
                                    </div>
                                    <div className={classes.optionArea}>
                                        {isEditingOption ? (
                                            <TextField
                                                variant="outlined"
                                                defaultValue={
                                                    changedOptionRef.current !== undefined
                                                        ? changedOptionRef.current
                                                        : editingOption.defaultValue
                                                }
                                                onChange={({ target }) => {
                                                    changedOptionRef.current = target.value;
                                                }}
                                                fullWidth
                                            />
                                        ) : (
                                            <span className={classes.optionLabel}>{el}</span>
                                        )}
                                    </div>
                                </div>
                                <div className={classes.updateOptionContainer}>{rightIcons}</div>
                            </div>
                        );
                    })}

                    <div className={classes.spacedContainer}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            defaultValue={newOptionRef.current}
                            onChange={({ target }) => {
                                newOptionRef.current = target.value;
                            }}
                            placeholder="Add another option"
                            className="usePlaceholderTextField"
                        />
                        <Tooltip title="Add another option">
                            <IconButton
                                className={classes.actionOption}
                                onClick={() => {
                                    if (!newOptionRef.current) return toastr.error("Can't add empty option");
                                    if ((widgetDisplayingData[id] || []).includes(newOptionRef.current))
                                        return toastr.error('Option already insert');
                                    const newOptions = [...widgetDisplayingData[id], newOptionRef.current];
                                    const updatedWidget = { ...widgetDisplayingData, [id]: newOptions };
                                    popoverItemsRef.current[id] = newOptions;
                                    newOptionRef.current = '';
                                    const newValues = data.fields.map(el => {
                                        if (el.id === widgetId) return { ...updatedWidget };
                                        return el;
                                    });
                                    const updatedWidgetRef = { ...popoverItemsRef.current, [id]: newOptions };
                                    setWidgetData(updatedWidgetRef);
                                    popoverItemsRef.current = updatedWidgetRef;
                                    setData({ ...data, fields: newValues });
                                }}
                            >
                                <AddCircleIcon className={classes.circleIcon} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                {requireDefault === undefined ? <div></div> : <DefaultValueInput id={requireDefault} />}
            </>
        );
    };

    OptionsInput.propTypes = {
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        isRadio: PropTypes.bool,
        afterHeaderComponents: PropTypes.element,
        requireDefault: PropTypes.bool
    };

    const Spacer = () => <div style={{ margin: 4 }} />;

    const MedicalHistoryText = props => {
        if (!widgetData.group || widgetData.group === WIDGET_GROUPS.NONE) {
            return <></>;
        }
        return (
            <Typography className={classes.medicalHistoryText}>
                The {props.widget || 'choice'} can be saved either as a label or description.
                <br />
                If one of the choices is {'"Yes"'} and it is selected, you can choose a custom label (or description
                text) if you wish
            </Typography>
        );
    };

    MedicalHistoryText.propTypes = {
        widget: PropTypes.string
    };

    const DefaultValueInput = ({ id }) => {
        return (
            <>
                <FormControlLabel
                    id={`${widgetData.id}-edit-${id}-has-default-value`}
                    control={
                        <Checkbox
                            defaultChecked={widgetData.hasDefaultValue || false}
                            onChange={() => {
                                const updatedWidget = {
                                    ...widgetDisplayingData,
                                    hasDefaultValue: !popoverItemsRef.current.hasDefaultValue
                                };
                                popoverItemsRef.current = {
                                    ...popoverItemsRef.current,
                                    hasDefaultValue: !popoverItemsRef.current.hasDefaultValue
                                };
                                if (
                                    !popoverItemsRef.current.hasDefaultValue &&
                                    popoverItemsRef.current[id] !== undefined
                                ) {
                                    delete popoverItemsRef.current[id];
                                    delete updatedWidget[id];
                                }
                                const newValues = data.fields.map(el => {
                                    if (el.id === widgetId) return { ...updatedWidget };
                                    return el;
                                });
                                setData({ ...data, fields: newValues });
                                setWidgetData(popoverItemsRef.current);
                            }}
                            name={`${widgetData.id}-edit-${id}-has-default-value`}
                        />
                    }
                    label={'Default value'}
                />
                {id === 'isVisible' ? (
                    <RadioGroup
                        aria-label="default-visible"
                        name={id}
                        value={widgetDisplayingData.isVisible}
                        onChange={({ target }) => {
                            const updatedWidget = {
                                ...widgetDisplayingData,
                                [id]: target.value
                            };
                            popoverItemsRef.current = {
                                ...popoverItemsRef.current,
                                [id]: target.value
                            };
                            const newValues = data.fields.map(el => {
                                if (el.id === widgetId) return { ...updatedWidget };
                                return el;
                            });
                            setData({ ...data, fields: newValues });
                            setWidgetData(popoverItemsRef.current);
                        }}
                    >
                        <FormControlLabel
                            value="f"
                            control={<Radio disabled={!widgetData.hasDefaultValue} />}
                            label="No"
                        />
                        <FormControlLabel
                            value="t"
                            control={<Radio disabled={!widgetData.hasDefaultValue} />}
                            label="Yes"
                        />
                    </RadioGroup>
                ) : (
                    <RadioGroup
                        aria-label="default-radio-value"
                        name={id}
                        value={widgetData[id]}
                        onChange={({ target }) => {
                            const updatedWidget = {
                                ...widgetDisplayingData,
                                value: target.value
                            };
                            popoverItemsRef.current = {
                                ...popoverItemsRef.current,
                                value: target.value
                            };
                            const newValues = data.fields.map(el => {
                                if (el.id === widgetId) return { ...updatedWidget };
                                return el;
                            });
                            setData({ ...data, fields: newValues });
                            setWidgetData(popoverItemsRef.current);
                        }}
                    >
                        {widgetData.options?.map((el, index) => (
                            <FormControlLabel
                                className={classes.optionLabel}
                                key={index}
                                value={el}
                                control={<Radio disabled={!widgetData.hasDefaultValue} />}
                                label={el}
                            />
                        ))}
                    </RadioGroup>
                )}
            </>
        );
    };

    DefaultValueInput.propTypes = {
        id: PropTypes.string.isRequired
    };

    const MedicalMessageText = type => {
        if (['Radio', 'AutoComplete', 'RadioList'].includes(type)) {
            return 'Show choices in Medical History Section?';
        } else if (type === 'List') {
            return 'Show text as detail in Medical History Section?';
        }
        return 'Show answers in Medical History Section?';
    };

    const GroupSelector = ({ showDividerOnEnd = true }) => {
        const MedicalText = MedicalMessageText(widgetData.type);

        return (
            <>
                <Divider className={classes.divider} />
                <div className={classes.groupSelector}>
                    <span>
                        <strong className={classes.bold}>{MedicalText}</strong>
                    </span>
                    <Autocomplete
                        id="form-widget-group"
                        className={`${formClasses.autocomplete} ${classes.autocomplete}`}
                        options={Object.values(WIDGET_GROUPS)}
                        value={widgetData.group || WIDGET_GROUPS.NONE}
                        onChange={(_, value) => {
                            const newGroupValues = {
                                group: value || WIDGET_GROUPS.NONE
                            };
                            if (!value || WIDGET_GROUPS.NONE === value) {
                                Object.assign(newGroupValues, {
                                    mhSelection: undefined,
                                    mhText: undefined,
                                    mhPositiveSelection: undefined
                                });
                            }
                            const updatedWidget = {
                                ...widgetDisplayingData,
                                ...newGroupValues
                            };
                            popoverItemsRef.current = {
                                ...popoverItemsRef.current,
                                ...newGroupValues
                            };
                            const newValues = data.fields.map(el => {
                                if (el.id === widgetId) return { ...updatedWidget };
                                return el;
                            });
                            setData({ ...data, fields: newValues });
                            setWidgetData(popoverItemsRef.current);
                        }}
                        renderInput={params => <TextField {...params} variant="outlined" fullWidth />}
                    />
                </div>
                {showDividerOnEnd && <Divider className={classes.divider} />}
            </>
        );
    };

    GroupSelector.propTypes = {
        showDividerOnEnd: PropTypes.bool
    };

    const CustomSelectionOnMH = ({ title, fieldKey, isText }) => {
        if (!widgetData.group || widgetData.group === WIDGET_GROUPS.NONE) {
            return <></>;
        }

        const onChangeData = (newData, updateData) => {
            popoverItemsRef.current = {
                ...popoverItemsRef.current,
                [fieldKey]: newData
            };
            if (updateData) {
                setWidgetData(popoverItemsRef.current);
            }
        };

        const onChangeValue = ({ target }) => {
            const valueToUpdate = (() => {
                if (popoverItemsRef.current[fieldKey]?.showAs === target.value) {
                    target.value = '';
                    return null;
                }
                return {
                    showAs: target.value,
                    customValue: ''
                };
            })();
            onChangeData(valueToUpdate, true);
        };

        return (
            <RadioGroup
                aria-label={title}
                name={title}
                value={widgetData[fieldKey]?.showAs}
                onClick={({ target }) => {
                    if (
                        popoverItemsRef.current[fieldKey]?.showAs &&
                        popoverItemsRef.current[fieldKey].showAs === target.value
                    ) {
                        target.value = '';
                        onChangeData(undefined, true);
                    }
                }}
                onChange={onChangeValue}
            >
                <Grid container spacing={1} direction="row" justify="flex-start" alignItems="center">
                    <Grid item xs={3}>
                        <Typography>{title}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            value={MEDICAL_HISTORY_SELECTIONS.LABEL}
                            control={<Radio />}
                            label={MEDICAL_HISTORY_SELECTIONS.LABEL}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControlLabel
                            value={MEDICAL_HISTORY_SELECTIONS.DESCRIPTION}
                            control={<Radio />}
                            label={MEDICAL_HISTORY_SELECTIONS.DESCRIPTION}
                        />
                    </Grid>
                    <Grid item xs={1} />
                    {!isText && (
                        <Grid
                            item
                            xs={4}
                            style={{
                                visibility: !widgetData[fieldKey]?.showAs ? 'hidden' : 'visible'
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="outlined"
                                defaultValue={widgetData[fieldKey]?.customValue}
                                onChange={({ target }) => {
                                    onChangeData({
                                        ...(widgetData[fieldKey] || {}),
                                        customValue: target.value
                                    });
                                }}
                                placeholder={
                                    widgetData[fieldKey]?.showAs === MEDICAL_HISTORY_SELECTIONS.LABEL
                                        ? 'Custom label name'
                                        : 'Custom text for description'
                                }
                                className="usePlaceholderTextField"
                            />
                        </Grid>
                    )}
                </Grid>
            </RadioGroup>
        );
    };

    CustomSelectionOnMH.propTypes = {
        title: PropTypes.string.isRequired,
        fieldKey: PropTypes.string.isRequired,
        isText: PropTypes.bool
    };

    const UploadImage = ({ id }) => {
        return (
            <Button component="label" variant="outlined" startIcon={<InsertPhotoIcon />}>
                Upload
                <input
                    id="photo-image-upload"
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={async e => {
                        const newValue = await new Promise(resolve => {
                            try {
                                const reader = new FileReader();
                                reader.readAsDataURL(e.target.files[0]);
                                reader.onload = () => resolve(reader.result);
                                reader.onerror = () => resolve('');
                            } catch (err) {
                                resolve('');
                            }
                        });
                        const updatedWidget = {
                            ...widgetDisplayingData,
                            [id]: newValue
                        };
                        popoverItemsRef.current = {
                            ...popoverItemsRef.current,
                            [id]: newValue
                        };
                        const newValues = data.fields.map(el => {
                            if (el.id === widgetId) return { ...updatedWidget };
                            return el;
                        });
                        setData({ ...data, fields: newValues });
                        setWidgetData(popoverItemsRef.current);
                    }}
                />
            </Button>
        );
    };

    UploadImage.propTypes = {
        id: PropTypes.string.isRequired
    };
    const ImageAlignment = () => {
        return (
            <>
                <Divider className={classes.divider} />
                <span>
                    <strong className={classes.bold}>Image Alignment</strong>
                </span>
                <RadioGroup
                    aria-label="image-alignment"
                    name={'Alignment'}
                    value={widgetData.alignment}
                    defaultValue={IMAGE_WIDGET_ALIGNMENT.LEFT}
                    onChange={({ target }) => {
                        const updatedWidget = {
                            ...widgetDisplayingData,
                            alignment: target.value
                        };
                        popoverItemsRef.current = {
                            ...popoverItemsRef.current,
                            alignment: target.value
                        };
                        const newValues = data.fields.map(el => {
                            if (el.id === widgetId) return { ...updatedWidget };
                            return el;
                        });
                        setData({ ...data, fields: newValues });
                        setWidgetData(popoverItemsRef.current);
                    }}
                >
                    {Object.values(IMAGE_WIDGET_ALIGNMENT).map((el, index) => (
                        <FormControlLabel key={index} value={el} control={<Radio />} label={el} />
                    ))}
                </RadioGroup>
            </>
        );
    };
    const ImageSize = () => {
        return (
            <>
                <Divider className={classes.divider} />
                <span>
                    <strong className={classes.bold}>Image Size</strong>
                </span>
                <RadioGroup
                    aria-label="image-size"
                    name={'Size'}
                    value={widgetData.image_size}
                    defaultValue={IMAGE_WIDGET_SIZE.MEDIUM}
                    onChange={({ target }) => {
                        const updatedWidget = {
                            ...widgetDisplayingData,
                            image_size: target.value
                        };
                        popoverItemsRef.current = {
                            ...popoverItemsRef.current,
                            image_size: target.value
                        };
                        const newValues = data.fields.map(el => {
                            if (el.id === widgetId) return { ...updatedWidget };
                            return el;
                        });
                        setData({ ...data, fields: newValues });
                        setWidgetData(popoverItemsRef.current);
                    }}
                >
                    {Object.values(IMAGE_WIDGET_SIZE).map((el, index) => (
                        <FormControlLabel key={index} value={el} control={<Radio />} label={el} />
                    ))}
                </RadioGroup>
            </>
        );
    };
    const renderFields = () => {
        switch (widgetData.type) {
            case WIDGET_TYPES.TITLE_WIDGET:
                return (
                    <>
                        <WidgetTitle />
                        <TextInput id="title" label="Text Label" />
                    </>
                );
            case WIDGET_TYPES.PARAGRAPH_WIDGET:
                return (
                    <>
                        <WidgetTitle />
                        <TextInput id="title" label="Title Label" />
                        <Spacer />
                        <TextInput id="content" label="Text" multiLine />
                    </>
                );
            case WIDGET_TYPES.TEXT_WIDGET:
                return (
                    <>
                        <WidgetTitle />
                        <TextInput id="title" label="Placeholder Text" />
                        <CheckboxInput id="isRequired" label="Required" />
                        <GroupSelector showDividerOnEnd={false} />
                        <CustomSelectionOnMH title="Show text as:" fieldKey="mhText" isText />
                    </>
                );
            case WIDGET_TYPES.CHECKBOX_WIDGET:
                return (
                    <>
                        <WidgetTitle />
                        <TextInput id="title" label="Text Label" />
                        <CheckboxInput id="isRequired" label="Required" />
                        <CheckboxInput id="value" label="Checkbox ticked as default" updateWidgetOnChange={true} />
                        <GroupSelector showDividerOnEnd={false} />
                        <CustomSelectionOnMH title="Show selection as:" fieldKey="mhSelection" />
                    </>
                );
            case WIDGET_TYPES.RADIO_WIDGET:
                return (
                    <>
                        <WidgetTitle />
                        <TextInput id="title" label="Text Label" />
                        <CheckboxInput id="isRequired" label="Required" />
                        <OptionsInput id="options" label="Options" isRadio={true} requireDefault={'value'} />
                        <GroupSelector showDividerOnEnd={false} />
                        <MedicalHistoryText />
                        <CustomSelectionOnMH title="Show selection as:" fieldKey="mhSelection" />
                        <CustomSelectionOnMH title={'Show "Yes" selection as:'} fieldKey="mhPositiveSelection" />
                    </>
                );
            case WIDGET_TYPES.AUTOCOMPLETE_WIDGET:
                return (
                    <>
                        <WidgetTitle />
                        <TextInput id="title" label="Placeholder Text" />
                        <Spacer />
                        <TextInput id="labelAfter" label="Text to appear to the right of dropdown" />
                        <CheckboxInput id="isRequired" label="Required" />
                        <OptionsInput
                            id="options"
                            label="Dropdown options"
                            afterHeaderComponents={
                                <>
                                    <CheckboxInput id="multiple" label="Choose multiple options" />
                                </>
                            }
                        />
                        <GroupSelector showDividerOnEnd={false} />
                        <MedicalHistoryText widget="dropdown text" />
                        <CustomSelectionOnMH title="Save any other choice as:" fieldKey="mhSelection" />
                    </>
                );
            case WIDGET_TYPES.RADIO_AUTOCOMPLETE_WIDGET:
                return (
                    <>
                        <WidgetTitle />
                        <TextInput id="title" label="Text Label" />
                        <Spacer />
                        <TextInput id="labelAfter" label="Text to appear to the right of dropdown" />
                        <CheckboxInput id="isRequired" label="Required" />
                        <Divider className={classes.divider} />
                        <OptionsInput
                            id="options"
                            label="Dropdown options"
                            requireDefault={'isVisible'}
                            afterHeaderComponents={[
                                <CheckboxInput key={1} id="autocompleteSearch" label="Autocomplete" />,
                                <CheckboxInput key={2} id="isSubFieldRequired" label="Required Dropdown" />
                            ]}
                        />
                        <GroupSelector showDividerOnEnd={false} />
                        <MedicalHistoryText />
                        <CustomSelectionOnMH title="Show selection as:" fieldKey="mhSelection" />
                        <CustomSelectionOnMH title={'Show "Yes" selection as:'} fieldKey="mhPositiveSelection" />
                    </>
                );
            case WIDGET_TYPES.RADIO_TEXT_WIDGET:
                return (
                    <>
                        <WidgetTitle />
                        <TextInput id="title" label="Title" />
                        <CheckboxInput id="isRequired" label="Required" />
                        <Divider className={classes.divider} />
                        <span style={{ marginBottom: 16 }}>
                            <strong className={classes.bold}>Text</strong>
                        </span>
                        <TextInput id="textTitle" label="Placeholder Text" />
                        <CheckboxInput id="isSubFieldRequired" label="Required Text" />
                        <DefaultValueInput id="isVisible" />
                        <GroupSelector showDividerOnEnd={false} />
                        <CustomSelectionOnMH title="Show text as:" fieldKey="mhText" isText />
                        <CustomSelectionOnMH title={'Show "Yes" selection as:'} fieldKey="mhPositiveSelection" />
                    </>
                );
            case WIDGET_TYPES.LIST_WIDGET:
                return (
                    <>
                        <WidgetTitle />
                        <TextInput id="title" label="Text Label" />
                        <CheckboxInput id="isRequired" label="Required" />
                        <OptionsInput id="options" label="Options" />
                        <Divider className={classes.divider} />
                        <span style={{ marginBottom: 16 }}>
                            <strong className={classes.bold}>Text</strong>
                        </span>
                        <TextInput id="textTitle" label="Placeholder Text" />
                        <GroupSelector showDividerOnEnd={false} />
                        <CustomSelectionOnMH title="Save selection as:" fieldKey="mhSelection" />
                        <CustomSelectionOnMH title="Save text field as:" fieldKey="mhText" isText />
                    </>
                );
            case WIDGET_TYPES.RADIO_LIST_WIDGET:
                return (
                    <>
                        <WidgetTitle />
                        <TextInput id="title" label="Text Label" />
                        <CheckboxInput id="isRequired" label="Required" />
                        <Divider className={classes.divider} />
                        <OptionsInput id="options" label="Dropdown options" requireDefault={'isVisible'} />
                        <Divider className={classes.divider} />
                        <span style={{ marginBottom: 16 }}>
                            <strong className={classes.bold}>Text</strong>
                        </span>
                        <TextInput id="textTitle" label="Text Label" />
                        <GroupSelector showDividerOnEnd={false} />
                        <MedicalHistoryText widget="dropdown text" />
                        <CustomSelectionOnMH title='Save a "Yes" choice as:' fieldKey="mhPositiveSelection" />
                        <CustomSelectionOnMH title="Save any other choice as:" fieldKey="mhSelection" />
                        <CustomSelectionOnMH title="Save text field as:" fieldKey="mhText" isText />
                    </>
                );
            case WIDGET_TYPES.TEXT_AREA_WIDGET:
                return (
                    <>
                        <WidgetTitle />
                        <TextInput id="title" label="Placeholder Text" />
                        <CheckboxInput id="isRequired" label="Required" />
                        <GroupSelector />
                    </>
                );
            case WIDGET_TYPES.BLOCK_WIDGET:
                return (
                    <>
                        <WidgetTitle />
                        <TextInput id="size" label="Size" isNumber />
                    </>
                );
            case WIDGET_TYPES.IMAGE_WIDGET:
                return (
                    <>
                        <WidgetTitle />
                        <UploadImage id="src" />
                        <ImageAlignment />
                        <ImageSize />
                    </>
                );
            case WIDGET_TYPES.SIGNATURE_FIELD_WIDGET:
                return (
                    <>
                        <WidgetTitle />
                        <TextInput id="title" label="Placeholder Text" />
                        <CheckboxInput id="isRequired" label="Required" />
                    </>
                );
            default:
                return <></>;
        }
    };

    return (
        <Popover
            id={'title-popover'}
            open={Boolean(anchorEl)}
            className={classes.popoverRoot}
            //anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center'
            }}
        >
            <IconButton
                className={classes.closeButton}
                onClick={() => {
                    setAnchorEl(null);
                }}
            >
                <CloseIcon />
            </IconButton>
            <div className={classes.widgetsContainer}>{widgetData && renderFields()}</div>
            <div className={classes.buttonsContainer}>
                <Button
                    className={`${classes.menuButton} ${classes.closeBtn}`}
                    onClick={() => {
                        setAnchorEl(null);
                    }}
                >
                    Close
                </Button>
                <Button
                    className={`${classes.menuButton} ${classes.saveBtn}`}
                    onClick={() => {
                        const newValues = data.fields.map(el => {
                            if (el.id === widgetId) return { ...popoverItemsRef.current };
                            return el;
                        });
                        setData({ ...data, fields: newValues });
                        setAnchorEl(null);
                    }}
                >
                    Save
                </Button>
            </div>
        </Popover>
    );
};

WidgetConfig.propTypes = {
    classes: PropTypes.object.isRequired,
    widgetId: PropTypes.string.isRequired,
    anchorEl: PropTypes.object.isRequired,
    setAnchorEl: PropTypes.func.isRequired
};

export default withStyles(styles)(WidgetConfig);
