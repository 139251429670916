const { isArray } = require('lodash');
const { WIDGET_GROUPS } = require('../../../index');

const getForAutocomplete = ({ field, errors, requiredMessage }) => {
    const mhData = [];
    const value = isArray(field.value) ? field.value.length : field.value;
    if (field.isRequired && !value) {
        errors.push({ id: field.id, errorType: requiredMessage(field.title) });
    }
    if (field.group && field.group !== WIDGET_GROUPS.NONE && field.mhSelection && value) {
        const type = field.mhSelection.showAs || '';
        const section = field.group;
        const label = field.mhSelection.customValue;
        if (field.mhSelection.customValue) {
            mhData.push({
                type,
                label,
                section
            });
        } else {
            if (isArray(field.value)) {
                field.value.forEach(optionValue => {
                    mhData.push({
                        type,
                        label: optionValue,
                        section
                    });
                });
            } else {
                mhData.push({
                    type,
                    label: field.value,
                    section
                });
            }
        }
    }
    return { id: field.id, value: field.value, mhData };
};

module.exports = getForAutocomplete;
