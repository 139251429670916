import React, { useMemo, useLayoutEffect, useState } from 'react';
import { FormControlLabel, Grid, Radio, RadioGroup, Typography, withStyles, Box } from '@material-ui/core';
import { useForms } from '../../../../hooks/forms';
import formStyles from '../../../../../collums-constants/styles/stylesheets/formStyles';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import getTitle from './helpers/getTitle';
import { hasErrors } from '../../../../helpers';

const RadioWidget = ({ classes, widgetProps, onChange }) => {
    const { isJourney, isReadOnly, errors } = useForms();
    const isMobile = useMediaQuery('(max-width:540px)');
    const [optsDirection, setOptsDirection] = useState(classes.radioOptionsInRows);
    const [gridDirection, setGridDirection] = useState(classes.radioGridInRows);
    const [radioOptionsGridWidth, setRadioOptionsGridWidth] = useState(7);
    const [optsSizeWhenBroke, setOptsSizeWhenBroke] = useState(0);

    const label = useMemo(() => {
        return getTitle(widgetProps.title, widgetProps.isRequired);
    }, [widgetProps]);

    const radioContainerHeight = (() => {
        const radioContainer = document.getElementById(`optionsContainer-${widgetProps.id}`);
        if (radioContainer) return radioContainer.clientHeight;
        return 50;
    })();

    const checkHeight = optsContainer => {
        return optsContainer > radioContainerHeight;
    };

    useLayoutEffect(() => {
        const optsContainer = document.getElementById(`optionsContainer-${widgetProps.id}`);
        if (widgetProps.options.length === optsSizeWhenBroke) {
            return;
        }
        if (optsContainer.clientHeight) {
            const isHeightHigher = checkHeight(optsContainer.clientHeight);
            const spans = optsContainer.querySelectorAll('label span:nth-child(2)');
            let inAnyNotOneliner = false;
            spans.forEach(function(span) {
                if (span.clientHeight > 30) {
                    inAnyNotOneliner = true;
                    return;
                }
            });

            if (
                (optsContainer.clientHeight > 50 && optsDirection.match(/radioOptionsInRows/)) ||
                isHeightHigher ||
                inAnyNotOneliner
            ) {
                if (!optsContainer.className.match(/radioGridInColumns/)) {
                    setOptsSizeWhenBroke(widgetProps.options.length);
                }
                setOptsDirection(classes.radioOptionsInColumns);
                setGridDirection(classes.radioGridInColumns);
                setRadioOptionsGridWidth(12);
            } else if (
                (widgetProps.options.length === optsSizeWhenBroke - 1 || !isHeightHigher) &&
                optsDirection.match(/radioOptionsInColumns/)
            ) {
                setOptsSizeWhenBroke(0);
                setOptsDirection(classes.radioOptionsInRows);
                setGridDirection(classes.radioGridInRows);
                setRadioOptionsGridWidth(7);
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        optsSizeWhenBroke,
        classes.radioGridInColumns,
        classes.radioGridInRows,
        classes.radioOptionsInColumns,
        classes.radioOptionsInRows,
        widgetProps.id,
        widgetProps.options
    ]);

    const generalStyle = useMemo(() => {
        if (!isReadOnly && hasErrors(widgetProps.id, errors)) {
            return classes.error;
        }

        if (isReadOnly) {
            return classes.textDisabled;
        }
        return '';
    }, [widgetProps, errors, isReadOnly, classes.error, classes.textDisabled]);

    return (
        <RadioGroup
            aria-label={widgetProps.title}
            name={widgetProps.title}
            value={widgetProps.value || ''}
            onChange={e => {
                onChange(widgetProps.id, e.target.value);
            }}
            label={label}
        >
            <Grid container spacing={1} className={gridDirection}>
                <Grid
                    item
                    xs={optsDirection !== classes.radioOptionsInRows ? 12 : 4}
                    style={{
                        width: optsDirection !== classes.radioOptionsInRows ? '100%' : '33%',
                        minWidth: isMobile ? '100%' : '33%'
                    }}
                >
                    <Typography
                        style={{
                            minWidth: isMobile ? '100%' : 'unset'
                        }}
                        className={generalStyle}
                    >
                        {label}
                    </Typography>
                </Grid>
                <Grid
                    style={{
                        minWidth: '0',
                        flexWrap: 'wrap !important',
                        maxWidth: optsDirection === classes.radioOptionsInRows ? 'calc(100% - 33%) !important' : '100%'
                    }}
                    item
                    xs={radioOptionsGridWidth}
                    id={`optionsContainer-${widgetProps.id}`}
                >
                    <Box
                        className={`${optsDirection} radio-buttons ${
                            widgetProps.options.length === 2 && widgetProps.options[0] === 'No'
                                ? 'radio-form-item-2-fields'
                                : ''
                        }`}
                    >
                        {widgetProps.options?.map((el, i) => (
                            <Grid
                                key={i}
                                item
                                xs={
                                    optsDirection === classes.radioOptionsInColumns
                                        ? 12
                                        : widgetProps.options.length === 2
                                        ? 2
                                        : 4
                                }
                            >
                                <FormControlLabel
                                    className={`${classes.radioOptionLabel} ${generalStyle}`}
                                    value={el}
                                    control={<Radio disabled={!isJourney || isReadOnly} className={generalStyle} />}
                                    label={el}
                                />
                            </Grid>
                        ))}
                    </Box>
                </Grid>
            </Grid>
        </RadioGroup>
    );
};

RadioWidget.propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    widgetProps: PropTypes.object.isRequired
};

export default withStyles(formStyles)(RadioWidget);
