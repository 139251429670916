const { isEmpty } = require('lodash');
const { WIDGET_TYPES } = require('../../index');
const getForAutocomplete = require('./widgets/autocomplete');
const getForCheckbox = require('./widgets/checkbox');
const getForRadio = require('./widgets/radio');
const getForTextField = require('./widgets/textField');
const getForTextArea = require('./widgets/textArea');
const getForList = require('./widgets/list');
const getForRadioTextField = require('./widgets/radioTextField');
const getForRadioList = require('./widgets/radioList');
const getForRadioAutocomplete = require('./widgets/radioAutocomplete');
const getForSignatureField = require('./widgets/signatureField');

const getAnswersFromForm = fields => {
    const requiredMessage = title => `field ${title} is required`;
    const errors = [];
    const answers = fields
        .map(field => {
            switch (field.type) {
                case WIDGET_TYPES.AUTOCOMPLETE_WIDGET: {
                    return getForAutocomplete({
                        field,
                        errors,
                        requiredMessage
                    });
                }
                case WIDGET_TYPES.CHECKBOX_WIDGET: {
                    return getForCheckbox({
                        field,
                        errors
                    });
                }
                case WIDGET_TYPES.RADIO_WIDGET:
                    return getForRadio({
                        field,
                        errors
                    });
                case WIDGET_TYPES.TEXT_WIDGET:
                    return getForTextField({
                        field,
                        errors,
                        requiredMessage
                    });
                case WIDGET_TYPES.TEXT_AREA_WIDGET: {
                    return getForTextArea({
                        field,
                        errors,
                        requiredMessage
                    });
                }
                case WIDGET_TYPES.LIST_WIDGET:
                    return getForList({
                        field,
                        errors,
                        requiredMessage
                    });
                case WIDGET_TYPES.RADIO_TEXT_WIDGET: {
                    return getForRadioTextField({
                        field,
                        errors,
                        requiredMessage
                    });
                }
                case WIDGET_TYPES.RADIO_LIST_WIDGET: {
                    return getForRadioList({
                        field,
                        errors,
                        requiredMessage
                    });
                }
                case WIDGET_TYPES.RADIO_AUTOCOMPLETE_WIDGET: {
                    return getForRadioAutocomplete({
                        field,
                        errors,
                        requiredMessage
                    });
                }
                case WIDGET_TYPES.SIGNATURE_FIELD_WIDGET: {
                    return getForSignatureField({
                        field,
                        errors,
                        requiredMessage
                    });
                }
                default:
                    return undefined;
            }
        })
        .filter(el => el && !isEmpty(el));

    return {
        answers,
        errors
    };
};

module.exports = getAnswersFromForm;
