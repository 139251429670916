const { WIDGET_GROUPS } = require('../../../index');

const getForList = ({ field, errors, requiredMessage }) => {
    const mhData = [];
    if (field.isRequired && !(field.value || []).length) {
        errors.push({ id: field.id, errorType: requiredMessage(field.title) });
    }
    if ((field.value || []).some(answer => typeof answer.value !== 'string')) {
        errors.push({ id: field.id, errorType: `Invalid option on field ${field.title}` });
    }
    if (field.value && field.group && field.group !== WIDGET_GROUPS.NONE && (field.mhText || field.mhSelection)) {
        (field.value || []).forEach(answer => {
            const section = field.group;
            if (field.mhText) {
                const type = field.mhText.showAs || '';
                if (type && answer.info) {
                    mhData.push({
                        type,
                        label: answer.info,
                        section
                    });
                }
            }
            if (field.mhSelection && answer.value) {
                const type = field.mhSelection.showAs || '';
                if (type) {
                    mhData.push({
                        type,
                        label: field.mhSelection.customValue || answer.value,
                        section
                    });
                }
            }
        });
    }
    return { id: field.id, value: field.value, mhData };
};

module.exports = getForList;
