import React, { useState } from 'react';
import AutoCompleteWidget from './widgets/AutoCompleteWidget';
import CheckBoxWidget from './widgets/CheckBoxWidget';
import ImageWidget from './widgets/ImageWidget';
import ParagraphWidget from './widgets/ParagraphWidget';
import RadioWidget from './widgets/RadioWidget';
import TextWidget from './widgets/TextWidget';
import TitleWidget from './widgets/TitleWidget';
import WidgetConfig from './WidgetConfig';
import RadioAutoCompleteWidget from './widgets/RadioAutoCompleteWidget';
import RadioTextWidget from './widgets/RadioTextWidget';
import ListWidget from './widgets/ListWidget';
import RadioListWidget from './widgets/RadioListWidget';
import { WIDGET_TYPES } from '../../../../collums-constants';
import { useForms } from '../../../hooks/forms';
import TextAreaWidget from './widgets/TextAreaWidget';
import { RenderViewStyles } from './styles';
import { Grid, IconButton, withStyles, makeStyles } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import PropTypes from 'prop-types';
import { swapPosition } from '../../../helpers';
import BlockWidget from './widgets/BlockWidget';
import Button from '@material-ui/core/Button';
import { modalsButtonStyles } from '../../../../collums-constants/styles/stylesheets/buttonsStyles';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CancelContinueModal from '../../common/CancelContinueModal';
import './placeholderStyles.css';
import './styles.css';
import SignatureWidget from './widgets/SignatureWidget';

const Render = ({ classes, openPreviewModal }) => {
    const buttonClasses = makeStyles(modalsButtonStyles)();
    const [anchorEl, setAnchorEl] = useState(null);
    const [targetWidget, setTargetWidget] = useState();
    const { data, setData, isJourney, additionalProps } = useForms();
    const [deletingIndex, setDeletingIndex] = useState(null);

    const onChange = (id, value, fieldName = 'value') => {
        const fields = data.fields.map(el => {
            if (el.id === id) {
                const newData = { ...el };
                if (fieldName !== 'value') {
                    const additionalInfo = newData.additionalInfo ? { ...newData.additionalInfo } : {};
                    additionalInfo[fieldName] = value;
                    if (fieldName === 'isVisible') {
                        newData.value = undefined;
                    }
                    newData.additionalInfo = additionalInfo;
                }
                newData[fieldName] = value;
                return newData;
            }
            return el;
        });
        setData({ ...data, fields });
    };

    const RenderComponent = (widgetProps, index) => {
        if (!widgetProps?.type) return null;
        const props = {
            widgetProps,
            onChange
        };
        if (widgetProps.type === WIDGET_TYPES.AUTOCOMPLETE_WIDGET) {
            widgetProps.limitTags = 999;
        }

        if (additionalProps && additionalProps?.fields) {
            const type = widgetProps.type;
            if (Object.prototype.hasOwnProperty.call(additionalProps.fields, type)) {
                props.widgetProps = {
                    // eslint-disable-next-line react/prop-types
                    ...props.widgetProps,
                    ...additionalProps.fields[type]
                };
            }
        }

        const WidgetComponent = (() => {
            switch (widgetProps.type) {
                case WIDGET_TYPES.TEXT_WIDGET:
                    return TextWidget;
                case WIDGET_TYPES.PARAGRAPH_WIDGET:
                    return ParagraphWidget;
                case WIDGET_TYPES.CHECKBOX_WIDGET:
                    return CheckBoxWidget;
                case WIDGET_TYPES.RADIO_WIDGET:
                    return RadioWidget;
                case WIDGET_TYPES.SELECT_WIDGET:
                case WIDGET_TYPES.AUTOCOMPLETE_WIDGET:
                    return AutoCompleteWidget;
                case WIDGET_TYPES.TITLE_WIDGET:
                    return TitleWidget;
                case WIDGET_TYPES.IMAGE_WIDGET:
                    return ImageWidget;
                case WIDGET_TYPES.RADIO_AUTOCOMPLETE_WIDGET:
                    return RadioAutoCompleteWidget;
                case WIDGET_TYPES.RADIO_TEXT_WIDGET:
                    return RadioTextWidget;
                case WIDGET_TYPES.LIST_WIDGET:
                    return ListWidget;
                case WIDGET_TYPES.RADIO_LIST_WIDGET:
                    return RadioListWidget;
                case WIDGET_TYPES.TEXT_AREA_WIDGET:
                    return TextAreaWidget;
                case WIDGET_TYPES.BLOCK_WIDGET:
                    return BlockWidget;
                case WIDGET_TYPES.SIGNATURE_FIELD_WIDGET:
                    return SignatureWidget;
                default:
                    return <></>;
            }
        })();

        const isMoveDisabled = isUp => {
            if (isUp) {
                if (index === 0) return true;
                return false;
            }
            if (index === data.fields.length - 1) return true;
        };

        const gridClickHandler = event => {
            event.preventDefault();
            event.stopPropagation();
            if (isJourney) return;
            if (anchorEl && widgetProps.id === targetWidget) return;
            setAnchorEl(event.currentTarget);
            setTargetWidget(widgetProps.id);
        };

        return (
            <Grid item xs={12} onClick={gridClickHandler} className={classes.widgetGrid}>
                {!isJourney ? <div className={classes.clickHandler} onClick={gridClickHandler} /> : ''}
                <div className={`${classes.widgetArea} widget-area`}>
                    <WidgetComponent {...props} />
                    <div className={'change-order-container'} style={{ display: isJourney ? 'none' : 'flex' }}>
                        <IconButton
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                setData({ ...data, fields: swapPosition(data.fields, index, index - 1) });
                            }}
                            disabled={isMoveDisabled(true)}
                        >
                            <ArrowUpwardIcon className={classes.arrowIcons} />
                        </IconButton>
                        <IconButton
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                setData({ ...data, fields: swapPosition(data.fields, index, index + 1) });
                            }}
                            disabled={isMoveDisabled(false)}
                        >
                            <ArrowDownwardIcon className={classes.arrowIcons} />
                        </IconButton>
                    </div>
                    <div className={classes.deleteItemContainer} style={{ display: isJourney ? 'none' : 'flex' }}>
                        <IconButton
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                setDeletingIndex(index);
                            }}
                        >
                            <DeleteOutlineOutlinedIcon className={classes.deleteIcon} />
                        </IconButton>
                    </div>
                </div>
            </Grid>
        );
    };

    return (
        <>
            <div className={classes.formContent}>
                <Grid
                    container
                    spacing={3}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    className={!isJourney ? classes.widgetsBuilderGridContainer : classes.widgetsGridContainer}
                >
                    {data.fields.map(RenderComponent)}
                </Grid>
                {anchorEl && <WidgetConfig widgetId={targetWidget} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />}
                {!isJourney && openPreviewModal && data.fields.length !== 0 && (
                    <Button
                        className={`${buttonClasses.baseButton} ${classes.previewButton}`}
                        onClick={openPreviewModal}
                    >
                        Preview
                    </Button>
                )}
            </div>
            <CancelContinueModal
                open={typeof deletingIndex === 'number'}
                title={'Delete block'}
                contentText={'Are you sure you want to delete this block?'}
                onContinue={() => {
                    const newFields = data.fields;
                    newFields.splice(deletingIndex, 1);
                    setData({ ...data, fields: newFields });
                    setDeletingIndex(null);
                    setAnchorEl(null);
                }}
                onCancel={() => {
                    setDeletingIndex(null);
                }}
                cancelButtonText="Back"
                continueButtonText="Delete"
            />
        </>
    );
};

Render.propTypes = {
    classes: PropTypes.object.isRequired,
    openPreviewModal: PropTypes.func
};

export default withStyles(RenderViewStyles)(Render);
