/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router';
import ClientDetailsPage from '../components/pages/ClientDetailsPage';
import MainPage from '../components/pages/MainPage';
import steps from '../constants/steps';
import { NavigationContextProvider } from '../contexts/NavigationContext';
import useAuth from '../hooks/useAuth';
import { AppointmentContextProvider } from '../contexts/AppointmentContext';
import { LoadingContextProvider } from '../contexts/LoadingContext';
import { JourneyContextProvider } from '../contexts/JourneyContext';
import PhotosPage from '../components/pages/PhotosPage';
import SummaryPage from '../components/pages/SummaryPage/SummaryPage';
import FormComplete from '../components/pages/FormCompletePage/FormComplete';
import FinishPage from '../components/pages/FinishPage';
import PractitionerFormsPage from '../components/pages/PractitionerFormsPage';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import getVH from '../collums-components/helpers/getVH';
import CustomerFormsPage from '../components/pages/CustomerFormsPage';
import { isAvailableByPlan, isValidMongoIdString } from '../collums-constants/utils';
import { COLLUMS_APPS_ACCESS_TIER } from '../collums-constants';
import EnvironmentDisabled from './../collums-components/components/common/EnvironmentDisabled';
import { AuthContextProvider } from '../contexts/AuthContext';
import { getQueryParams } from '../../services/locationHelper';
import { CURRENT_CLINIC } from '../collums-constants/storageKeys';

function App({ children }) {
    const location = useLocation();
    const activeKey = location.pathname.replace('/', '');

    useEffect(() => {
        const currentClinic = getQueryParams(location).clinic;
        if (currentClinic && isValidMongoIdString(currentClinic)) {
            localStorage.setItem(CURRENT_CLINIC, currentClinic);
        }
    }, []); //eslint-disable-line
    const activeStep = steps.findIndex(step => step.id === activeKey);

    useLayoutEffect(() => {
        getVH();
    }, []);

    const { user } = useAuth();

    if (!isAvailableByPlan(COLLUMS_APPS_ACCESS_TIER.JOURNEY)) {
        return <EnvironmentDisabled />;
    }

    if (!user) return null;

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <AuthContextProvider>
                <JourneyContextProvider>
                    <AppointmentContextProvider>
                        <LoadingContextProvider>
                            <NavigationContextProvider activeStep={activeStep}>
                                {children}
                                <Switch>
                                    <Route path="/" exact component={MainPage} />
                                    <Route path="/client-details" component={ClientDetailsPage} />
                                    <Route path="/forms" component={CustomerFormsPage} />
                                    <Route path="/form-complete" component={FormComplete} />
                                    <Route path="/practitioner-forms" component={PractitionerFormsPage} />
                                    <Route path="/summary" component={SummaryPage} />
                                    <Route path="/photos" component={PhotosPage} />
                                    <Route path="/finish" component={FinishPage} />
                                </Switch>
                            </NavigationContextProvider>
                        </LoadingContextProvider>
                    </AppointmentContextProvider>
                </JourneyContextProvider>
            </AuthContextProvider>
        </MuiPickersUtilsProvider>
    );
}

export default App;
