import api from './config';
import axios from 'axios';
import imageCompression from 'browser-image-compression';

class PhotosApi {
    static async create(url, name, thumbnail, customer, origin) {
        return api.request({
            method: 'POST',
            url: '/photos/create',
            data: {
                url,
                name,
                thumbnail,
                customer,
                origin
            }
        });
    }

    static getByCustomer(customerId) {
        return api.request({
            method: 'GET',
            url: `/photos/customer/${customerId}`
        });
    }

    static getPhotosByNoteId(treatmentId) {
        return api.request({
            method: 'GET',
            url: `/photos/note/${treatmentId}`
        });
    }

    static getPresignedURI({ treatmentId, customerId, name, isThumbnail }) {
        const type = 'image/png';
        return api.request({
            method: 'GET',
            url: `/photos/${treatmentId}/upload-photo?customerId=${customerId}&type=${type}&name=${name}&isThumbnail=${isThumbnail}`
        });
    }

    static async uploadPhotos(photo, treatmentId, customerId, isThumbnail) {
        const uploadPhoto = async (url, file) => {
            let optimizedImg;
            const optimizationOptions = isThumbnail
                ? {
                      maxSizeMB: 0.1,
                      maxWidthOrHeight: 1920,
                      fileType: 'image/jpeg' // force file type to speed up compression on some devices
                  }
                : {
                      maxSizeMB: 1,
                      fileType: 'image/jpeg'
                  };
            try {
                optimizedImg = await imageCompression(file, optimizationOptions);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(JSON.stringify(e));
                return false;
            }

            const optimizedFile = new File([optimizedImg], file.name, { type: file.type });

            const options = {
                headers: {
                    'Content-Type': file.name.split('.')[1],
                    'x-amz-acl': 'public-read'
                }
            };

            try {
                await axios({ url: url, method: 'PUT', data: optimizedFile, options });
                return url;
            } catch (error) {
                console.error(error);
            }
        };

        const url = await this.getPresignedURI({
            treatmentId,
            customerId,
            name: photo.name || '',
            isThumbnail: isThumbnail ? true : false
        });

        try {
            await uploadPhoto(url.signedRequest, isThumbnail ? photo : photo.file);
        } catch (error) {
            console.error(error);
        }
        return url;
    }

    static list(customerId, query) {
        if (api.params.headers.Authorization === null) {
            api.resetToken(localStorage.getItem('token'));
        }
        return api.request({
            method: 'GET',
            url: `/photos/${customerId}?${query}`
        });
    }

    static getFilters(customerId) {
        return api.request({
            method: 'GET',
            url: `/photos/${customerId}/filters`
        });
    }

    static deletePhoto(treatmentId, url) {
        return api.request({
            method: 'DELETE',
            url: `/photos/${treatmentId}?url=${url}`
        });
    }

    static createWithoutPhoto(data) {
        return api.request({
            method: 'POST',
            url: '/photos/without-photo',
            data
        });
    }

    static finishUploadPhotoStage(id, data) {
        return api.request({
            method: 'PUT',
            url: `/photos/update-by-upload/${id}`,
            data
        });
    }
}

export default PhotosApi;
