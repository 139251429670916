const formStyles = theme => ({
    font: {
        fontFamily: 'Roboto, Arial, Sans-serif',
        boxSizing: 'border-box',
        fontSize: '14px',
        '& *': {
            boxSizing: 'border-box'
        },
        '& > * > *, & > * > * > *, & > *': {
            fontFamily: 'Roboto, Arial, Sans-serif',
            boxSizing: 'border-box'
        }
    },
    label: {
        '& > span': {
            lineHeight: 1.5,
            fontFamily: 'Roboto, Arial, Sans-serif',
            fontSize: '14px'
        }
    },
    autocomplete: {
        width: '100%',
        minHeight: 38,
        '& .MuiOutlinedInput-root': {
            minHeight: '38px'
        },
        '& .MuiInputBase-root': {
            padding: '0!important',
            height: 'auto'
        },
        '& input': {
            height: 'inherit',
            boxSizing: 'border-box'
        },
        '& .MuiAutocomplete-endAdornment': {
            top: 'calc(50% - 16px)',
            [theme.breakpoints.down('sm')]: {
                right: '-3px !important'
            }
        },
        '& .MuiChip-root': {
            whiteSpace: 'normal',
            padding: '10px 0 10px 0',
            height: 'auto'
        },
        '& .MuiChip-label': {
            whiteSpace: 'normal',
            lineHeight: 'initial'
        },
        ...(theme.app !== 'journey' ? label14 : {})
    },
    largerForm: {
        '& ~ div.change-order-container': {
            left: 'calc(-40px - 36px - 4px) !important'
        },
        //? Delete icon button
        '& ~ div:nth-of-type(3)': {
            left: 'calc(100% + 36px - 4px) !important'
        }
    },
    autocompleteWithPadding: {
        width: '100%',
        minHeight: 38,
        '& .MuiOutlinedInput-root': {
            minHeight: '38px'
        },
        '& .MuiAutocomplete-inputRoot': {
            padding: '0 !important',
            paddingLeft: '10px !important'
        }
    },
    autocompleteTextField: {
        width: '100%',
        '& input': {
            paddingLeft: '12px !important'
        }
    },
    select: {
        width: '100%',
        lineHeight: 0,
        height: 'auto'
    },
    textAreaDiv: {
        padding: 5
    },
    textArea: {
        boxSizing: 'border-box',
        padding: '10px',
        width: '100%',
        resize: 'vertical',
        outline: 'rgba(0,0,0,0.8)',
        border: '1px solid rgba(0,0,0,0.3)',
        borderRadius: '5px',
        minHeight: '2.5rem',
        maxHeight: '200px',
        color: '#333',
        fontFamily: 'Roboto, Arial, Sans-serif !important',
        '&::placeholder': {
            fontFamily: 'Roboto, Arial, Sans-serif !important',
            fontSize: (theme.formLabel || '14px') + ' !important'
        },
        background: 'transparent',
        fontSize: theme.formLabel || '14px'
    },
    error: {
        borderColor: '#f44336',
        outline: '#f44336',
        color: '#f44336'
    },
    title: {
        fontSize: theme.app === 'journey' ? '22px !important' : '18px !important',
        textAlign: 'center',
        fontFamily: 'Roboto, Arial, Sans-serif',
        fontWeight: 500
    },
    radioGrid: {
        display: 'flex',
        flexFlow: 'row wrap',
        '& > div': {
            flex: 0
        }
    },
    radioOptionLabel: {
        wordBreak: 'break-all'
    },
    radioGridInColumns: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyItems: 'center',
        width: '100%',
        position: 'relative',
        flexWrap: 'nowrap !important'
    },
    radioGridInRows: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: theme.app === 'customer-app' ? 'wrap' : 'nowrap !important',
        position: 'relative',
        // width: '100%',
        alignItems: 'center',
        justifyItems: 'flex-start'
    },
    radioOptionsContainer: {
        flexWrap: 'wrap !important'
    },
    radioOptionsInColumns: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minWidth: 'fit-content'
    },
    radioOptionsInRows: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        '& > div:first-of-type': {
            // maxWidth: 94,
            flex: 1
        }
    },
    paragraphTitle: {
        fontSize: theme.app === 'journey' ? '20px !important' : '18px !important',
        marginBottom: 10,
        fontWeight: 400
    },
    paragraphContent: {
        '& > *': {
            fontFamily: 'Roboto, Arial, Sans-serif !important',
            fontSize: 14
        }
    },
    imageWidgetImgContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    imgLeftAligned: {
        alignItems: 'flex-start'
    },
    imgCenterAligned: {
        alignItems: 'center'
    },
    imgRightAligned: {
        alignItems: 'flex-end'
    },
    imgSmallSize: {
        maxWidth: '75px',
        maxHeight: '75px'
    },
    imgMediumSize: {
        maxWidth: '200px',
        maxHeight: '200px'
    },
    imgBigSize: {
        maxWidth: '100%',
        maxHeight: '100%'
    },
    textDisabled: {
        color: 'rgba(0,0,0,0.5)'
    },
    disabled: {
        '& .Mui-disabled': {
            color: '#5c5c5c',
            '&:hover': {
                cursor: 'no-drop'
            }
        }
    },
    breakParagraph: {
        marginBottom: theme.app === 'journey' ? '18px !important' : '14px !important'
    },
    boxSignature: {
        height: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    boxSignatureValid: {
        border: '1px solid #000'
    },
    boxSignatureError: {
        border: '1px solid #ff3333'
    },
    boxSignatureDisabled: {
        borderColor: 'rgba(0,0,0,0.5)'
    }
});
const label14 = {
    '& label': {
        fontSize: '14px'
    }
};
export default formStyles;
