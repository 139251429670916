const { isArray } = require('lodash');
const { WIDGET_GROUPS, MEDICAL_HISTORY_SELECTIONS } = require('../../../index');

const getForRadioAutocomplete = ({ field, errors, requiredMessage }) => {
    const mhData = [];
    const isValidMHSection = field.group && field.group !== WIDGET_GROUPS.NONE;
    const value = isArray(field.value) ? field.value.length : field.value;

    if (field.isRequired && !field.isVisible) {
        errors.push({ id: field.id, errorType: requiredMessage(field.title) });
    } else if (field.isRequired && field.isVisible === 't' && field.isSubFieldRequired && !field.value) {
        errors.push({ id: field.id, errorType: requiredMessage(field.title) });
    }
    const section = field.group;
    if (isValidMHSection && (field.mhSelection || field.mhPositiveSelection) && field.isVisible === 't' && value) {
        if (field.mhPositiveSelection) {
            const type = field.mhPositiveSelection.showAs || '';
            const label = field.mhPositiveSelection.customValue;
            if (label) {
                mhData.push({
                    type,
                    label,
                    section
                });
            } else {
                if (isArray(field.value)) {
                    field.value.forEach(optionValue => {
                        mhData.push({
                            type,
                            label: optionValue,
                            section
                        });
                    });
                } else {
                    mhData.push({
                        type,
                        label: field.value,
                        section
                    });
                }
            }
        }

        if (field.mhSelection) {
            const type = field.mhSelection.showAs || '';
            const label = field.mhSelection.customValue;
            if (field.mhSelection.customValue) {
                mhData.push({
                    type,
                    label,
                    section
                });
            } else {
                if (isArray(field.value)) {
                    field.value.forEach(optionValue => {
                        mhData.push({
                            type,
                            label: optionValue,
                            section
                        });
                    });
                } else {
                    mhData.push({
                        type,
                        label: field.value,
                        section
                    });
                }
            }
        }
    }
    if (
        isValidMHSection &&
        field.isVisible !== 't' &&
        field.mhPositiveSelection?.showAs === MEDICAL_HISTORY_SELECTIONS.LABEL
    ) {
        mhData.push({
            section,
            type: MEDICAL_HISTORY_SELECTIONS.LABEL,
            label: field.mhPositiveSelection.customValue || field.title,
            isRemoval: true
        });
    }
    return {
        id: field.id,
        value: field.isVisible === 't' ? field.value : undefined,
        isVisible: field.isVisible,
        mhData
    };
};

module.exports = getForRadioAutocomplete;
