import React from 'react';
import LoadingScreen from '../../collums-components/components/common/loadingScreen';
const LoadingContext = React.createContext({});
export const LoadingContextProvider = ({ children }) => {
    const [isLoading, setIsLoading] = React.useState(false);
    return (
        <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
            {isLoading && <LoadingScreen></LoadingScreen>}
            {children}
        </LoadingContext.Provider>
    );
};
export default LoadingContext;
