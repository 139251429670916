import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import AccessDisabledModal from './../components/common/AccessDisabledModal';
import { useHistory } from 'react-router-dom';

const AccessTierContext = createContext({});

export const AccessTierProvider = ({ children }) => {
    const [isModalAppearing, setIsModalAppearing] = useState(false);
    const history = useHistory();

    const redirectTab = newTab => {
        history.push(newTab);
    };

    const openFeatureModal = url => {
        setIsModalAppearing(true);
        if (url) redirectTab(url);
    };

    const closeModal = () => setIsModalAppearing(false);

    return (
        <AccessTierContext.Provider value={{ openFeatureModal }}>
            {isModalAppearing && <AccessDisabledModal onClose={closeModal} />}
            {children}
        </AccessTierContext.Provider>
    );
};

AccessTierProvider.propTypes = {
    children: PropTypes.element
};

export const useAccessTier = () => useContext(AccessTierContext);
