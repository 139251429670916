import React, { useState, useEffect } from 'react';
import Api from '../../../services/Api';
import useQs from '../../hooks/useQs';
import { useHistory } from 'react-router-dom';

const AuthContext = React.createContext({});

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState({});

    const qs = useQs();
    const history = useHistory();

    useEffect(() => {
        const urlToken = qs.get('token');
        const token = localStorage.getItem('token');

        if (urlToken && urlToken !== '' && token !== urlToken) {
            localStorage.setItem('token', urlToken);
            const apptId = (() => {
                const qsApptId = qs.get('appointmentId');
                if (!qsApptId || qsApptId === 'null') {
                    const localStorageApptIds = localStorage.getItem('appointmentId');
                    if (localStorageApptIds) return localStorageApptIds;
                }
                return qsApptId;
            })();
            history.replace(`/?appointmentId=${apptId}`);
            window.location.reload();
            return;
        }

        const saveAppts = () => {
            const apptIds = qs.get('appointmentId');
            if (apptIds) {
                localStorage.setItem('appointmentId', apptIds);
            }
        };

        const authUrl = process.env.REACT_APP_AUTH_URL || 'https://staging.auth.collums.co.uk';
        if (!token) {
            saveAppts();
            window.location = `${authUrl}/?redirect=journey`;
            return;
        }

        const userRequest = async () => {
            try {
                const { data } = await Api.get('user/me', {
                    headers: {
                        Authorization: token
                    }
                });
                if (!qs.get('appointmentId')) {
                    const apptIds = localStorage.getItem('appointmentId');
                    if (apptIds) {
                        history.replace(`/?appointmentId=${apptIds}`);
                        localStorage.removeItem('appointmentId');
                    }
                }
                setUser(data);
            } catch (err) {
                console.error(err);
                saveAppts();
                window.location = `${authUrl}/?redirect=journey`;
            }
        };

        userRequest();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};

export default AuthContext;
